/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Formik, FastField } from "formik";
import {  Grid, Avatar, Stack, Card } from "@mui/material";
import { styled } from "@mui/material/styles";
import "./Simulation.css";
import CssTextField from "Components/CssTextField";
import { GlobalState } from "context/GlobalContext";
import ImageView from "../../Components/ImageView";
import _ from "lodash";
import useApi from "services/api-hook";
import useRequests from "services/request-hook";

const CssAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: "transparent",
  border: "1px dashed",
  borderColor: theme.palette.primary.contrastText,
  top: "18px",
  left: "12px",
  "&.avatar": {
    borderWidth: 0,
  },
  "& svg": {
    display: "none",
  },
}));

const CssCard = styled(Card)({
  marginTop: "30px !important",
  padding: "6px 20px 20px",
  position: "relative",
  backgroundColor: "transparent",
  border: "1px solid #673068",
});

const ChoiceForm = ({ node, handleResponse, nodes }) => {
  const { sid } = useParams();
  const { isPublished } = useContext(GlobalState);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [kpiData, setKpiData] = useState([]);
  const [hasKpis, setHasKpis] = useState(false);
  const { getChoicesApi, getKpiApi } = useRequests();
  const getChoices = useApi(getChoicesApi);
  const getKpi = useApi(getKpiApi);

  const [initialValues, setInitialValues] = useState([]);

  useEffect(() => {
    if (node && node.elementId) {
      let parent_node = _.find(nodes, (o) => o.id === node?.parent);
      getChoices.request(parent_node?.elementId, node?.elementId).then((res) => {
        let result = res.data;
        setInitialValues(result);
      });
    }
    getKpi.request(sid).then((res) => {
      if (res.data.length) {
        setKpiData(res.data);
        setHasKpis(true);
      } else {
        setHasKpis(false);
      }
    });
  }, []);

  const openImageViewer = useCallback((index, imageUrl, e) => {
    setImages([imageUrl]);
    setCurrentImage(index);
    setIsViewerOpen(true);
    e.stopPropagation();
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Formik>
    <Grid item container component={CssCard} className="card-wrapper">
      <Grid item xs={5} style={{ maxWidth: "32.666667%" }} mr={2}>
        <FastField
          component={CssTextField}
          variant="outlined"
          size="small"
          name={`choices.${initialValues.label}.label`}
          id={`choices.${initialValues.label}.label`}
          value={initialValues.label}
          InputProps={{
            className: "text-field",
          }}
          inputProps={{ readOnly: isPublished }}
          label="Label*"
        />
      </Grid>

      <Grid item xs={5} style={{ maxWidth: "32.666667%" }}>
        <FastField
          component={CssTextField}
          variant="outlined"
          type="text"
          size="small"
          name={`choices.${initialValues.index}.index`}
          id={`choices.${initialValues.index}.index`}
          value={initialValues.index}
          InputProps={{
            className: "text-field",
          }}
          inputProps={{ readOnly: isPublished }}
          label="Index"
        />
      </Grid>

      <Grid
        item
        xs={5}
        className="icon-file-grid"
        style={{ maxWidth: "32.666667%" }}
      >
        <Stack spacing={2} direction="row">
          <CssAvatar
            alt={"Icon"}
            src={initialValues?.icon?.url}
            className={initialValues?.icon?.url ? "avatar" : ""}
            onClick={(e) => openImageViewer(0, initialValues?.icon?.url, e)}
            style={{ cursor: "pointer" }}
          />
          <label htmlFor={`choices.${initialValues.index}.icon`}>
            <input
              id={`choices.${initialValues.index}.icon`}
              name={`choices.${initialValues.index}.icon`}
              type="file"
              disabled={isPublished}
              className="form-control"
              style={{ display: "none" }}
              accept="image/*"
              inputProps={{ readOnly: isPublished }}
            />
          </label>
        </Stack>
      </Grid>
      {kpiData &&
        kpiData.length > 0 &&
        kpiData.map((o, i) => (
          <Grid item xs={5} key={i} style={{ maxWidth: "32.666667%" }} mr={2}>
            <FastField
              component={CssTextField}
              variant="outlined"
              size="small"
              type="text"
              onKeyDown={(evt) =>
                (evt.key === "e" || evt.key === "E") && evt.preventDefault()
              }
              name={`choices.${0}.kpis_to_impact.${o.uid}`}
              value={
                initialValues?.kpis_to_impact &&
                initialValues?.kpis_to_impact[o.uid]
              }
              InputProps={{
                className: "text-field",
              }}
              inputProps={{ readOnly: isPublished }}
              label={o.name}
            />
          </Grid>
        ))}
      {isViewerOpen && (
        <ImageView
          currentImage={currentImage}
          openImageViewer={openImageViewer}
          closeImageViewer={closeImageViewer}
          isViewerOpen={isViewerOpen}
          imageNames=""
          viewFullScreen={false}
          images={images}
        />
      )}
    </Grid>
    </Formik>
  );
};

export default ChoiceForm;
