/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { ReactFlowProvider } from "react-flow-renderer";
import "./Simulation.css";
import Builder from "./Builder";
import WorkFlowArea from "./WorkFlowArea";
import { GlobalState } from "context/GlobalContext";
import useApi from 'services/api-hook';
import useRequests from 'services/request-hook';
import CssTitle from "Components/CssTitle";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Simulation = () => {
  const { sid, category } = useParams();
  const { setScenarioDetails, scenarioDetails, openEditForm, setIsPublished, isPublished } = useContext(GlobalState);
  const { getScenarioApi } = useRequests();
  const getScenario = useApi(getScenarioApi);

  const getScenariosList = () => {
    getScenario.request(sid)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data;
          setScenarioDetails(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (openEditForm === false) {
      getScenariosList();
    }
  }, [openEditForm]);

  useEffect(() => {
    return () => { setIsPublished(false) }
  }, [])

  return (
    <ReactFlowProvider>
      <CssTitle sx={{ mb: 3 }} variant="h6"><NavLink to={'/'}>Home</NavLink> <NavigateNextIcon /> <NavLink to={`/${category}`} style={{ textTransform: "capitalize"}}>{category} Simulation</NavLink> <NavigateNextIcon /> {scenarioDetails?.title}</CssTitle>
      <div className='Simulation'>
        <Grid container spacing={2} className='simulation-wrapper'>
          {
            !isPublished && (
              <Grid item xs={2}>
                <Builder />
              </Grid>
            )
          }
          <Grid item xs={isPublished ? 12 : 10}>
            <WorkFlowArea />
          </Grid>
        </Grid>
      </div>
    </ReactFlowProvider>
  );
};

export default Simulation;
