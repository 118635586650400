export const getTheme = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light' && {
      primary: {
        main: '#FFF',
        contrastText: '#200D2C'
      },
      background: {
        default: '#FFF',
        light: '#ced4da',
        dark: '#FFF'
      }
    }),
    ...(mode === 'dark' && {
      primary: {
        main: '#1e0e31',
        light: '#FFF',
        contrastText: '#FFF'
      },
      background: {
        default: '#1e0e31',
        light: '#131124',
        dark: '#4d1f51'
      }
    })
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },


    MuiSelect: {
      styleOverrides: {},
    },
    MuiInputLabel: {
        styleOverrides: {
          root: {
            color: mode === "dark" ? "#fff" : "#200D2C",
            "&.Mui-focused": {
              color: mode === "dark" ? "#fff" : "#200D2C"
            }
          },
        }
      },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FFF"
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FFF"
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FFF"
          },
          color: "#FFF"
        }
      }
    },
    MuiClockPicker: mode === 'dark' && {
      styleOverrides: {
        root: {
          '& button': {
            '&:hover': {
              backgroundColor: '#ffffff14'
            }
          }
        }
      }
    }
  }
});
