import React, { useState } from "react";
import PropTypes from "prop-types";
import ImageViewer from "react-simple-image-viewer";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Vimeo from "@u-wave/react-vimeo";

const Wrapper = styled("div")(({ theme }) => ({
  position: 'relative'
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "& .MuiOutlinedInput-input": {
      color: theme.palette.primary.contrastText,
    },
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function isVimeoUrl(url) {
  const vimeoRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com\/)([0-9]{9})(.*)?$/;
  return vimeoRegex.test(url);
}

function getMediaType(image) {
    let mediaType = "image";
    if (image.includes(".mp4")) mediaType = "video";
    else if (image.includes(".mp3") || image.includes(".ogg"))
      mediaType = "audio";
    else mediaType = "image";
    return mediaType;
  }

export default function MediaViewSlider(props) {
  const [isViewerOpen] = useState(props.isViewerOpen);
  const [fileIndex, setFileIndex] = useState(props.currentImage);
  const [videoDuration, setVideoDuration] = useState(+localStorage.getItem("duration") || 0);
  const [currentTime, setCurrentTime] = useState(videoDuration);

  const viewNextImageName = () => {
    props.images.length === fileIndex+1 ? setFileIndex(0) : setFileIndex(fileIndex+1);
  }
  const viewPreviousImageName = () => {
    fileIndex === 0 ? setFileIndex(props.images.length-1) : setFileIndex(fileIndex-1);
  }

  const handleVideoPause = () => {
    setVideoDuration(currentTime);
    localStorage.setItem("duration", currentTime);
  };

  const handleVideoEnd = () => {
    setVideoDuration(0);
    localStorage.removeItem("duration");
  };

  let media = "";
  if (getMediaType(props.images[fileIndex]) === "audio") {
    media = (
      <audio autoPlay style={{ width: "850px", height: "450px" }} controls>
        <source src={props.images[fileIndex]} />
      </audio>
    );
  } else if (getMediaType(props.images[fileIndex]) === "video") {
      if (isVimeoUrl(props.images[fileIndex])) {
        media = (
          <div
            style={{
              width: "100%",
              height: "99%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Vimeo
              video={props.images[fileIndex]}
              autoplay
              preload
              start={videoDuration}
              responsive
              style={{
                width: "850px",
                height: "100%",
                padding: "0 !important",
                position: "static !important",
              }}
              onPause={handleVideoPause}
              onEnd={handleVideoEnd}
              onTimeUpdate={(time) =>
                setCurrentTime(time.seconds)
              }
            />
          </div>
        );
      } else {
        media = (
          <video autoPlay height={450} width={850} controls>
            <source src={props.images[fileIndex]} type="video/mp4" />
          </video>
        );
      }
  } else {
    media = <img src={props.images[fileIndex]} style={{ objectFit: 'contain' }} height={450} width={850} alt="" />;
  }

  return (
    isViewerOpen && (
      <div>
        <BootstrapDialog
          onClose={props.closeImageViewer}
          aria-labelledby="customized-dialog-title"
          open={isViewerOpen}
          maxWidth="xl"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={props.closeImageViewer}
            style={{
              width: "95%",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {props.imageNames[fileIndex]}
          </BootstrapDialogTitle>
          <DialogContent dividers sx={{ textAlign: "center" }}>
            <Wrapper>
              <KeyboardArrowLeftIcon onClick={(e) => viewPreviousImageName(props.currentImage, e)} sx={{ pointerEvents: fileIndex === 0 ? 'none' : 'auto', fontSize: 100, position: 'absolute', zIndex: 1, top: '50%', left: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }} />
              {media}
              <KeyboardArrowRightIcon onClick={(e) => viewNextImageName(props.currentImage, e)} sx={{ pointerEvents: fileIndex === props?.images?.length ? 'none' : 'auto', fontSize: 100, position: 'absolute', zIndex: 1, top: '50%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }} />
            </Wrapper>
          </DialogContent>
        </BootstrapDialog>
      </div>
    )
  );
}
