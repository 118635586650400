import { ROLES_ACTION } from "state/actions/user";

export const initialState = {
  allRoles: null,
  roles: null
};

const reducer = (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case ROLES_ACTION.UPDATE_ROLES:
      return {
        ...state,
        allRoles: data,
        roles: data?.map(item => item?.name),
      };

    default:
      return state;
  }
};

export default reducer;
