import { useContext } from "react";
import axios from "axios";
import { GlobalState } from "context/GlobalContext";
import Auth from "./auth";

const useAxios = (config = {}) => {
  const { setOpenApiSnackbar, setApiSnackbarMessage, setApiSnackbarSeverity } =
    useContext(GlobalState);
  const instance = (service) => {
    let url = "";
    if (service) {
      url = `${process.env.REACT_APP_API_URL}/${service}/${process.env.REACT_APP_DOMAIN_MODULE}`;
    }

    const axiosInstance = axios.create({
      ...config,
      baseURL: url,
      headers: {
        ...(config.headers || {}),
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: Auth.isLoggedIn()
          ? `Bearer ${Auth.getToken()}`
          : undefined,
      },
    });

    axiosInstance.interceptors.request.use(
      (req) => req,
      (error) => Promise.reject(error)
    );

    axiosInstance.interceptors.response.use(
      (res) => res,
      async (error) => {
        const originalRequest = error.config;
        let token = null;

        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            await Auth.updateToken(async (res) => {
              token = res.token || null;
              axios.defaults.headers.common["Authorization"] =
                "Bearer " + token;
              originalRequest.headers["Authorization"] = "Bearer " + token;
            });
            if (!token) {
              return Auth.doLogin();
            }
            return instance(originalRequest);
          } catch (err) {
            return Promise.reject(err);
          }
        } else if (error.response.status === 422) {
          setOpenApiSnackbar(true);
          setApiSnackbarMessage(error.response.data.error_description);
          setApiSnackbarSeverity("error");
        }
        return Promise.reject(error);
      }
    );
    return axiosInstance;
  };

  return {
    axios: instance("v1"),
    axios_v2: instance("v2"),
  };
};

export default useAxios;