/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { Formik, Form, FieldArray, FastField } from "formik";
import {
  Container,
  Grid,
  Stack,
  Typography,
  IconButton,
  Card,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import * as Yup from "yup";
import "./Simulation.css";
import StaticInfo from "./StaticInfo";
import CssButton from "Components/CssButton";
import Menus from "./Menus";
import useApi from "services/api-hook";
import useRequests from "services/request-hook";
import { GlobalState } from "context/GlobalContext";
import CssTitle from "Components/CssTitle";
import FileUpload from "Components/FileUpload";
import CssCircularProgress from "Components/CssCircularProgress";
import CssTextField from "Components/CssTextField";

const SUPPORTED_FORMATS_IMAGE = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "video/mp4",
];

const FeedbackSlideFormSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, "minimum length of a text is 3")
    .max(1000, "maximum length of a text is 1000")
    .required("Title is required"),
  slideNumber: Yup.string()
    .test('valid-feedback-slide', 'Feedback Slide No. must be a number or a range (e.g., 1.1, 1.2)', function(value) {
      if (!value) {
        return true;
      }
      const regex = /^\d+(\.\d+)*$/;
      return regex.test(value);
    }),
  description: Yup.string()
    .min(3, "minimum length of a text is 3")
    .max(1000, "maximum length of a text is 1000")
    .when('isDescription', {
      is: true,
      then: Yup.string().required('Description is required'),  // Description is required if isDescription is true
      otherwise: Yup.string().notRequired(), // If isDescription is false, it's not required
    }),
  image: Yup.mixed()
    .nullable()
    .notRequired()
    .test(
      "fileType",
      "Unsupported File Format",
      (value) => value === null || SUPPORTED_FORMATS_IMAGE.includes(value?.type)
    )
    .when('isDescription', {
      is: false, // If isDescription is false, image becomes required
      then: Yup.mixed().required("Image is required"), 
      otherwise: Yup.mixed().notRequired(), // If isDescription is true, image is not required
    }),
  image_apa: Yup.mixed()
    .nullable()
    .notRequired()
    .test("fileType", "Unsupported File Format", (value) => {
      if (value) {
        return value === null || SUPPORTED_FORMATS_IMAGE.includes(value?.type);
      } else {
        return true;
      }
    }),
  // static_info: Yup.array().of(
  //   Yup.object().shape({
  //     name: Yup.string()
  //       .min(3, "minimum length of a text is 3")
  //       .max(30, "maximum length of a text is 30"),
  //     value: Yup.string()
  //       .min(3, "minimum length of a text is 3")
  //       .max(30, "maximum length of a text is 30"),
  //   })
  // ),
  // menus: Yup.array().of(
  //   Yup.object().shape({
  //     type: Yup.string()
  //       .min(3, "minimum length of a text is 3")
  //       .max(30, "maximum length of a text is 30"),
  //     name: Yup.string()
  //       .min(3, "minimum length of a text is 3")
  //       .max(30, "maximum length of a text is 30"),
  //   })
  // ),
});

const CssAddIconButton = styled(IconButton)({
  position: "absolute",
  top: "33px",
  right: "-8px",
});

const CssAddIcon = styled(AddCircleTwoToneIcon)({
  "& .CssAddIcon": {
    color: "#FFF",
    fontSize: "2rem",
  },
});

const CssCard = styled(Card)({
  marginTop: "20px !important",
  padding: "6px 20px 20px",
  // position: "relative",
  display: "block !important",
  backgroundColor: "transparent",
  border: "1px solid #673068",
  textAlign: "center",
});

const FeedbackSlideForm = ({ handleResponse, node }) => {
  const { isPublished } = useContext(GlobalState);
  const { feedbackSlideApi, getInjectsApi, updateFeedbackSlideApi } =
    useRequests();
  const feedbackSlide = useApi(feedbackSlideApi);
  const injectDetails = useApi(getInjectsApi);
  const updateFeedbackSlide = useApi(updateFeedbackSlideApi);
  const [fileUrl, setFileUrl] = useState("");
  const [file2Url, setFile2Url] = useState("");
  const [callUpdate, setCallUpdate] = useState(false);
  const childFunc = React.useRef(null);
  const childFunc1 = React.useRef(null);
  const optionalImage = true;
  const [saveImg, setSaveImg] = useState(node?.elementId ? true : false);
  const [optionalImageSave, setOptionalImageSave] = useState(false);
  const [btnDisable, setBtnDisable] = React.useState(false);

  const [onDisable, setOnDisable] = useState(node?.elementId ? true : false);
  const [initialValues, setInitialValues] = useState({
    title: "",
    slideNumber: "",
    description: "",
    image: null,
    static_info: [],
    menus: [],
    image_apa: null,
    isDescription: true
  });

  const onFileUpload = ({ url }) => {
    if (url) {
      let finalUrl = url?.substring(0, url?.indexOf("?"));
      var path = finalUrl.split("/");
      finalUrl = process.env.REACT_APP_CDN_URL
        ? `${process.env.REACT_APP_CDN_URL}/${path[3]}/${path[4]}`
        : finalUrl;
      setFileUrl(finalUrl);
      setSaveImg(false);
    } else {
      setFileUrl("");
    }
  };
  const onFile2Upload = ({ url }) => {
    if (url) {
      let finalUrl = url?.substring(0, url?.indexOf("?"));
      setFile2Url(finalUrl);
    } else {
      setFile2Url("");
      optionalSaveImage(false);
    }
  };
  const saveOnDisable = (value) => {
    setOnDisable(value);
  };
  const saveImage = (value) => {
    setSaveImg(value);
  };

  const optionalSaveImage = (value) => {
    setOptionalImageSave(value);
  };

  const handleSpinner = (value) => {
    setBtnDisable(value);
  };

  useEffect(() => {
    setOptionalImageSave(false);
    if (node && node.elementId) {
      injectDetails
        .request(node.elementId)
        .then((res) => {
          if (res.status === 200) {
            setInitialValues(prev => ({...prev, ...res.data, isDescription: !res?.data?.image ? true : false}));
            setCallUpdate(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={FeedbackSlideFormSchema}
      isInitialValid={false}
      validateOnMount={true}
      onSubmit={(values) => {
        const { isDescription, ...payload } = values;
        payload.image = fileUrl ? fileUrl : payload.image;
        payload.image_apa = file2Url ? file2Url : payload.image_apa;
        if (isDescription) {
          payload.image = null;
        } else {
          payload.description = "";
          payload.title = "";
          payload.feedbackSlide = "";
        }
        if (callUpdate) {
          updateFeedbackSlide.request(node.elementId, payload).then((res) => {
            handleResponse(res);
          });
        } else {
          feedbackSlide.request(payload).then((res) => {
            handleResponse(res);
          });
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        isValid,
        setFieldValue,
        isSubmitting,
        dirty,
        handleBlur,
      }) => (
        <Form noValidate={true} autoComplete="off" className="form-wrapper">
          {typeof values?.image === "string" ? delete errors.image : ""}
          {typeof values?.image_apa === "string" ? delete errors.image_apa : ""}
          <Container className="container">
            <Grid container className="form-wrapper" direction="row">
              {values.isDescription && <Grid item xs={12}>
                <FastField
                  component={CssTextField}
                  variant="outlined"
                  size="small"
                  name="title"
                  id="title"
                  value={values.title}
                  inputProps={{ readOnly: isPublished }}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    setFieldValue("title", event.target.value);
                  }}
                  label="Title*"
                />
                {errors.title && touched.title ? (
                  <div className="field-error">{errors.title}</div>
                ) : null}
              </Grid>}
              <Grid item xs={12}>
                <FastField
                  component={CssTextField}
                  variant="outlined"
                  size="small"
                  name="slideNumber"
                  id="slideNumber"
                  value={values.slideNumber}
                  inputProps={{ readOnly: isPublished }}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    setFieldValue("slideNumber", event.target.value);
                  }}
                  label="Feedback Slide No."
                />
                {errors.slideNumber && touched.slideNumber ? (
                  <div className="field-error">{errors.slideNumber}</div>
                ) : null}
              </Grid>
              <div style={{
                display: 'flex',
                border: '1px solid white',
                marginTop: '15px',
                borderRadius: '4px',
                marginLeft: 'auto',
              }} className='text-sm flex border border-grey-200 rounded-md overflow-hidden'>
                <span 
                  style={{ cursor: 'pointer', background: values?.isDescription ? '#673068' : "transparent"}} 
                  onClick={() => setFieldValue("isDescription", true)} 
                  className="cursor-pointer px-3 py-1">
                    Description
                </span>
                <span 
                  style={{ cursor: 'pointer', background: !values?.isDescription ? '#673068' : "transparent"}} 
                  onClick={() => setFieldValue("isDescription", false)} 
                  className="cursor-pointer px-3 py-1">
                    Image
                </span>
              </div>
              {values?.isDescription && <Grid item xs={12} style={{ marginBottom: '10px'}}>
                <FastField
                  component={CssTextField}
                  variant="outlined"
                  size="small"
                  name="description"
                  id="description"
                  multiline
                  rows={4}
                  value={values.description}
                  inputProps={{ readOnly: isPublished }}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    setFieldValue("description", event.target.value);
                  }}
                  label="Description*"
                />
                {errors.description && touched.description ? (
                  <div className="field-error">{errors.description}</div>
                ) : null}
              </Grid>}
              <Grid item xs={12}>
                {!values?.isDescription && <div style={{ paddingTop: "5px" }}>
                  <FileUpload
                    name="image"
                    id="image"
                    multiple={false}
                    selectedFile={values?.image}
                    optionalImage={!optionalImage}
                    saveImage={saveImage}
                    onFileUpload={onFileUpload}
                    optionalSaveImage={optionalSaveImage}
                    accept={{ "image/*": [".jpeg", ".png"], "video/*": [".mp4"] }}
                    childFunc={childFunc1}
                    optionalImageSave={!optionalImageSave}
                    bucket={"inject-slides"}
                    isValid={false}
                    onDisable={onDisable}
                    saveOnDisable={saveOnDisable}
                    title="Slide Image*"
                    handleSpinner={handleSpinner}
                  />
                  {errors.image && errors.image ? (
                    <div
                      className="errorText"
                      style={{ position: "relative", top: 0 }}
                    >
                      {errors.image}
                    </div>
                  ) : null}
                </div>}
                <div style={{ paddingTop: "25px" }}>
                  <FileUpload
                    name="image_apa"
                    id="image_apa"
                    multiple={false}
                    optionalImage={optionalImage}
                    saveImage={saveImage}
                    selectedFile={values?.image_apa}
                    optionalSaveImage={optionalSaveImage}
                    onFileUpload={onFile2Upload}
                    accept={{ "image/*": [".jpeg", ".png"], "video/*": [".mp4"] }}
                    childFunc={childFunc}
                    optionalImageSave={optionalImageSave}
                    bucket={"inject-slides"}
                    onDisable={onDisable}
                    saveOnDisable={saveOnDisable}
                    title="Slide Image APA"
                    isPublished={isPublished}
                    handleSpinner={handleSpinner}
                  />
                  {errors.image_apa && errors.image_apa ? (
                    <div
                      className="errorText"
                      style={{ top: 0, position: "relative" }}
                    >
                      {errors.image_apa}
                    </div>
                  ) : null}
                </div>
              </Grid>
            </Grid>
            <Grid container className="form-wrapper" direction="row">
              <FieldArray name="static_info">
                {({ insert, remove, push }) => (
                  <>
                    <Grid item xs={12} className="field-array-title-wrapper">
                      <Typography variant="h5" className="field-array-title">
                        Static Info
                      </Typography>
                      {values?.static_info?.length < 6 && isPublished ? null : (
                        <CssAddIconButton
                          onClick={() => push({ name: "", value: "" })}
                        >
                          <CssAddIcon />
                        </CssAddIconButton>
                      )}
                    </Grid>
                    {values.static_info.length === 0 ? (
                      <Grid
                        container
                        component={CssCard}
                        className="card-wrapper"
                      >
                        <CssTitle
                          variant="h6"
                          sx={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            !isPublished && push({ name: "", value: "" })
                          }
                        >
                          Add Static Info
                        </CssTitle>
                      </Grid>
                    ) : (
                      values?.static_info?.map((item, index) => (
                        <StaticInfo
                          key={index}
                          index={index}
                          errors={errors}
                          touched={touched}
                          onRemove={remove}
                          staticInfos={item}
                          enableClose={values.static_info.length > 0}
                          onSetFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          isPublished={isPublished}
                        />
                      ))
                    )}
                  </>
                )}
              </FieldArray>
            </Grid>
            <Grid
              container
              className="form-wrapper menus-form-wrapper"
              direction="row"
            >
              <FieldArray name="menus">
                {({ insert, remove, push }) => (
                  <>
                    <Grid item xs={12} className="field-array-title-wrapper">
                      <Typography variant="h5" className="field-array-title">
                        Menus
                      </Typography>
                      {isPublished ? null : (
                        <CssAddIconButton
                          onClick={() =>
                            push({
                              type: "",
                              name: "",
                              menu_items: [
                                {
                                  label: "",
                                  content_type: "2D",
                                  content: "",
                                },
                              ],
                            })
                          }
                        >
                          <CssAddIcon />
                        </CssAddIconButton>
                      )}
                    </Grid>
                    {values.menus.length === 0 ? (
                      <Grid
                        container
                        component={CssCard}
                        className="card-wrapper"
                      >
                        <CssTitle
                          variant="h6"
                          sx={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            !isPublished &&
                            push({
                              type: "",
                              name: "",
                              menu_items: [
                                {
                                  label: "",
                                  content_type: "2D",
                                  content: "",
                                },
                              ],
                            })
                          }
                        >
                          Add Menus
                        </CssTitle>
                      </Grid>
                    ) : (
                      values.menus.map((item, index) => (
                        <Menus
                          key={index}
                          values={values}
                          index={index}
                          errors={errors}
                          touched={touched}
                          onRemove={remove}
                          menus={item}
                          enableClose={values.menus.length > 0}
                          onSetFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                        />
                      ))
                    )}
                  </>
                )}
              </FieldArray>
            </Grid>
          </Container>
          <Grid className="button-stack form-btn-wrapper">
            <Stack spacing={2} direction="row">
              {node?.elementId ? (
                <CssButton
                  variant="contained"
                  size="medium"
                  type="submit"
                  disabled={
                    !isValid ||
                    isSubmitting ||
                    isPublished ||
                    !dirty ||
                    // !saveImg ||
                    // optionalImageSave ||
                    btnDisable
                  }
                >
                  {isSubmitting ? <CssCircularProgress size={20} /> : "Update"}
                </CssButton>
              ) : (
                <CssButton
                  variant="contained"
                  size="medium"
                  type="submit"
                  disabled={
                    !isValid ||
                    isSubmitting ||
                    isPublished ||
                    // !saveImg ||
                    !dirty ||
                    // optionalImageSave ||
                    btnDisable
                  }
                >
                  {isSubmitting ? <CssCircularProgress size={20} /> : "Save"}
                </CssButton>
              )}

              {node && node.elementId ? null : (
                <CssButton
                  type="reset"
                  variant="contained"
                  onClick={() => {
                    childFunc.current();
                    childFunc1.current();
                  }}
                  size="medium"
                  disabled={!dirty}
                  sx={{ backgroundColor: "#673068" }}
                >
                  Reset
                </CssButton>
              )}
            </Stack>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default FeedbackSlideForm;
