import React, { useContext } from "react";
import { Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import SignalCellular4BarIcon from "@mui/icons-material/SignalCellular4Bar";
import { Handle } from "react-flow-renderer";
import "./Simulation.css";
import { GlobalState } from "context/GlobalContext";
import { truncate } from "utils";

const CssTriangle = styled(SignalCellular4BarIcon)({
  color: "#FFF",
  position: "absolute",
  bottom: 0,
  right: 0,
  "&.triangle-top-left": {
    transform: "rotate(180deg)",
    left: "-2px",
    top: "-2px",
  },
  "&.triangle-bottom-right": {
    bottom: "-2px",
    right: "-2px",
  },
});

const Track = (props) => {
  const { nodesDetails, setNodesDetails, isPublished } =
    useContext(GlobalState);

  const onNodeNameChange = (e) => {
    setNodesDetails({
      ...nodesDetails,
      [`${props.id}`]: { enableInput: true, label: e.target.value },
    });
  };

  const onNodeNameBlur = (e) => {
    setNodesDetails({
      ...nodesDetails,
      [`${props.id}`]: { enableInput: false, label: e.target.value },
    });
  };

  return (
    <Grid
      item
      className={isPublished ? "node is-published" : "node"}
      style={{ height: "100px" }}
    >
      <CssTriangle className="triangle triangle-top-left" />
      <CssTriangle className="triangle triangle-bottom-right" />
      {nodesDetails[`${props.id}`] &&
      nodesDetails[`${props.id}`].enableInput ? (
        <textarea
          autoFocus
          className="node-textarea"
          onChange={onNodeNameChange}
          onBlur={onNodeNameBlur}
        >
          {props.data.label}
        </textarea>
      ) : (
        <>
          <h6 className="node-inline-naming">Track</h6>
          <span className="node-name" title={props.data.label}>
            {props.data.label ? truncate(props.data.label, 10) : "Track"}
          </span>
        </>
      )}
      {!isPublished && (
        <IconButton className="close-button">
          <CloseIcon className="icon-close" />
        </IconButton>
      )}
      <Handle type="source" position="right" className="rightEdge" />
    </Grid>
  );
};

const IntroductionTrailer = (props) => {
  const { nodesDetails, setNodesDetails, isPublished } =
    useContext(GlobalState);

  const onNodeNameChange = (e) => {
    setNodesDetails({
      ...nodesDetails,
      [`${props.id}`]: { enableInput: true, label: e.target.value },
    });
  };

  const onNodeNameBlur = (e) => {
    setNodesDetails({
      ...nodesDetails,
      [`${props.id}`]: { enableInput: false, label: e.target.value },
    });
  };

  return (
    <Grid
      item
      className={isPublished ? "node is-published" : "node"}
      style={{ height: "100px" }}
    >
      <CssTriangle className="triangle triangle-top-left" />
      <CssTriangle className="triangle triangle-bottom-right" />
      {nodesDetails[`${props.id}`] &&
      nodesDetails[`${props.id}`].enableInput ? (
        <textarea
          autoFocus
          className="node-textarea"
          onChange={onNodeNameChange}
          onBlur={onNodeNameBlur}
        >
          {props.data.label}
        </textarea>
      ) : (
        <>
          <h6 className="node-inline-naming">Trailer</h6>
          <span className="node-name" title={props.data.label}>
            {props.data.label ? truncate(props.data.label, 10) : "Trailer"}
          </span>
        </>
      )}
      {!isPublished && (
        <IconButton className="close-button">
          <CloseIcon className="icon-close" />
        </IconButton>
      )}
      <Handle type="source" position="right" className="rightEdge" />
      <Handle type="target" position="left" className="leftEdge" />
    </Grid>
  );
};

const Scene = (props) => {
  const { nodesDetails, setNodesDetails, isPublished } =
    useContext(GlobalState);

  const onNodeNameChange = (e) => {
    setNodesDetails({
      ...nodesDetails,
      [`${props.id}`]: { enableInput: true, label: e.target.value },
    });
  };

  const onNodeNameBlur = (e) => {
    setNodesDetails({
      ...nodesDetails,
      [`${props.id}`]: { enableInput: false, label: e.target.value },
    });
  };

  return (
    <Grid
      item
      className={isPublished ? "node is-published" : "node"}
      style={{ height: "100px" }}
    >
      <CssTriangle className="triangle triangle-top-left" />
      <CssTriangle className="triangle triangle-bottom-right" />
      {nodesDetails[`${props.id}`] &&
      nodesDetails[`${props.id}`].enableInput ? (
        <textarea
          autoFocus
          className="node-textarea"
          onChange={onNodeNameChange}
          onBlur={onNodeNameBlur}
        >
          {props.data.label}
        </textarea>
      ) : (
        <>
          <h6 className="node-inline-naming">Scene</h6>
          <span className="node-name" title={props.data.label}>
            {props.data.label ? truncate(props.data.label, 10) : "Scene"}
          </span>
        </>
      )}
      {!isPublished && (
        <IconButton className="close-button">
          <CloseIcon className="icon-close" />
        </IconButton>
      )}
      <Handle type="source" position="right" className="rightEdge" />
      <Handle type="target" position="left" className="leftEdge" />
    </Grid>
  );
};

const Questions = (props) => {
  const { nodesDetails, setNodesDetails, isPublished } =
    useContext(GlobalState);

  const onNodeNameChange = (e) => {
    setNodesDetails({
      ...nodesDetails,
      [`${props.id}`]: { enableInput: true, label: e.target.value },
    });
  };

  const onNodeNameBlur = (e) => {
    setNodesDetails({
      ...nodesDetails,
      [`${props.id}`]: { enableInput: false, label: e.target.value },
    });
  };

  return (
    <Grid
      item
      className={isPublished ? "node is-published" : "node"}
      style={{ height: "100px" }}
    >
      <CssTriangle className="triangle triangle-top-left" />
      <CssTriangle className="triangle triangle-bottom-right" />
      {nodesDetails[`${props.id}`] &&
      nodesDetails[`${props.id}`].enableInput ? (
        <textarea
          autoFocus
          className="node-textarea"
          onChange={onNodeNameChange}
          onBlur={onNodeNameBlur}
        >
          {props.data.label}
        </textarea>
      ) : (
        <>
          <h6 className="node-inline-naming">Questions</h6>
          <span className="node-name" title={props.data.label}>
            {props.data.label ? truncate(props.data.label, 10) : "Questions"}
          </span>
        </>
      )}

      {!isPublished && (
        <IconButton className="close-button">
          <CloseIcon className="icon-close" />
        </IconButton>
      )}
      <Handle type="source" position="right" className="rightEdge" />
      <Handle type="target" position="left" className="leftEdge" />
    </Grid>
  );
};

const FeedbackSlide = (props) => {
  const { nodesDetails, setNodesDetails, isPublished } =
    useContext(GlobalState);

  const onNodeNameChange = (e) => {
    setNodesDetails({
      ...nodesDetails,
      [`${props.id}`]: { enableInput: true, label: e.target.value },
    });
  };

  const onNodeNameBlur = (e) => {
    setNodesDetails({
      ...nodesDetails,
      [`${props.id}`]: { enableInput: false, label: e.target.value },
    });
  };

  return (
    <Grid
      item
      className={isPublished ? "node is-published" : "node"}
      style={{ height: "100px" }}
    >
      <CssTriangle className="triangle triangle-top-left" />
      <CssTriangle className="triangle triangle-bottom-right" />
      {nodesDetails[`${props.id}`] &&
      nodesDetails[`${props.id}`].enableInput ? (
        <textarea
          autoFocus
          className="node-textarea"
          onChange={onNodeNameChange}
          onBlur={onNodeNameBlur}
        >
          {props.data.label}
        </textarea>
      ) : (
        <>
          <h6 className="node-inline-naming">Feedback Slide</h6>
          <span className="node-name" title={props.data.label}>
            {props.data.label
              ? truncate(props.data.label, 10)
              : "Feedback Slide"}
          </span>
        </>
      )}
      {!isPublished && (
        <IconButton className="close-button">
          <CloseIcon className="icon-close" />
        </IconButton>
      )}
      <Handle type="source" position="right" className="rightEdge" />
      <Handle type="target" position="left" className="leftEdge" />
    </Grid>
  );
};

const DecisionPoint = (props) => {
  const { nodesDetails, setNodesDetails, isPublished } =
    useContext(GlobalState);

  const onNodeNameChange = (e) => {
    setNodesDetails({
      ...nodesDetails,
      [`${props.id}`]: { enableInput: true, label: e.target.value },
    });
  };

  const onNodeNameBlur = (e) => {
    setNodesDetails({
      ...nodesDetails,
      [`${props.id}`]: { enableInput: false, label: e.target.value },
    });
  };

  return (
    <Grid
      item
      className={isPublished ? "node is-published" : "node"}
      style={{ height: "100px" }}
    >
      <CssTriangle className="triangle triangle-top-left" />
      <CssTriangle className="triangle triangle-bottom-right" />
      {nodesDetails[`${props.id}`] &&
      nodesDetails[`${props.id}`].enableInput ? (
        <textarea
          autoFocus
          className="node-textarea"
          onChange={onNodeNameChange}
          onBlur={onNodeNameBlur}
        >
          {props.data.label}
        </textarea>
      ) : (
        <>
          <h6 className="node-inline-naming">Decision Point</h6>
          <span className="node-name" title={props.data.label}>
            {props.data.label
              ? truncate(props.data.label, 10)
              : "Decision Point"}
          </span>
        </>
      )}

      {!isPublished && (
        <IconButton className="close-button">
          <CloseIcon className="icon-close" />
        </IconButton>
      )}
      <Handle type="source" position="right" className="rightEdge" />
      <Handle type="target" position="left" className="leftEdge" />
    </Grid>
  );
};

const Choices = (props) => {
  const { nodesDetails, setNodesDetails, isPublished } =
    useContext(GlobalState);

  const onNodeNameChange = (e) => {
    setNodesDetails({
      ...nodesDetails,
      [`${props.id}`]: { enableInput: true, label: e.target.value },
    });
  };

  const onNodeNameBlur = (e) => {
    setNodesDetails({
      ...nodesDetails,
      [`${props.id}`]: { enableInput: false, label: e.target.value },
    });
  };

  return (
    <Grid
      item
      className={isPublished ? "node is-published" : "node"}
      style={{ height: "100px" }}
    >
      <CssTriangle className="triangle triangle-top-left" />
      <CssTriangle className="triangle triangle-bottom-right" />
      {nodesDetails[`${props.id}`] &&
      nodesDetails[`${props.id}`].enableInput ? (
        <textarea
          autoFocus
          className="node-textarea"
          onChange={onNodeNameChange}
          onBlur={onNodeNameBlur}
        >
          {props.data.label}
        </textarea>
      ) : (
        <>
          <h6 className="node-inline-naming">Choices</h6>
          <span className="node-name" title={props.data.label}>
            {props.data.label ? truncate(props.data.label, 10) : "Choices"}
          </span>
        </>
      )}
      {!isPublished && (
        <IconButton className="close-button">
          <CloseIcon className="icon-close" />
        </IconButton>
      )}
      <Handle type="source" position="right" className="rightEdge" />
      <Handle type="target" position="left" className="leftEdge" />
    </Grid>
  );
};

export const nodeTypes = {
  track: Track,
  introductionTrailer: IntroductionTrailer,
  scene: Scene,
  questions: Questions,
  feedbackSlide: FeedbackSlide,
  decisionPoint: DecisionPoint,
  choices: Choices,
};
