/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CssTitle from "Components/CssTitle";
import CssButton from "Components/CssButton";
import ModalContainer from "Components/ModalContainer";
import { Formik, Form, FastField } from "formik";
import { Grid, Stack, Container, MenuItem, FormControl, InputLabel, Checkbox } from "@mui/material";
import CssTextField from "Components/CssTextField";
import UsersList from "./UsersList";
import useRequests from "services/request-hook";
import useApi from "services/api-hook";
import InputFileUpload from "Components/InputFileUpload";
import * as Yup from "yup";
import CustomSnackbar from "Components/CustomSnackbar";
import CssCircularProgress from "Components/CssCircularProgress";
import CssSelectField from "Components/CssSelectField";
import ConfirmDialog from "Components/ConfirmDialog";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CssCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme?.palette?.primary?.contrastText,
  "& .MuiSvgIcon-root": {
    color: theme?.palette?.primary?.contrastText,
  }
}));

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "minimum of 3 characters")
    .max(30, "maximum of 30 characters")
    .matches(/^[A-Za-z]{2}[ A-Za-z0-9_-]*$/, "Name must be alphanumeric  only")
    .required("Name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is Required"),
  roles: Yup.lazy(value => {
    if (Array.isArray(value)) {
      return Yup.array()
        .required("Role is Required");
    } else {
      return Yup.string()
        .required("Please Select a role");
    }
  })
});

const Users = () => {
  const roles = useSelector(state => state?.userReducer?.roles);
  const navigate = useNavigate();
  const { getUsersListApi, createUserApi, getRolesListApi, updateUserRolesApi, deleteUserApi } = useRequests();
  const getUsersList = useApi(getUsersListApi);
  const createUser = useApi(createUserApi);
  const deleteUser = useApi(deleteUserApi);
  const getRolesList = useApi(getRolesListApi);
  const updateUserRole = useApi(updateUserRolesApi);
  const [open, setOpen] = useState(false);
  const [currentRoles, setCurrentRoles] = useState([]);
  const [callUpdate, setCallUpdate] = useState(false);
  const [usersDataList, setUsersDataList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [fileUrl, setFileUrl] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [openUserRoleDeleteAlert, setOpenUserRoleDeleteAlert] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    title: "",
    avatar: "",
    roles: "",
    conference_id: "",
  });

  const setSnackBar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleRoleOptions = (currentRoles) => {
    if (currentRoles?.length >= 1) {
      if (currentRoles?.includes?.("Super Admin")) {
        const newRoleOptions = roleOptions.map(item => {
          if (item?.value === "Super Configurator") {
            return { ...item, disabled: true }
          }
          
          return { ...item, disabled: false }
        })
        setRoleOptions(newRoleOptions);
      }
      if (currentRoles?.includes?.("AssociateConfigurator") || currentRoles?.includes?.("Associate Configurator_Dental") || currentRoles?.includes?.("Associate Configurator_Medical") || currentRoles?.includes?.("Associate Configurator_Emergence") || currentRoles?.includes?.("Associate Configurator_Cyber")) {
        const newRoleOptions = roleOptions.map(item => {
          if (item?.value === "Super Configurator" || item?.value === "DomainConfigurator_Dental" || item?.value === "DomainConfigurator_Cyber" || item?.value === "DomainConfigurator_Medical" || item?.value === "DomainConfigurator_Emergence") {
            return { ...item, disabled: true }
          }
          if (currentRoles?.includes?.("AssociateConfigurator")) {
            if (item?.value === "Associate Configurator_Dental" || item?.value === "Associate Configurator_Medical" || item?.value === "Associate Configurator_Emergence" || item?.value === "Associate Configurator_Cyber") {
              return { ...item, disabled: true }
            }
          }

          return { ...item, disabled: false }
        })
        setRoleOptions(newRoleOptions);
      } else if (currentRoles?.includes?.("Super Configurator") || currentRoles?.includes?.("DomainConfigurator_Dental") || currentRoles?.includes?.("DomainConfigurator_Cyber") || currentRoles?.includes?.("DomainConfigurator_Medical") || currentRoles?.includes?.("DomainConfigurator_Emergence")) {
        const newRoleOptions = roleOptions.map(item => {
          if (item?.value === "Super Configurator" || item?.value === "AssociateConfigurator" || item?.value === "Associate Configurator_Dental" || item?.value === "Associate Configurator_Medical" || item?.value === "Associate Configurator_Emergence" || item?.value === "Associate Configurator_Cyber") {
            return { ...item, disabled: true }
          }
          
          return { ...item, disabled: false }
        })
        setRoleOptions(newRoleOptions);
      }
      if (currentRoles?.includes?.("Super Configurator")) {
        const newRoleOptions = roleOptions.map(item => {
          if (item?.value === "Super Configurator") {
            return { ...item, disabled: false }
          }
          return { ...item, disabled: true }
        })
        setRoleOptions(newRoleOptions);
      } 
    } else {
      const newRoleOptions = roleOptions.map(item => ({ ...item, disabled: false }));
      setRoleOptions(newRoleOptions);
    }
    
  }

  const onUserRoleDeletepdate = (isDelete) => {
    if (isDelete) {
      deleteUser
        .request(deleteUserId)
        .then(res => {
          // if (res?.status === 200) {
            setOpenUserRoleDeleteAlert(false);
            fetchUsersList(page, rowsPerPage);
          // }
        })
        .catch(err => console.error(err));
    } else {
      setOpenUserRoleDeleteAlert(false);
    }
  }

  const openDeleteConfirmation = (userDetail) => {
    setOpenUserRoleDeleteAlert(true);
    setDeleteUserId(userDetail?._id);
  }

  const openHandleEdit = (userDetail) => {
    setCallUpdate(true);
    setInitialValues({
      id: userDetail?._id || "",
      name: userDetail?.name || "",
      email: userDetail?.email || "",
      title: userDetail?.title || "",
      avatar: userDetail?.avatar?.url || "",
      roles: userDetail?.roles?.map(item => item?.name) || [],
      conference_id: userDetail?.conference_id || ""
    });

    // Updates roles(checkbox disabled or not) option in Role field
    handleRoleOptions(userDetail?.roles?.map(item => item?.name));
    
    setCurrentRoles(userDetail?.roles?.map(item => item?.name) || []);
    handleOpen();
  }

  const fetchUsersList = (pageNO, rowsPerPages) => {
    getUsersList
      .request(pageNO + 1, rowsPerPages)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            setRowsPerPage(+res?.headers?.limit);
            setCount(+res?.headers?.total_count);
            setUsersDataList([...res.data]);
          } else {
            setUsersDataList([]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // Redirect to home page is user is not Super Admin
    if (!roles?.includes("Super Admin")) {
      navigate('/');
    }

    fetchUsersList(page, rowsPerPage);

    const fetchRolesList = () => {
      // Calling API to get all roles list
      getRolesList
        .request()
        .then((res) => {
          if (res.status === 200) {
            const roles = res?.data?.map(item => ({ value: item?.name, label: item?.label }))
            setRoleOptions(roles);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

    fetchRolesList();
  }, []);

  const onFileUpload = ({ url }) => {
    if (url) {
      let finalUrl = url?.substring(0, url?.indexOf("?"));
      var path = finalUrl.split("/");
      finalUrl = process.env.REACT_APP_CDN_URL
        ? `${process.env.REACT_APP_CDN_URL}/${path[3]}/${path[4]}`
        : finalUrl;
      setFileUrl(finalUrl);
    }
  };

  const handleCloseDrawer = () => {
    setOpen(false);
    if (callUpdate) {
      setCallUpdate(false);
      setInitialValues({
        name: "",
        email: "",
        title: "",
        avatar: "",
        roles: "",
        conference_id: ""
      });
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            spacing={2}
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
          >
            <CssTitle variant="h4">Users</CssTitle>
            <CssButton
              variant="contained"
              onClick={handleOpen}
              sx={{ width: "max-content" }}
            >
              Add User
            </CssButton>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <UsersList
            usersDataList={usersDataList}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            setCount={setCount}
            count={count}
            fetchUsersList={fetchUsersList}
            openHandleEdit={openHandleEdit}
            openDeleteConfirmation={openDeleteConfirmation}
          />
        </Grid>
      </Grid>
      <ModalContainer
        open={open}
        handleClose={handleCloseDrawer}
        title={`${callUpdate ? "Edit" : "Add"} User`}
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          validateOnMount={true}
          isInitialValid={false}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            if (callUpdate) {
              let addRole = [], removeRole = [];
              values.roles.map(item => {
                if (!currentRoles.includes(item)) {
                  addRole.push(item);
                }
                return item;
              })
              currentRoles.map(item => {
                if (!values.roles.includes(item)) {
                  removeRole.push(item);
                }
                return item;
              })
              updateUserRole
                .request(initialValues?.id, {
                  addedRole: addRole,
                  RemovedRole: removeRole
                })
                .then(res => {
                  setSubmitting(false);
                  fetchUsersList(page, rowsPerPage);
                  handleCloseDrawer();
                  setSnackBar();
                  if (removeRole?.includes("Super Admin")) {
                    window.location.href = "/";
                  }
                })
                .catch((error) => {
                  console.log("Error", error);
                  setSubmitting(false);
                })
            } else {
              const payload = {
                name: values.name,
                email: values.email,
                title: values.title.trim(),
                avatar: { url: fileUrl ? fileUrl : values?.avatar },
                roles: [values.roles],
                org_id: "axon",
              };
              if (values.conference_id.length !== 0) {
                payload.conference_id = values.conference_id.trim();
              }
              createUser
                .request(payload)
                .then((res) => {
                  if (res.status === 200) {
                    setSubmitting(false);
                    fetchUsersList(page, rowsPerPage);
                    setOpen(false);
                    setSnackBar();
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                  setSubmitting(false);
                });
            }
          }}
        >
          {({
            dirty,
            isValid,
            values,
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            handleBlur,
          }) => (
            <Form
              onSubmit={handleSubmit}
              noValidate={true}
              autoComplete="off"
              className="form-wrapper formSpace"
            >
              <Container sx={{ marginTop: "10px" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <div className="form_control">
                      <FastField
                        component={CssTextField}
                        variant="outlined"
                        size="small"
                        name="name"
                        id="name"
                        label="Name*"
                        autoComplete="Off"
                        value={values.name}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("name", e.target.value);
                        }}
                        disabled={callUpdate}
                        sx={{ marginTop: "0px" }}
                      />
                      {errors.name && touched.name ? (
                        <div className="errorText">{errors.name}</div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="form_control">
                      <FastField
                        component={CssTextField}
                        variant="outlined"
                        size="small"
                        name="email"
                        id="email"
                        label="Email*"
                        type="email"
                        autoComplete="Off"
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("email", e.target.value);
                        }}
                        disabled={callUpdate}
                        sx={{ marginTop: "0px" }}
                      />
                      {errors.email && touched.email ? (
                        <div className="errorText">{errors.email}</div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="form_control">
                      <FormControl fullWidth size="small">
                        <InputLabel id="type" className="select-label">
                          Role*
                        </InputLabel>
                        <FastField
                          component={CssSelectField}
                          multiple={callUpdate}
                          variant="outlined"
                          size="small"
                          name="roles"
                          id="roles"
                          label="Role"
                          autoComplete="Off"
                          value={values.roles}
                          onBlur={handleBlur}
                          renderValue={(selected) => callUpdate ? selected?.join(', ') : selected}
                          onChange={(e) => {
                            let value = e.target.value;
                            handleRoleOptions(value);
                            if (value?.includes("AssociateConfigurator") && (value?.includes("Associate Configurator_Dental") || value?.includes("Associate Configurator_Medical") || value?.includes("Associate Configurator_Emergence") || value?.includes("Associate Configurator_Cyber"))) {
                              value = value.filter(item => {
                                if (item?.includes("Associate Configurator_Dental") || item?.includes("Associate Configurator_Medical") || item?.includes("Associate Configurator_Emergence") || item?.includes("Associate Configurator_Cyber")) {
                                    return false;
                                }
                                return true;
                            })
                            }
                            setFieldValue("roles", value);
                          }}
                          sx={{ marginTop: "0px" }}
                        >
                          {roleOptions &&
                            roleOptions.map((item, i) => (
                              <MenuItem disabled={item?.disabled || false} key={item.label} value={item.value}>
                                {callUpdate && <CssCheckbox checked={values.roles.indexOf(item.value) > -1} />}
                                {item.label}
                              </MenuItem>
                            ))}
                        </FastField>
                      </FormControl>
                      {errors.roles && touched.roles ? (
                        <div className="errorText">{errors.roles}</div>
                      ) : null}
                    </div>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <div className="form_control">
                      <FastField
                        component={CssTextField}
                        variant="outlined"
                        size="small"
                        name="title"
                        id="title"
                        label="Title"
                        autoComplete="Off"
                        value={values.title}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("title", e.target.value);
                        }}
                        disabled={callUpdate}
                        sx={{ marginTop: "0px" }}
                      />
                      {errors.title && touched.title ? (
                        <div className="errorText">{errors.title}</div>
                      ) : null}
                    </div>
                  </Grid> */}
                  <Grid item xs={12}>
                    <div className="form_control">
                      <FastField
                        component={CssTextField}
                        variant="outlined"
                        size="small"
                        name="conference_id"
                        id="conference_id"
                        label="Conference Id"
                        autoComplete="Off"
                        value={values.conference_id}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("conference_id", e.target.value);
                        }}
                        disabled={callUpdate}
                        sx={{ marginTop: "0px" }}
                      />
                      {errors.conference_id && touched.conference_id ? (
                        <div className="errorText">{errors.conference_id}</div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={2} direction="row">
                      <InputFileUpload
                        onFileUpload={onFileUpload}
                        bucket={"other"}
                        acceptType="image/*"
                        stackInput={{
                          name: "avatar",
                          label: "Avatar",
                          placeholder: "Avatar",
                        }}
                        isDisabled={callUpdate}
                        id="avatar-image"
                      />
                    </Stack>
                  </Grid>
                  <Grid className="button-stack form-btn-wrapper">
                    <Stack spacing={2} direction="row">
                      <CssButton
                        variant="contained"
                        size="medium"
                        type="submit"
                        disabled={!isValid || isSubmitting}
                      >
                        {isSubmitting ? <CssCircularProgress size={20} /> : callUpdate ? "Update" : "Save"}
                      </CssButton>
                      <CssButton
                        variant="contained"
                        sx={{ backgroundColor: "#673068" }}
                        size="medium"
                        type="reset"
                        disabled={!dirty}
                      >
                        Reset
                      </CssButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Container>
            </Form>
          )}
        </Formik>
      </ModalContainer>
      <ConfirmDialog
        open={openUserRoleDeleteAlert}
        text={`Are you sure, you would like to Delete this User?`}
        handleYesNo={onUserRoleDeletepdate}
      />
      <CustomSnackbar
        open={openSnackbar}
        message={"Data Saved Successfully"}
        handleClose={handleCloseSnackbar}
      />
    </>
  );
};

export default Users;
