import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';
import './Simulation.css';

const CssTriangle = styled(SignalCellular4BarIcon)({
  color: '#727375',
  position: 'absolute',
  bottom: 0,
  right: 0,
  '&.triangle-top-left': {
    transform: 'rotate(180deg)',
    left: '-2px',
    top: '-2px'
  },
  '&.triangle-bottom-right': {
    bottom: '-2px',
    right: '-2px'
  }
});

const Node = ({ nodeData }) => {
  const { data, type } = nodeData;

  const onDragStart = (event) => {
    event.dataTransfer.setData('type/reactflow', type);
    event.dataTransfer.setData('label/reactflow', data.label);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <Grid 
      item 
      className='node' 
      xs={7} 
      onDragStart={(event) => onDragStart(event)}
      draggable
    >
      <CssTriangle className='triangle-top-left' />
      <CssTriangle className='triangle-bottom-right' />
      <span>{data.label}</span>
    </Grid>
  );
}

export default Node;

