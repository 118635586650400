import { styled } from '@mui/material/styles';
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const CssDateTimePicker = styled(DatePicker)({
    // '& .MuiTextField-root': {
    //     color: '#FFF !important'
    // },
    // '& svg': {
    //     fill: "#fff"
    // }
});

export default CssDateTimePicker;