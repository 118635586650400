import Keycloak from 'keycloak-js';

class CustomKeycloak extends Keycloak {

  /**
   * Since updateToken() will just return boolean value, 
   * to access updated token we've customized
   * @param {*} minValidity 
   * @returns {token, refreshToken}
   */
  async customUpdateToken(minValidity) {
    const updated = await this.updateToken(minValidity);

    return updated ? {
      token: this.token,
      refreshToken: this.refreshToken
    } : { token: '', refreshToken: '' }
  }
}

export default CustomKeycloak;