export const setNodesData = (data) => {
  return {
    type: 'SET_NODES_DATA',
    data: data
  };
};

export const setNodeData = (data) => {
  return {
    type: 'SET_NODE_DATA',
    data: data
  };
};

export const updateNodesData = (data) => {
  return {
    type: 'UPDATE_NODES_DATA',
    data: data
  };
};