import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import _ from "lodash";
import { Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import MacDownload from "../../Theme/Images/mac-download.png";
import WinDownload from "../../Theme/Images/win-download.png"
import CustomSnackbar from "Components/CustomSnackbar";

const CssTablePagination = styled(TablePagination)(({ theme }) => ({
  "& .MuiTablePagination-selectLabel": {
    marginBottom: "0px",
  },
  "& .MuiTablePagination-displayedRows": {
    marginBottom: "0px",
  },
}));

const SRList = (props) => {
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const { usersDataList, page, setPage, setRowsPerPage, rowsPerPage, count, fetchSRList } = props
    // const emptyRows =
    //     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersDataList.length) : 0;
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
        fetchSRList(0, event.target.value);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        fetchSRList(newPage, rowsPerPage);
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleCopyLink = (url) => {
        navigator.clipboard.writeText(url)
          .then(() => {
            setOpenSnackbar(true);
          })
          .catch((error) => {
            console.error('Error copying link to clipboard:', error);
          });
      };

    return (
        <Paper elevation={0} sx={{ width: '100%', overflow: 'hidden', backgroundColor: "transparent" }}>
            <TableContainer sx={{ height: 400, }}>
                <Table stickyHeader aria-label="Meeting table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" className="tableHeader">Simulation Type</TableCell> 
                            <TableCell align="left" className="tableHeader">Name</TableCell>
                            <TableCell align="left" className="tableHeader">SetUp Files</TableCell>
                            <TableCell align="left" className="tableHeader">Poster</TableCell>
                            <TableCell align="left" className="tableHeader">Edit </TableCell>
                            <TableCell align="left" className="tableHeader">Delete </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usersDataList?.map((row, i) => (
                            <TableRow key={row?._id} sx={{ cursor: "pointer" }}>
                                {console.log("row",row)}
                                <TableCell align="left">{_.truncate(row?.simulation_type) || `---`}</TableCell>
                                <TableCell align="left">{_.truncate(row?.name) || `---`}</TableCell>
                                <TableCell align="left">{row?.win_setup_file ? <img title="Window Download" onClick={() => handleCopyLink(row.win_setup_file)} src={WinDownload} style={{ width: 30, height: 30, objectFit: 'cover',marginRight:'1rem'}}/>:"-"} {row?.mac_setup_file ? <img title="Mac Download" onClick={() => handleCopyLink(row.mac_setup_file)} src={MacDownload} style={{ width: 30, height: 30, objectFit: 'cover'}}/> : "-"}</TableCell>
                                <TableCell align="left">{row?.poster_url ? <img src={row?.poster_url} style={{ width: 80, height: 50, objectFit: 'cover',border: '1px solid #ccc',  }} />: "-"}
                                </TableCell>
                                <TableCell align="left">
                                    <Tooltip
                                        title="Edit"
                                        onClick={() => props.handelOpenEdit(row)}
                                        placement="left"
                                    >
                                        <EditIcon />
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="left">
                                    <Tooltip
                                        title="Delete"
                                        onClick={() => {props.handleDelete(row)}}
                                        placement="left"
                                    >
                                        <DeleteIcon />
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <CssTablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <CustomSnackbar
                open={openSnackbar}
                message={'File Copied'}
                handleClose={handleCloseSnackbar}
            />
        </Paper>
    );
};
export default SRList;
