import React, { useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

export default function ImageView(props) {
  const [isViewerOpen] = useState(props.isViewerOpen);
  const [fileIndex, setFileIndex] = useState(props.currentImage);

  const viewNextImageName = () => {
    props.images.length === fileIndex+1 ? setFileIndex(0) : setFileIndex(fileIndex+1);
  }
  const viewPreviousImageName = () => {
    fileIndex === 0 ? setFileIndex(props.images.length-1) : setFileIndex(fileIndex-1);
  }

  return (
    isViewerOpen && (
      <div style={{ position: "relative" }}>
        <h2
          style={{
            // display: "flex",
            position: "fixed",
            zIndex: 1001,
            left: "94px",
            top: props.viewFullScreen ? "82px" : "10px",
            color: "#FFF",
            whiteSpace: 'nowrap',
            width: '85%', 
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {props.imageNames[fileIndex]}
        </h2>
        <ImageViewer
          src={props.images}
          currentIndex={props.currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={props.closeImageViewer}
          rightArrowComponent={<KeyboardArrowRightIcon onClick={(e) => viewNextImageName(props.currentImage, e)} sx={{ fontSize: 100 }} />}
          leftArrowComponent={<KeyboardArrowLeftIcon onClick={(e) => viewPreviousImageName(props.currentImage, e)} sx={{ fontSize: 100 }} />}
          backgroundStyle={
            props.viewFullScreen
              ? {
                  left: "66px",
                  top: "80px",
                  width: "95.2%",
                  height: "87%",
                  opacity: "0.97",
                  zIndex: 1000
                }
              : {zIndex: 1000}
          }
        ></ImageViewer>
      </div>
    )
  );
}
