/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Formik, Form, FastField, FieldArray, useFormikContext } from 'formik';
import {
  Container,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import * as Yup from 'yup';
import './Simulation.css';
import CssTextField from 'Components/CssTextField';
import CssSelectField from 'Components/CssSelectField';
import CssButton from 'Components/CssButton';
import Choices from './Choice';
import useApi from 'services/api-hook';
import useRequests from 'services/request-hook';
import _ from 'lodash';
import { GlobalState } from "context/GlobalContext";
import CssCircularProgress from 'Components/CssCircularProgress';

const QuestionsFormSchema = Yup.object().shape({
  title: Yup.string().min(3, "minimum length of a text is 3").max(1000, "maximum length of a text is 1000").required('Title is required'),
  time_to_answer: Yup.number().notRequired().nullable(),
  question_importance: Yup.number().min(1, "Min value 1.").max(3, "Max value 3.").notRequired().nullable(),
  select_type: Yup.string().required('Type is required'),
  question_no: Yup.number().required('Question No is required'),
  choices: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required('Label is required'),
      })
    )
});

const CssAddIconButton = styled(IconButton)({
  position: 'absolute',
  top: '33px',
  right: '30px'
});

const CssAddIcon = styled(AddCircleTwoToneIcon)({
  color: '#FFF',
  fontSize: '2rem'
});

const CssWarningIcon = styled(WarningAmberRoundedIcon)({
  position: 'relative',
  top: '-2px',
  marginRight: '5px',
  color: '#f09500'
});

const QuestionsForm = ({ node, handleResponse }) => {
  const { sid } = useParams();
  const { isPublished } = useContext(GlobalState);
  const formContext = useFormikContext();
  const { questionsApi, getKpiApi, getQuestionsApi, updateQuestionsApi } = useRequests();
  const questions = useApi(questionsApi);
  const getKpi = useApi(getKpiApi);
  const getQuestions = useApi(getQuestionsApi);
  const updateQuestions = useApi(updateQuestionsApi);
  const [kpiData, setKpiData] = useState([]);
  const [openKpiAlert, setOpenKpiAlert] = useState(false);
  const [hasKpis, setHasKpis] = useState(false);
  const [callUpdate, setCallUpdate] = useState(false);
  const [iconUrls, setIconUrls] = useState({});
  const [initialValues, setInitialValues] = useState({
    title: '',
    question_importance: "",
    time_to_answer: '',
    select_type: '',
    question_no: '',
    index:'',
    choices: [
      {
        label: '',
        index: '',
        icon: { url: '' },
        kpis_to_impact: {}
      },
      {
        label: '',
        index: '',
        icon: { url: '' },
        kpis_to_impact: {}
      }
    ]
  });

  const handleKpiAlertClose = () => {
    setOpenKpiAlert(false);
  };

  const onIconFileUpload = ({ file_url }, index) => {
    formContext.setFieldValue(`choices.${index}.icon`, { url: file_url })
  };

  useEffect(() => {
    if (node && node.elementId) {
      getQuestions.request(node.elementId).then((res) => {
        let result = res.data;
        if (result.choices.length <= 1) {
          result.choices.push({
            label: '',
            icon: { url: '' },
            kpis_to_impact: {}
          });
        }
        setInitialValues(res.data);
        setCallUpdate(true);
      });
    }
    getKpi.request(sid).then((res) => {
      if (res.data.length) {
        setKpiData(res.data);
        setHasKpis(true);
      } else {
        setHasKpis(false);
        setOpenKpiAlert(true);
      }
    });
  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={QuestionsFormSchema}
        enableReinitialize={true}
        isInitialValid={false}
        validateOnMount={true}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          values.choices = values.choices.map((c, i) => {
            if (c.kpis_to_impact && !_.isEmpty(c.kpis_to_impact)) {
              Object.keys(c.kpis_to_impact).map((kpi) => {
                    if (c.kpis_to_impact[kpi] !== '') {
                        c.kpis_to_impact[kpi] = parseFloat(c.kpis_to_impact[kpi]);
                    }
               return c.kpis_to_impact[kpi];
              });
            }
            if (!_.isEmpty(iconUrls)) {
              if (iconUrls[i]) {
                c.icon = iconUrls[i].icon;
              }
            }
            return c;
          });
          values['scenario_id'] = sid
          if (callUpdate) {
            updateQuestions.request(node.elementId, values).then(res => {
              handleResponse(res, hasKpis);
            });
          } else {
            questions.request(values).then(res => {
              handleResponse(res, hasKpis);
            });
          }
          setSubmitting(false)
        }}
      >
        {({ values, errors, touched, isValid, setFieldValue, isSubmitting, dirty, handleBlur }) => (
          <Form noValidate={true} autoComplete='off' className='form-wrapper'>
            <Container className='container'>
              <Grid container className='form-wrapper questions-form' direction='row' >
                <Grid item xs={5}>
                  <FastField
                    component={CssTextField}
                    variant='outlined'
                    size='small'
                    name='title'
                    id='title'
                    value={values.title}
                    inputProps={{ readOnly: isPublished }}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      setFieldValue('title', event.target.value);
                    }}
                    InputProps={{
                      className: 'text-field'
                    }}
                    label='Title*' />
                  {errors.title && touched.title ? (
                    <div className='field-error'>{errors.title}</div>) : null}
                </Grid>
                <Grid item xs={5}>
                  <FastField
                    component={CssTextField}
                    variant='outlined'
                    size='small'
                    name='question_importance'
                    id='question_importance'
                    inputProps={{ readOnly: isPublished }}
                    onKeyDown={(evt) => (evt.key === 'e' || evt.key === 'E' || evt.key === '.' || evt.key === '-') && evt.preventDefault()}
                    value={values.question_importance}
                    onBlur={handleBlur}
                    type='text'
                    onChange={(e) => {
                      const re = /^[0-9]+$/
                      if (e.target.value === "" || re.test(e.target.value))
                        setFieldValue('question_importance', e.target.value);
                    }}
                    InputProps={{
                      className: 'text-field'
                    }}
                    label='Question Importance' />
                  {errors.question_importance && touched.question_importance ? (
                    <div className='field-error'>{errors.question_importance}</div>) : null}
                </Grid>
                <Grid item xs={5}>
                  <FastField
                    component={CssTextField}
                    variant='outlined'
                    size='small'
                    name='time_to_answer'
                    id='time_to_answer'
                    type='text'
                    inputProps={{ readOnly: isPublished }}
                    value={values.time_to_answer}
                    onKeyDown={(evt) => (evt.key === 'e' || evt.key === 'E' || evt.key === '.' || evt.key === '-') && evt.preventDefault()}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      const re = /^[0-9]+$/
                      if (e.target.value === "" || re.test(e.target.value))
                        setFieldValue('time_to_answer', e.target.value);
                    }}
                    InputProps={{
                      className: 'text-field'
                    }}
                    label='Time to Answer (Optional)' />
                  {errors.time_to_answer && touched.time_to_answer ? (
                    <div className='field-error'>{errors.time_to_answer}</div>) : null}
                </Grid>
                <Grid item xs={5} >
                  <FastField
                    component={CssTextField}
                    variant='outlined'
                    size='small'
                    name='question_no'
                    id='question_no'
                    onBlur={handleBlur}
                    inputProps={{ readOnly: isPublished }}
                    value={values.question_no}
                    onChange={(event) => {
                      setFieldValue('question_no', event.target.value);
                    }}
                    InputProps={{
                      className: 'text-field'
                    }}
                    label='Question No.*' />
                  {errors.question_no && touched.question_no ? (
                    <div className='field-error'>{errors.question_no}</div>) : null}
                </Grid>
                <Grid item xs={5}>
                  <div className='form_control'>
                    <FormControl fullWidth size='small'>
                      <InputLabel id='select_type' className='select-label'>
                        Select Type*
                      </InputLabel>
                      <FastField
                        component={CssSelectField}
                        labelId='select_type'
                        variant='outlined'
                        size='small'
                        name='select_type'
                        id='select_type'
                        label='Select Type*'
                        value={values.select_type}
                        onBlur={handleBlur}
                        inputProps={{ readOnly: isPublished }}
                        onChange={(e) => {
                          setFieldValue('select_type', e.target.value);
                        }}
                        InputProps={{
                          className: 'select-field'
                        }}
                      >
                        <MenuItem key={0} value={'single_select'}>
                          {`Single Select`}
                        </MenuItem>
                        <MenuItem key={1} value={'multi_select'}>
                          {`Multi Select`}
                        </MenuItem>
                      </FastField>
                    </FormControl>
                    {errors.select_type && touched.select_type ? (
                      <div className='errorText'>{errors.select_type}</div>
                    ) : null}
                  </div>
                </Grid>
              </Grid>
              <Grid container className='form-wrapper' direction='row'>
                <FieldArray name='choices'>
                  {({ insert, remove, push }) => (
                    <>
                      <Grid item xs={10} className='field-array-title-wrapper'>
                        <Typography variant='h5' className='field-array-title'>Choices</Typography>
                        {values.choices.length < 6 &&
                          isPublished ? null : (
                          <CssAddIconButton onClick={() => push({ label: '', kpis_to_impact: {} })}>
                            <CssAddIcon />
                          </CssAddIconButton>)
                        }
                      </Grid>
                      {values.choices.map((item, index) => <Choices
                        key={index}
                        index={index}
                        errors={errors}
                        touched={touched}
                        onRemove={remove}
                        kpi={kpiData}
                        values={values}
                        choice={item}
                        enableClose={values.choices.length > 2}
                        onSetFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        onIconFileUpload={onIconFileUpload}
                        isPublished={isPublished}
                        bucket={"choiceicons"}
                        choiceType="question"
                      />)}
                    </>
                  )}
                </FieldArray>
              </Grid>
            </Container>
            <Grid className='button-stack form-btn-wrapper'>
              <Stack spacing={2} direction='row'>
                <CssButton
                  variant='contained'
                  size='medium'
                  type='submit'
                  disabled={!isValid || isSubmitting || isPublished}
                >
                  {isSubmitting ? <CssCircularProgress size={20} /> : node?.elementId ? "Update" : "Save"}
                </CssButton>
                {
                  node && node.elementId ? null : (
                    <CssButton
                      type='reset'
                      variant='contained'
                      size='medium'
                      sx={{ backgroundColor: "#673068" }}
                      disabled={!dirty}
                    >Reset</CssButton>
                  )
                }
              </Stack>
            </Grid>
          </Form>
        )}
      </Formik>
      <Dialog
        open={openKpiAlert}
        keepMounted
      >
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <CssWarningIcon />Warning: No KPI's defined, would like to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CssButton
            variant='contained'
            size='medium'
            onClick={handleKpiAlertClose}
            autoFocus
            className='button-alert'
          >
            Continue
          </CssButton> 
        </DialogActions>
      </Dialog>
    </>
  );
} 
 
export default QuestionsForm;

