import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const CssTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  "& a": {
    color: theme.palette.primary.contrastText,
  }
}));

export default CssTitle;