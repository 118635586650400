import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const CssTextField = styled(TextField)({
  width: '100%',
  marginBottom: '2px', 
  marginTop: '20px',
  // '& label': {
  //   color: '#FFF', 
  //   '&.Mui-focused': {
  //     color: '#FFF',
  //   }
  // },
  // '& .MuiInput-underline:after': {
  //   borderBottomColor: '#FFF',
  // },
  // '& .MuiInput-underline:before': {
  //   borderBottomColor: '#FFF',
  // },
  // '& .MuiInput-underline:hover:before': {
  //   borderBottomColor: '#FFF'
  // },
  // '& input': {
  //   color: '#FFF',
  //   '&[type=file]:focused': {
  //     borderWidth: 1
  //   }
  // },
  // '& .MuiOutlinedInput-root': {
  //   '& fieldset': {
  //     borderColor: '#FFF',
  //   },
  //   '&:hover fieldset': {
  //     borderColor: '#FFF',
  //   },
  //   '&.Mui-focused fieldset': {
  //     borderColor: '#FFF',
  //   },
  // }
});

export default CssTextField;