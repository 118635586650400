import React, { forwardRef } from "react";
import { Dialog, DialogTitle, DialogContent, IconButton, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./ModalContainer.css";
import { styled } from '@mui/material/styles';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='left' ref={ref} {...props} />;
});
 
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {  
    backgroundColor: theme.palette.primary.main,
    backgroundImage: 'none'  
  },
  '& .modal-title': {
    color: theme.palette.primary.contrastText,
    position: 'relative',
    fontSize: '2rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  '& .icon-close': {
    color: theme.palette.primary.contrastText,
  },
  '& label': {
    color: theme.palette.primary.contrastText,
    '&.Mui-focused': {
      color: theme.palette.primary.contrastText,
    }
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.primary.contrastText,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.contrastText,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.contrastText,
    },
  },
  '& input': {
    color: theme.palette.primary.contrastText,
    '&[type=file]:focused': {
      borderWidth: 1
    } 
  }, 
  '& .title': {
    color: theme.palette.primary.contrastText,
  },
  '& .field-array-title': {
    color: theme.palette.primary.contrastText,
  },
  '& .select-label': {
    color: theme.palette.primary.contrastText,
  },
  "& .MuiSelect-select ": {
    color: theme.palette.primary.contrastText,
  },
  "& .MuiSelect-icon": {
    color: theme.palette.primary.contrastText,
  },
  '& .form_control': {
    color: theme.palette.primary.contrastText,
  },
  '& addIcon ': {
    color: theme.palette.primary.contrastText,
    fontSize: "2rem",
  },
  '& uploadImg': {
    color: theme.palette.primary.contrastText,
  },
  '& .form-btn-wrapper': {
    backgroundColor: theme.palette.primary.main,
  },
  '& .text-field':{
    color: theme.palette.primary.contrastText,
  },
  '& .CssUploadIcon': {
    color: theme.palette.primary.contrastText,
  },
  '& .dropZone': {
    border: '2px dashed', 
    borderColor: theme.palette.primary.contrastText,
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.contrastText,
  },
  '& .menu-items-container': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  '& .MuiSelect-select': {
    color: theme.palette.primary.contrastText,           
  },
  '& .table-body td .type-select fieldset .text-field fieldset': {
    borderColor: theme.palette.primary.contrastText,
  },
  '& .table-head th': {
    color: theme.palette.primary.contrastText,           
  },
}));


const ModalContainer = (props) => {
  const { children, title, open, handleClose, className } = props;
 
  return (
    <div>
      <StyledDialog
        fullScreen
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className={`ModalContainer ${className}`}
      >
        <DialogTitle className='modal-title'>
          <div className="form-title">{title}</div>
          <IconButton
            edge='start'
            onClick={handleClose}
            aria-label='close'
            className='icon-close'
            size='large'
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </StyledDialog>
    </div>
  );
};

export default ModalContainer;
