import React, { useEffect } from "react";
import { Field } from "formik";
import PropTypes from "prop-types";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const RenderSelect = (props) => {
  const {
    id,
    label,
    field,
    form,
    disabled,
    customOnChange,
    options,
    selectSingleOptionByDefault
  } = props;

  useEffect(() => {
    if (selectSingleOptionByDefault && options.length === 1) {
      if (typeof options[0] === "object") {
        form.setFieldValue(field.name, options[0].value);
      } else {
        form.setFieldValue(field.name, options[0]);
      }
    }
  });

  return (
    <FormControl fullWidth size="small">
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        {...field}
        name={field.name}
        id={id}
        labelId={id}
        label={label}
        disabled={disabled}
        color="primary"
        InputProps={{
          onChange: (event) => {
            if (customOnChange) {
              customOnChange(
                event.target.value,
                event.target.name,
                form.setFieldValue
              );
            }
          },
        }}
      >
        {options.length > 0 ? (
          options &&
          options?.map((option) => {
            return typeof option === "object" && option !== null ? (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ) : (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem value="" disabled>
            No Details
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

const SelectField = (props) => {
  const {
    name,
    id,
    label,
    disabled,
    customOnChange,
    options,
    selectSingleOptionByDefault,
    required,
    ...other
  } = props;
  return (
    <Field
      component={RenderSelect}
      name={name}
      id={id}
      label={label}
      disabled={disabled}
      options={options}
      selectSingleOptionByDefault={selectSingleOptionByDefault}
      customOnChange={customOnChange}
      required={required}
      {...other}
    />
  );
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  customOnChange: PropTypes.func,
  selectSingleOptionByDefault: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};

SelectField.defaultProps = {
  name: "select",
  id: "select",
  label: "Select Field",
  disabled: false,
  options: [],
  selectSingleOptionByDefault: false,
  required: false,
  error: false,
};

export default SelectField;
