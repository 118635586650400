import React, { useState, useCallback } from "react";
import {
  Grid,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
  Typography,
  CardMedia,
  TextField,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { FastField } from "formik";
import CssTextField from "Components/CssTextField";
import CssSelectField from "Components/CssSelectField";
import { Avatar, CircularProgress } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import useRequests from "services/request-hook";
import useApi from "services/api-hook";
import ImageView from "../../../Components/ImageView";
import CustomizedDialogs from "../../../Components/DialogBox";
import AddLinkIcon from '@mui/icons-material/AddLink';

const CssCloseIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "-10px",
  right: "-10px",
  border: "1px solid #F00",
  padding: 0,
  backgroundColor: theme.palette.primary.main,
}));

const CssCloseIcon = styled(CloseIcon)({
  color: "#F00",
  fontSize: "1.2rem",
});

const CssAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: "transparent",
  border: "1px dashed",
  borderColor: theme.palette.primary.contrastText,
  top: "18px",
  left: "12px",
  "&.avatar": {
    borderWidth: 0,
  },
  "& svg": {
    display: "none",
  },
}));

const CssCard = styled(CardMedia)({
  backgroundColor: "transparent",
  border: "1px dashed #FFF",
  borderRadius: "50%",
  width: "100%",
  height: "100%",
  "&.avatar": {
    borderWidth: 0,
  },
  "& svg": {
    display: "none",
  },
});

const CssCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  position: "relative",
  top: "20px",
  left: "20px",
}));

const CssUploadButton = styled(IconButton)({
  position: "relative",
});

const CssUploadIcon = styled(CloudUploadIcon)({
  color: "#FFF",
});

const CssDeleteIcon = styled(DeleteIcon)({
  color: "#FFF",
});

const CssLinkIcon = styled(AddLinkIcon)({
  color: "#FFF",
})

const MenuItems = (props) => {
  const {
    menuIndex,
    index,
    enableClose,
    onRemove,
    onSetFieldValue,
    errors,
    touched,
    menuItems,
    isPublished,
    bucket,
  } = props;
  const [showLoader, setShowLoader] = useState(false);
  const [currentImage, setCurrentImage] = useState(menuIndex);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [url, setUrl] = React.useState("");
  const [images, setImages] = useState([]);
  const [label, setLabel] = React.useState("");
  const { getSignedUrlApi, createAssetsApi } = useRequests();
  const [openURL, setOpenURL] = useState(false);
  const getSignedUrl = useApi(getSignedUrlApi);
  const createAssets = useApi(createAssetsApi);
  const [invalidUrlError, setInvalidUrlError] = useState(false);

  let contentAcceptance = "";
  if (menuItems.content_type === "image" || menuItems.content_type === "2D") {
    contentAcceptance = "image/*";
  } else {
    contentAcceptance = "video/*";
  }

  const addAssetsToDB = (payload) => {
    createAssets
      .request(bucket, payload)
      .then((res) => {
        if (res.status === 200) {
          console.log("res", res);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const fetchAndUploadFile = (file) => {
    // if (!file || !file.name.match(/^[a-zA-Z0-9_\-]+\.[a-zA-Z0-9]+$/)) {
    //   // Invalid file name
    //   setShowLoader(false);
    //   setInvalidUrlError(true);
    //   return null;
    // } 
    getSignedUrl
      .request(bucket || "other")
      .then((res) => {
        if (res.status === 200) {
          let client = res?.data?.client;
          let blobSAS = res?.data?.blobSAS;
          let name = file?.name.split(".");
          let fileExtension = name?.pop();
          let fileName = `${name[0]}${Date.now()}.${fileExtension}`;
          const finalUrl = `${client}/${fileName}?${blobSAS}`;
          fetch(`${finalUrl}`, {
            method: "PUT",
            body: file,
            headers: {
              "x-ms-blob-type": "BlockBlob",
              "Content-Type": "multipart/form-data",
            },
          })
            .then((response) => {
              if (response.status === 201 || response.status === 200) {
                let fileUrl = response?.url?.substring(
                  0,
                  response?.url?.indexOf("?")
                );
                var path = fileUrl.split("/");
                fileUrl = process.env.REACT_APP_CDN_URL
                  ? `${process.env.REACT_APP_CDN_URL}/${path[3]}/${path[4]}`
                  : fileUrl;
                onSetFieldValue(
                  `menus.${menuIndex}.menu_items.${index}.content`,
                  fileUrl
                );
                let payload = {
                  fileurl: fileUrl,
                  filename: fileName,
                  type: bucket,
                };
                addAssetsToDB(payload);
                setShowLoader(false);
                setOpenURL(false);
                setUrl("");
              }
            })
            .catch((error) => {
              console.log("error", error);
              setShowLoader(false);
              // setInvalidUrlError(true);
            });
        }
      })
      .catch((error) => {
        console.log("error", error);
        setShowLoader(false);
        // setInvalidUrlError(true);
      });
  };

  
  const handleUploadFile = (file) => {
    setShowLoader(true);
    // setInvalidUrlError(false);
    if (file instanceof File) {
      fetchAndUploadFile(file);
    } else {
      fetch(file)
        .then((response) => response.blob())
        .then((blob) => {
          const urlFile = new File([blob], `urlFile.${blob.type.split('/')[1]}`, {
            type: blob.type,
            lastModified: Date.now(),
          });
          // if (!urlFile.name.match(/^[a-zA-Z0-9_\-]+\.[a-zA-Z0-9]+$/)) {
          //   setShowLoader(false);
          //   // setInvalidUrlError(true);
          //   return null;
          // } else {
            fetchAndUploadFile(urlFile);
          // }
        })
        .catch((error) => {
          console.error("Error fetching file from URL:", error);
          setShowLoader(false);
          // setInvalidUrlError(true);
        });
    }
  };

  const openImageViewer = useCallback((index, imageUrl, e) => {
    setImages([imageUrl]);
    setCurrentImage(index);
    setIsViewerOpen(true);
    e.stopPropagation();
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handleClickOpen = (url, label) => (event) => {
    setOpenDialog(true);
    setUrl(url);
    setLabel(label);
    event.stopPropagation();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenURL = () => {
    setOpenURL((open) => !open)
  }

  
  return (
    <Grid item container className="menu-items-container filled-inputs">
      <Grid item xs={3} sx={{ marginTop: "20px" }}>
        <FastField
          component={CssTextField}
          variant="filled"
          size="small"
          name={`menus.${menuIndex}.menu_items.${index}.label`}
          value={menuItems.label}
          InputProps={{
            className: "text-field",
          }}
          onChange={(e) => {
            onSetFieldValue(
              `menus.${menuIndex}.menu_items.${index}.label`,
              e.target.value
            );
          }}
          inputProps={{ readOnly: isPublished }}
          label="Label"
        />
        {errors &&
          errors.menus &&
          errors.menus[menuIndex] &&
          errors.menus[menuIndex].menu_items &&
          errors.menus[menuIndex].menu_items[index] &&
          errors.menus[menuIndex].menu_items[index].label &&
          touched &&
          touched.menus &&
          touched.menus[menuIndex] &&
          touched.menus[menuIndex].menu_items &&
          touched.menus[menuIndex].menu_items[index] &&
          touched.menus[menuIndex].menu_items[index].label && (
            <div className="field-error">
              {errors.menus[menuIndex].menu_items[index].label}
            </div>
          )}
      </Grid>
      <Grid item xs={3} sx={{ marginTop: "20px" }}>
        <FormControl fullWidth size="small">
          <InputLabel id="content_type">Content Type</InputLabel>
          <FastField
            component={CssSelectField}
            labelId="content_type"
            variant="filled"
            size="small"
            name={`menus.${menuIndex}.menu_items.${index}.content_type`}
            label="Content Type"
            value={menuItems.content_type}
            defaultValue=""
            onChange={(e) => {
              onSetFieldValue(
                `menus.${menuIndex}.menu_items.${index}.content_type`,
                e.target.value
              );
            }}
            InputProps={{
              className: "text-field",
            }}
            inputProps={{ readOnly: isPublished }}
          >
            <MenuItem key={0} value={"2D"}>
              {`Image`}
            </MenuItem>
            <MenuItem key={1} value={"video"}>
              {`Video`}
            </MenuItem>
            <MenuItem key={2} value={"link"}>
              {`Link`}
            </MenuItem>
          </FastField>
        </FormControl>
        {errors &&
          errors.menus &&
          errors.menus[menuIndex] &&
          errors.menus[menuIndex].menu_items &&
          errors.menus[menuIndex].menu_items[index] &&
          errors.menus[menuIndex].menu_items[index].content_type &&
          touched &&
          touched.menus &&
          touched.menus[menuIndex] &&
          touched.menus[menuIndex].menu_items &&
          touched.menus[menuIndex].menu_items[index] &&
          touched.menus[menuIndex].menu_items[index].content_type && (
            <div className="field-error">
              {errors.menus[menuIndex].menu_items[index].content_type}
            </div>
          )}
      </Grid>
      <Grid item xs={6} style={{display:'flex'}}>
        <Stack spacing={4} direction="row" justifyContent="center">
          <CssAvatar
            alt={"Icon"}
            className={menuItems?.content ? "avatar" : ""}
          >
            {(menuItems.content_type === "" ||
              menuItems.content_type === "2D" ||
              menuItems.content_type === "image") &&
              menuItems.content !== "" && (
                <CssCard
                  className={menuItems?.content ? "avatar" : ""}
                  component="img"
                  image={menuItems?.content}
                  alt={"Icon"}
                  onClick={(e) => openImageViewer(0, menuItems?.content, e)}
                  style={{ cursor: "pointer" }}
                />
              )}
            {menuItems.content_type === "video" && menuItems.content !== "" && (
              <CssCard
                className={menuItems?.content ? "avatar" : ""}
                component="video"
                image={menuItems?.content}
                alt={"Icon"}
                onClick={handleClickOpen(menuItems?.content, menuItems?.label)}
                style={{ cursor: "pointer" }}
              />
            )}
          </CssAvatar>
          <label htmlFor={`menus.${menuIndex}.menu_items.${index}.content`}>
            <input
              id={`menus.${menuIndex}.menu_items.${index}.content`}
              name={`menus.${menuIndex}.menu_items.${index}.content`}
              disabled={isPublished}
              type="file"
              onChange={(event) =>
                handleUploadFile(event.currentTarget.files[0])
              }
              className="form-control"
              style={{ display: "none" }}
              accept={contentAcceptance}
              inputProps={{ readOnly: isPublished }}
              InputProps={{
                className: "text-field",
              }}
            />
            {showLoader ? (
              <CssCircularProgress size={30} />
            ) : (
              <>
                <Typography style={{ fontSize: "17px", padding: "0px" }}>
                  Content
                </Typography>
                <div style={{display:'flex'}}>
                {menuItems?.content_type === "link" ? null : <CssUploadButton
                  aria-label="upload"
                  variant="contained"
                  component="span"
                >
                  <CssUploadIcon />
                </CssUploadButton>}
                <CssUploadButton
                  variant="contained"
                >
                  <CssLinkIcon onClick={handleOpenURL}/>
                </CssUploadButton>
                <CssUploadButton
                  aria-label="upload"
                  variant="contained"
                  component="span"
                  sx={{ cursor: "pointer" }}
                  onClick={(e) => {
                    onSetFieldValue(
                      `menus.${menuIndex}.menu_items.${index}.content`,
                      ""
                    );
                    e.preventDefault();
                  }}
                >
                  <CssDeleteIcon />
                </CssUploadButton>
                </div>
                {openURL && <div style={{ display: "flex", justifyContent: "flex-end",alignItems:"center", marginTop: "8px" }}>
                  {/* <div style={{ fontSize: "14px", color: "white", marginRight: "10px"}}>{`URL`} </div> */}
                  <div>
                  <TextField
                    aria-label="upload"
                    placeholder="Enter URL"
                    onChange={(event) => {
                      setUrl(event.target.value) 
                      setInvalidUrlError(false)}}
                    variant="outlined"
                    margin="dense"
                    InputProps={{ style: { height: "30px",width:'16.3rem',marginTop:'1rem' } }}
                  />
                  {/* {invalidUrlError && <div style={{ color: 'red', fontSize: '14px', marginLeft: '4rem', marginTop: '4px' }}>{`Invalid File Format`}</div>} */}
                  </div>
                    <Button onClick={(e) => {
                      handleUploadFile(url)
                      e.preventDefault();
                    }} style={{ color: 'white', marginTop: '10px' }} >
                      Add
                    </Button>
                </div>}
              </>
            )}
          </label>
        </Stack>
      </Grid>

      {enableClose && !isPublished && (
        <CssCloseIconButton onClick={() => onRemove(index)}>
          <CssCloseIcon />
        </CssCloseIconButton>
      )}
      {openDialog && url && (
        <CustomizedDialogs
          handleClose={handleCloseDialog}
          open={openDialog}
          url={url}
          title={label}
          type="video"
        />
      )}
      {isViewerOpen && (
        <ImageView
          currentImage={currentImage}
          openImageViewer={openImageViewer}
          closeImageViewer={closeImageViewer}
          isViewerOpen={isViewerOpen}
          imageNames=""
          viewFullScreen={false}
          images={images}
        />
      )}
    </Grid>
  );
};

export default MenuItems;
