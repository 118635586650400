import React, { useState, useRef, useContext } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Typography,
  CardActions,
  Box,
} from "@mui/material";
import { GlobalState } from "context/GlobalContext";
import ImageTwoToneIcon from "@mui/icons-material/ImageTwoTone";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import "./MediaCard.css";
import _ from "lodash";
import MediaViewSlider from "Components/MediaViewSlider";

const CssDefaultImageIcon = styled(ImageTwoToneIcon)({
  fontSize: "8.7rem",
  transform: "translate(-50%)",
  position: "relative",
  left: "50%",
});

const styles = {
  paperContainer: {
    height: 200,
    width: "100%",
    // backgroundImage: `url(${"https://img.freepik.com/free-vector/abstract-colorful-music-poster-template-with-photo_52683-17762.jpg?w=740&t=st=1672728014~exp=1672728614~hmac=876dab2802dccb93c6617dd168f90eae4f0cdf603ab804f23d74f8638ed6c080"})`
  },
};

const MediaCard = (props) => {
  const { playId, setPlayId } = useContext(GlobalState);
  const vidRef = useRef(null);
  const [url, setUrl] = React.useState("");
  const [actionShow] = React.useState(props.actionShow);
  const [currentImage, setCurrentImage] = useState(props.index);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [playIdIndex, setPlayIdIndex] = useState("");


  // useEffect(() => {
  //   if (playIdIndex) {
  //     setPlayId(playIdIndex);
  //   }
  // }, [playIdIndex]);

  const handleClickOpen = (event, index, url) => {
    setIsViewerOpen(true);
    setCurrentImage(index);
    setUrl(url);
    event.stopPropagation();
  };

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const {
    image,
    alt,
    children,
    title,
    subTitle,
    handleClick,
    images,
    imageNames,
    index,
    viewFullScreen
  } = props;

  // const handlePlayVideo = (indexValue) => {
  //   setPlayId(indexValue);
  // };

  return (
    <>
      <Card
        className="MediaCard"
        sx={{ maxWidth: 345, height: '100%' }}
        onClick={() => handleClick?.(image)}
      >
        <CardActionArea sx={{ height: '100%' }}>
          {image &&
          !image.includes(".mp4") &&
          !image.includes(".mp3") &&
          !image.includes(".ogg") ? (
            <Box>
              <CardMedia
                className="MediaImg"
                component="img"
                height="200"
                sx={{ p: '10px' }}
                image={image}
                alt={
                  alt
                    ? _.truncate(alt, {
                        length: 40,
                        separator: " ",
                      })
                    : ""
                }
              />
              <CardActions></CardActions>
            </Box>
          ) : (
            // <CssDefaultImageIcon />
            <div>
              <video
                ref={vidRef}
                src={image}
                alt={image}
                className="image"
                style={styles.paperContainer}
                controls
                controlsList="nodownload noplaybackrate"
                disablePictureInPicture
                onClick={e => e.preventDefault()}
                // onClick={() => handlePlayVideo(props.index)}
                id={props.index}
              />
            </div>
          )}
          <CardContent className="MediaCardContent">
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              className="MediaCard-title"
            >
              {title}
            </Typography>
            {actionShow && (
              <Box>
                {image &&
                !image.includes(".mp4") &&
                !image.includes(".mp3") &&
                !image.includes(".ogg") ? (
                  <Tooltip title="Preview Image" placement="top">
                    <VisibilityOutlinedIcon
                      onClick={event => handleClickOpen(event, props.index, image)}
                      style={{ float: "right" }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Play" placement="top">
                    <PlayCircleOutlineIcon
                      onClick={event => handleClickOpen(event, props.index, image)}
                      style={{ float: "right" }}
                    />
                  </Tooltip>
                )}
              </Box>
            )}
            <Typography variant="body2">
              {subTitle ? subTitle : null}
            </Typography>
            {children}
          </CardContent>
        </CardActionArea>
      </Card>
      {isViewerOpen && (
        <MediaViewSlider
          url={url}
          currentImage={currentImage}
          closeImageViewer={closeImageViewer}
          isViewerOpen={isViewerOpen}
          imageNames={imageNames}
          viewFullScreen={viewFullScreen}
          images={images}
        />
      )}
    </>
  );
};

export default MediaCard;
