/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useContext,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Stack } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { addEdge, useNodesState, useEdgesState } from "react-flow-renderer";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import "./Simulation.css";
import CssButton from "Components/CssButton";
import { ObjectId } from "utils";
import { setNodesData, setNodeData } from "state/actions/nodes";
import CustomReactFlow from "Components/CustomReactFlow";
import CssBox from "Components/CssBox";
import ModalContainer from "Components/ModalContainer";
import CustomSnackbar from "Components/CustomSnackbar";
import ConfirmDialog from "Components/ConfirmDialog";
import TrackForm from "./TrackForm";
import IntroTrailerForm from "./IntroTrailerForm";
import SceneForm from "./SceneForm";
import QuestionsForm from "./QuestionsForm";
import DecisionPointForm from "./DecisionPointForm";
import FeedbackSlideForm from "./FeedbackSlideForm";
import ChoiceForm from "./ChoiceForm";
import useApi from "services/api-hook";
import useRequests from "services/request-hook";
import { GlobalState } from "context/GlobalContext";
import { useSnackbar } from "notistack";

const WorkFlow = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { category, sid } = useParams();
  const flowWrapper = useRef(null);
  const dispatch = useDispatch();
  const {
    nodesDetails,
    setNodesDetails,
    setIsPublished,
    isPublished,
    scenarioDetails,
    isUpdateFlowStructure,
    setIsUpdateFlowStructure,
  } = useContext(GlobalState);
  const roles = useSelector(state => state?.userReducer?.roles);
  const hasSRModificationAccess = !roles?.includes("Super Admin");
  const isUnpublish = scenarioDetails?.status === "UNPUBLISH";
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [flowInstance, setFlowInstance] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [callUpdateApi, setCallUpdateApi] = useState({
    isUpdate: false,
    fid: null,
  });
  const [openNodeDeleteAlert, setOpenNodeDeleteAlert] = useState(false);
  const [deletedElements, setDeletedElements] = useState([]);
  const [deletedNodes, setDeletedNodes] = useState([]);
  const [isDrafted, setIsDrafted] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openPublishModel, setOpenPublishModel] = useState(false);
  const [addNodes, setAddNodes] = useState(false);
  const [isCheckPublish, setIsCheckPublish] = useState(false);

  const { getFlowStructureApi, flowStructureApi, updateFlowStructureApi, unpublishSRApi } =
    useRequests();
  const getFlowStructure = useApi(getFlowStructureApi);
  const flowStructure = useApi(flowStructureApi);
  const updateFlowStructure = useApi(updateFlowStructureApi);
  const unpublishSR = useApi(unpublishSRApi);

  const onConnect = useCallback(
    async (params) => {
      if (await checkEdgeConnected(params)) return;

      setEdges((eds) => addEdge(params, eds));
      setAddNodes(true);
    },
    [edges]
  );

  const checkEdgeConnected = async (params) => {
    let source_node = _.find(nodes, (node) => {
      return node.id === params.source;
    });
    let target_node = _.find(nodes, (source) => {
      return source.id === params.target;
    });

    if (params.target === params.source) {
      return true;
    } else if (
      source_node?.type === "decisionPoint" &&
      target_node?.type !== "choices"
    ) {
      return true;
    } else if (
      source_node?.type === "decisionPoint" &&
      target_node?.type === "choices"
    ) {
      return false;
    } else if (target_node?.type === "choices") {
      return true;
    } else if (
      target_node?.type === "decisionPoint" &&
      _.find(edges, (o) => o.source === params.source)
    ) {
      return true;
    } else if (
      source_node?.type === "choices" &&
      _.find(edges, (o) => o.source === params.source)
    ) {
      return true;
    } else if (
      source_node?.type !== "decisionPoint" &&
      _.find(edges, (o) => o.source === params.source)
    ) {
      return true;
    }
    return false;
  };

  const handleRemoveConnection = (event, edge) => {
    if (!isPublished) {
      const choice = _.find(nodes, (o) => o.id === edge.target);
      const target = edges.filter((e) => e.target === edge.target);
      const source = edges.filter((e) => e.source === edge.source);
      if (choice.type === "choices") {
        setAddNodes(false);
      } else if (target.length > 1) {
        let edgesCopy = _.filter(edges, (e) => e.source !== edge.source);
        setEdges((eds) => addEdge(eds, edgesCopy));
      } else if (source.length > 1) {
        let edgesCopy = _.filter(edges, (e) => e.target !== edge.target);
        setEdges((eds) => addEdge(eds, edgesCopy));
      } else {
        let edgesCopy = _.filter(edges, (e) => e.target !== edge.target);
        setEdges((eds) => addEdge(eds, edgesCopy));
      }
      setAddNodes(true);
    } else {
      setAddNodes(false);
    }
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
    setAddNodes(true);
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const reactFlowBounds = flowWrapper?.current?.getBoundingClientRect();
      const type = event.dataTransfer.getData("type/reactflow");
      const label = event.dataTransfer.getData("label/reactflow");

      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      const position = flowInstance?.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      const newNode = {
        id: ObjectId(),
        type,
        position,
        data: { label: `${label}` },
        className: "in-active",
      };
      setNodes((nds) => nds.concat(newNode));
    },
    [flowInstance]
  );

  const isDeleteButtonClicked = (e) => {
    let isDelete = false;
    const pattern = /icon-close|close-button/gi;

    if (_.isObject(e.target.className)) {
      if (e.target.className.baseVal) {
        isDelete = e.target.className.baseVal.match(pattern) || false;
      } else {
        isDelete =
          e.target.parentNode.className.baseVal.match(pattern) || false;
      }
    } else if (_.isString(e.target.className)) {
      isDelete = e.target.className.match(pattern) || false;
    }

    return isDelete;
  };

  const isNodeNameClicked = (e) => {
    let isNodeName = false;
    const pattern = /node-name|node-textarea/gi;

    if (_.isString(e.target.className)) {
      isNodeName = e.target.className.match(pattern) || false;
    }

    return isNodeName;
  };

  const openNodeForm = (event, node) => {
    setSelectedNode(node);
    if (isDeleteButtonClicked(event)) {
      setOpenNodeDeleteAlert(true);
    } else if (!isPublished && isNodeNameClicked(event)) {
      setNodesDetails({
        ...nodesDetails,
        [`${node.id}`]: { enableInput: true, label: "" },
      });
      setAddNodes(true);
    } else if (isPublished && edges) {
      dispatch(setNodeData(node));
      setOpenModal(true);
      setAddNodes(false);
    } else {
      dispatch(setNodeData(node));
      setOpenModal(true);
      setAddNodes(true);
    }
  };

  const handleResponse = (res, hasKpis) => {
    if (res) {
      // setNodes((nds) =>
      nodes.map((n) => {
        if (n.id === selectedNode.id) {
          n.elementId = res.data.id;
          n.className = "active";

          if (n.type === "questions") {
            n.hasKpis = hasKpis;
          }
          if (n.type === "decisionPoint") {
            res?.data?.choices?.map((choice, i) => {
              let existNode = _.find(nodes, (o) => o.elementId === choice.id);
              if (existNode?.elementId === choice.id) {
                return true;
              } else {
                const len = res?.data?.choices.length;
                const pos = parseInt(len / 2);
                let yPos = 0;
                if (len % 2 === 0) {
                  if (pos > i) {
                    // negative
                    yPos = -((pos - i) * 77);
                  } else {
                    yPos = (i + 1 - pos) * 77;
                  }
                } else {
                  if (pos > i) {
                    // negative
                    yPos = -((pos - i) * 130);
                  } else if (pos < i) {
                    // positive
                    yPos = (i - pos) * 130;
                  }
                }
                const type = "choices";
                const label = choice.label;
                const position = flowInstance?.project({
                  x: n.position.x + 236,
                  y: yPos,
                });
                const newNode = {
                  id: ObjectId(),
                  type,
                  position,
                  data: { label: `${label}` },
                  className: "active",
                  elementId: choice.id,
                };
                const newEdge = {
                  source: n.id,
                  sourceHandle: null,
                  target: newNode.id,
                  targetHandle: null,
                  id: "reactflow__edge-" + n.id + "-" + newNode.id,
                };
                setNodes((nds) => nds.concat(newNode));
                setEdges((eds) => eds.concat(newEdge));
              }
            });
          }

          setOpenModal(false);
          setSnackbarSeverity("success");
          setOpenSnackbar(true);
          setSnackbarMessage("Data Saved Successfully");
        }
        return n;
      });
      // );
    } else {
      setOpenModal(true);
    }
  };

  const getUpdateEdges = () => {
    let eds = _.filter(edges, (e) => {
      if (e.source !== selectedNode.id) {
        return e;
      }
    });

    eds = _.filter(eds, (e) => {
      if (e.target !== selectedNode.id) {
        return e;
      }
    });

    setEdges((edges) => eds);
  };

  const getUpdateEdgesOnDelete = (nodeId) => {
    let eds = _.filter(edges, (e) => {
      if (e.source !== nodeId) {
        return e;
      }
    });

    eds = _.filter(eds, (e) => {
      if (e.target !== nodeId) {
        return e;
      }
    });

    setEdges((edges) => eds);
  };

  const getUpdateNodes = () => {
    setNodes((nds) =>
      _.filter(nds, (o) => {
        if (o.id === selectedNode.id) {
          if (o.elementId) {
            setDeletedElements((dns) =>
              dns.concat({ type: o.type, elementId: o.elementId })
            );
          }
        } else {
          return o;
        }
      })
    );
  };

  const getUpdateNodesOnDelete = (nodeId) => {
    setNodes((nds) =>
      _.filter(nds, (o) => {
        if (o.id === nodeId) {
          if (o.elementId) {
            setDeletedElements((dns) =>
              dns.concat({ type: o.type, elementId: o.elementId })
            );
          }
        } else {
          return o;
        }
      })
    );
  };

  const removeDuplicates = (array, keys) => {
    const keyFn = item => keys.map(key => item[key]).join('|');
    return [...new Map(array.map(item => [keyFn(item), item])).values()];
  }

  const getStructurePayload = async (isPublish) => {
    const payload = {
      content: {
        nodes: [],
        edges,
      },
      status: isUnpublish ? "UNPUBLISH" : "DRAFT",
      publish: isPublish || false,
      deleted_elements: [],
    };

    const n = _.map(nodes, (node) => {
      if (node.type === "track") {
        node = { ...node, parent: "" };
      } else {
        let item = _.find(edges, (o) => o.target === node.id);

        if (_.findIndex(deletedNodes, (n) => n.id === node.parent) !== -1) {
          delete node.parent;
        }

        if (item) {
          node.parent = item.source;
        }
      }

      return node;
    });

    payload.content.nodes = n;

    if (deletedElements.length) {
      payload.deleted_elements = removeDuplicates(deletedElements, ['type','elementId']);;
    }

    return payload;
  };

  const saveFlowStructure = async (isPublish, message) => {
    const payload = await getStructurePayload(isPublish);
    if (!isPublish && _.isEmpty(nodes)) {
      enqueueSnackbar("Flow structure can't be saved as empty", {
        variant: "error",
      });
      return;
    }

    if (callUpdateApi.isUpdate) {
      updateFlowStructure
        .request(sid, callUpdateApi.fid, payload)
        .then((res) => {
          handleFlowStructureResponse(res, isPublish, message);
          setIsCheckPublish(false);
        });
    } else {
      flowStructure.request(sid, payload).then((res) => {
        handleFlowStructureResponse(res, isPublish, message);
      });
    }
    setAddNodes(false);
  };

  const handleUnpublishSR = async () => {
    unpublishSR
      .request(sid)
      .then(res => {
        setIsPublished(false);
        enqueueSnackbar("SR Unpublished Successfully.", { variant: "success" });
        navigate(`/${category}`);
      })
      .catch(error => {
        console.error("Error: ", error);
      })
  }

  const handleFlowStructureResponse = (response, isPublish, message) => {
    if (!response) return;
    if (isPublish) {
      enqueueSnackbar(message, { variant: "success" });
      setIsPublished(true);
      setTimeout(() => {
        navigate(`/${category}`);
      }, 3000);
    } else {
      enqueueSnackbar("Flow structure draft saved successfully", {
        variant: "success",
      });
      setIsDrafted(true);
    }
  };

  const validateFlowStructure = () => {
    let isValid = false;
    let message = "";
    if (_.isEmpty(nodes)) {
      message = "Flow structure can't be published as empty";
      return {
        isValid,
        message,
      };
    } else if (!_.isEmpty(nodes) && _.isEmpty(edges)) {
      message = "Flow structure can't be published with empty edges";
      return {
        isValid,
        message,
      };
    } else if (_.filter(nodes, (o) => !o.elementId).length) {
      message = "Please fill the data for each node";
      return {
        isValid,
        message,
      };
    } else if (
      _.filter(nodes, (o) => {
        if (!_.find(edges, (e) => e.target === o.id) && o.type !== "track") {
          return o;
        }
      }).length
    ) {
      message = "Track or edges need to connect properly.";
      return {
        isValid,
        message,
      };
    }
    if (
      !_.isEmpty(nodes) &&
      _.find(nodes, (o) => o.type === "questions" && !o.hasKpis)
    ) {
      message = "KPI's need to add for questions before publish.";
      return {
        isValid,
        message,
      };
    } else {
      isValid = true;
      message = "Flow structure has been successfully published";
    }

    return {
      isValid,
      message,
    };
  };

  const onPublish = async () => {
    const { isValid, message } = await validateFlowStructure();
    if (!isValid) {
      enqueueSnackbar(message, { variant: "error" });
    } else {
      setOpenPublishModel(true);
    }
  };

  const handlePublishYes = async (clickYes) => {
    if (clickYes) {
      const { isValid, message } = await validateFlowStructure();
      if (isValid) {
        saveFlowStructure(true, message);
        setIsCheckPublish(true);
        setOpenPublishModel(false);
      }
    } else {
      setOpenPublishModel(false);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const onCancel = (data) => {
    if (
      addNodes === false &&
      openSnackbar === false &&
      isCheckPublish === false
    ) {
      navigate(`/${category}`);
    } else {
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleNavigate = () => {
    setOpenDialog(false);
    navigate(`/${category}`);
  };

  const onNodeDelete = (isDelete) => {
    if (isDelete) {
      setDeletedNodes((nds) => nds.concat({ id: selectedNode.id }));
      getUpdateNodes();
      getUpdateEdges();
      setIsDrafted(false);
      setAddNodes(true);
    }
    setOpenNodeDeleteAlert(false);
  };

  const onMultipleDeleteNode = (choiceId) => {
    const currentChoice = _.find(nodes, (o) => o.elementId === choiceId);
    setDeletedNodes((nds) => nds.concat({ id: currentChoice?.id }));
    getUpdateNodesOnDelete(currentChoice?.id);
    getUpdateEdgesOnDelete(currentChoice?.id);
    setIsDrafted(false);
    setAddNodes(true);
    const currentChoiceId = currentChoice?.id;
    const currentSource = _.find(edges, (ed) => ed.source === currentChoiceId);
    const currentSourceNext = _.find(
      edges,
      (ed) => ed.source === currentSource?.target
    );
    const currentSourceNextSource = _.filter(
      edges,
      (ed) => ed.target === currentSourceNext?.source
    );
    if (currentSourceNextSource.length === 1) {
      setDeletedNodes((nds) =>
        nds.concat({ id: currentSourceNextSource[0]?.target })
      );
      getUpdateNodesOnDelete(currentSourceNextSource[0]?.target);
      getUpdateEdgesOnDelete(currentSourceNextSource[0]?.target);
      setIsDrafted(false);
      setAddNodes(true);
      const nextNode = _.find(
        nodes,
        (n) => n.id === currentSourceNextSource?.target
      );
      onMultipleDeleteNode(nextNode?.elementId);
    }
  };

  const callGetFlowStructure = () => {
    getFlowStructure.request(sid).then((res) => {
      const isDataEmpty = _.isEmpty(res.data);
      const content = !isDataEmpty && res.data.content;
      const ns = content && content.nodes.length ? content.nodes : nodes;
      const es = content && content.edges.length ? content.edges : edges;
      if (content) {
        setIsDrafted(true);
      }
      setNodes((nds) => ns);
      setEdges((eds) => es);
      if (isDataEmpty === true) {
        setIsPublished(false);
      }
      if (res.data.publish === true) {
        setIsPublished(true);
      }
      setCallUpdateApi({ isUpdate: !isDataEmpty, fid: res.data._id });
      setIsUpdateFlowStructure(false);
    });
  };

  useEffect(() => {
    if (!_.isEmpty(nodesDetails)) {
      setNodes((nds) =>
        nds.map((n) => {
          if (nodesDetails[n.id]) {
            n.data.label = nodesDetails[n.id].label;
          }
          return n;
        })
      );
    }
  }, [nodesDetails]);

  useEffect(() => {
    dispatch(setNodesData(nodes));
  }, [nodes]);

  useEffect(() => {
    callGetFlowStructure();
  }, []);

  useEffect(() => {
    if (isUpdateFlowStructure) {
      callGetFlowStructure();
    }
  }, [isUpdateFlowStructure]);

  return (
    <CssBox className="workspace-section">
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Data will not be saved. Are you sure you want to navigate from this
            page?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CssButton
            variant="contained"
            sx={{ width: "40px" }}
            onClick={handleCloseDialog}
          >
            Stay
          </CssButton>
          <CssButton
            variant="contained"
            sx={{ width: "40px" }}
            onClick={handleNavigate}
            autoFocus
          >
            Leave
          </CssButton>
        </DialogActions>
      </Dialog>

      <Grid container className="flow-wrapper" ref={flowWrapper}>
        <CustomReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onInit={(rfi) => setFlowInstance(rfi)}
          onDrop={onDrop}
          onDragOver={onDragOver}
          onNodeClick={openNodeForm}
          onEdgeClick={handleRemoveConnection}
        />
        {hasSRModificationAccess && <Grid className="button-stack three-buttons">
          <Stack spacing={2} direction="row" justifyContent={"end"}>
            {isPublished && !isUnpublish ? 
              (<>
                <CssButton
                  variant="contained"
                  sx={{ width: "200px" }}
                  size="medium"
                  disabled={nodes.length === 0 ? true : false}
                  onClick={() => handleUnpublishSR()}
                >
                  Unpublish
                </CssButton>
              </>) : 
              (<>
                <CssButton
                  variant="contained"
                  sx={{ width: "200px" }}
                  size="medium"
                  disabled={nodes.length === 0 ? true : false}
                  onClick={() => saveFlowStructure()}
                >
                  {isUnpublish ? "Save" : "Save As Draft"}
                </CssButton>
                <CssButton
                  variant="contained"
                  sx={{ width: "200px" }}
                  size="medium"
                  disabled={nodes.length ? false : true}
                  onClick={onPublish}
                >
                  Publish
                </CssButton>
              </>
            )}
            <CssButton
              variant="contained"
              sx={{ width: "200px" }}
              size="medium"
              onClick={onCancel}
            >
              Cancel
            </CssButton>
          </Stack>
        </Grid>}
      </Grid>
      <ModalContainer
        open={openModal}
        handleClose={() => setOpenModal(false)}
        title={selectedNode?.data?.label}
        className={
          selectedNode && "max-width"
          // selectedNode.type !== "track" &&
          // selectedNode.type !== "scene"
          //   ? "max-width"
          //   : ""
        }
      >
        {selectedNode && selectedNode.type === "track" && (
          <TrackForm node={selectedNode} handleResponse={handleResponse} />
        )}
        {selectedNode && selectedNode.type === "introductionTrailer" && (
          <IntroTrailerForm
            node={selectedNode}
            handleResponse={handleResponse}
          />
        )}
        {selectedNode && selectedNode.type === "scene" && (
          <SceneForm node={selectedNode} handleResponse={handleResponse} />
        )}
        {selectedNode && selectedNode.type === "questions" && (
          <QuestionsForm node={selectedNode} handleResponse={handleResponse} />
        )}
        {selectedNode && selectedNode.type === "feedbackSlide" && (
          <FeedbackSlideForm
            node={selectedNode}
            handleResponse={handleResponse}
          />
        )}
        {selectedNode && selectedNode.type === "decisionPoint" && (
          <DecisionPointForm
            node={selectedNode}
            handleResponse={handleResponse}
            onMultipleDeleteNode={onMultipleDeleteNode}
          />
        )}
        {selectedNode && selectedNode.type === "choices" && (
          <ChoiceForm
            node={selectedNode}
            handleResponse={handleResponse}
            nodes={nodes}
          />
        )}
      </ModalContainer>
      <CustomSnackbar
        open={openSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
        handleClose={handleCloseSnackbar}
      />
      <ConfirmDialog
        open={openNodeDeleteAlert}
        text={`Are you sure, you would like to delete, ${selectedNode?.data.label}?`}
        handleYesNo={onNodeDelete}
      />
      <ConfirmDialog
        open={openPublishModel}
        text={`Are you sure, you want to Publish?`}
        handleYesNo={handlePublishYes}
      />
    </CssBox>
  );
};

export default WorkFlow;
