/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { NavLink, useParams } from "react-router-dom";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import MediaCard from "Components/MediaCard";
import CssButton from "Components/CssButton";
import useRequests from "services/request-hook";
import useApi from "services/api-hook";
import CssTitle from "Components/CssTitle";
import CustomSnackbar from "Components/CustomSnackbar";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CssCircularProgress from "Components/CssCircularProgress";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 1.0),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 1.0),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "25ch",
      "&:focus": {
        width: "25ch",
      },
    },
  },
}));

const validationSchema = Yup.object().shape({
  asset: Yup.mixed().required("please Upload file is required"),
});

const AssetsList = (props) => {
  const { type } = useParams();
  const { getAssetsApi, getSignedUrlApi, createAssetsApi } = useRequests();
  const getSignedUrl = useApi(getSignedUrlApi);
  const createAssets = useApi(createAssetsApi);
  const getAssets = useApi(getAssetsApi);
  const [assetsLength, setAssetsLength] = useState(true);
  const [assetsList, setAssetsList] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [triggerObserver, setTriggerObserver] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);
  const [isAfterSubmit, setIsAfterSubmit] = useState(false);
  const containerRef = useRef(null);
  const [resLength, setResLength] = useState(null);
  const [text, setText] = useState("");

  const containerOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0.3,
  };

  const handleFetchAssetsList = (page) => {
    getAssets
      .request(type, page, text)
      .then((res) => {
        setResLength(res.headers.total_count);
        if (res.status === 200) {
          const { current_page, total_pages } = res?.headers;
          if (res?.data?.length > 0) {
            if (isAfterSubmit) {
              setIsAfterSubmit(false);
              setAssetsList([]);
              setAssetsList(res.data);
            } else {
              if (currentPage < 1) {
                setAssetsList(res.data);
              } else {
                if (assetsList.length < res.headers.total_count) {
                  setAssetsList((sls) => sls.concat(res.data));
                }
              }
            }
            setAssetsLength(true);
            if (parseInt(total_pages) > parseInt(current_page)) {
              setCurrentPage(current_page);
              setTriggerObserver(false);
            } else {
              setTriggerObserver(true);
            }
          } else {
            setAssetsList([]);
            setAssetsLength(false);
            setTriggerObserver(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (type) {
      handleFetchAssetsList(1);
    }
  }, []);

  useEffect(() => {
    if (isAfterSubmit) {
      handleFetchAssetsList(currentPage);
      setIsAfterSubmit(false);
    }
  }, [isAfterSubmit]);

  useEffect(() => {
    setCurrentPage(1);
    handleFetchAssetsList(1);
    setIsAfterSubmit(true);
  }, [text]);

  //close model
  const handleCloseModal = () => {
    setUploadModal(false);
  };

  //open model
  const handleOpenModal = () => {
    setUploadModal(true);
  };

  const onInteraction = (entities, observer) => {
    const [entity] = entities;

    if (entity.isIntersecting && !triggerObserver) {
      setTriggerObserver(true);
      if (!isNaN(parseInt(currentPage))) {
        handleFetchAssetsList(parseInt(currentPage) + 1);
      }
    }
  };

  const addAssetsToDB = (bucket, payload) => {
    createAssets
      .request(bucket, payload)
      .then((res) => {
        if (res.status === 200) {
          console.log("res", res);
          setIsAfterSubmit(true);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    if (assetsList.length < resLength) {
      const observer = new IntersectionObserver(
        onInteraction,
        containerOptions
      );
      if (containerRef.current) observer.observe(containerRef.current);

      return () => {
        if (containerRef.current) observer.unobserve(containerRef.current);
      };
    }
  }, [containerRef, containerOptions]);

  let images = [];
  let imageNames = [];

  return (
    <>
      <CssTitle sx={{ mb: 3 }} variant="h6"><NavLink to={'/media'}>Media</NavLink> <NavigateNextIcon /> <span style={{ textTransform: "capitalize"}}>{`assets of ${type}`}</span></CssTitle>
      <Grid container spacing={3}>
        <Grid item xs={12} className={"title-wrapper"}>
          <CssTitle
            variant="h4"
            className={"asset-title"}
          >{`assets of ${type}`}</CssTitle>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => setText(e.target.value)}
            />
          </Search>
          <CssButton
            variant="contained"
            onClick={handleOpenModal}
            sx={{ width: "max-content" }}
          >
            Upload
          </CssButton>
        </Grid>
        <CssTitle
          hidden={assetsLength}
          style={{ fontSize: "25px", marginTop: "170px", marginLeft: "500px" }}
        >
          {"No Data Found"}
        </CssTitle>
      </Grid>
      <Grid container spacing={4} sx={{ marginTop: "1rem" }}>
        {assetsLength
          ? assetsList?.map((item, i) => {
              images.push(item?.file_url);
              imageNames.push(item?.filename);
              return (
                <Grid item xs={3} key={i}>
                  <MediaCard
                    image={
                      item?.file_url.substring(
                        0,
                        item?.file_url?.indexOf("?")
                      ) || item?.file_url
                    }
                    alt={item?.filename}
                    title={item?.filename}
                    subTitle={item?.type}
                    actionShow={true}
                    images={images}
                    imageNames={imageNames}
                    index={i}
                    viewFullScreen={true}
                  />
                </Grid>
              );
            })
          : ""}
        {/** DO NOT REMOVE THIS EMPTY GRID - START */}
        <Grid item xs={3} ref={containerRef} sx={{ height: 50 }}></Grid>
        {/** DO NOT REMOVE THIS EMPTY GRID - END */}
      </Grid>
      <Dialog
        onClose={handleCloseModal}
        aria-labelledby="customized-dialog-title"
        open={uploadModal}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
          Add Asset
        </DialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              asset: null,
            }}
            validationSchema={validationSchema}
            validateOnMount={true}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              let file = values.asset;
              let formData = new FormData();
              formData.append("file", file);
              getSignedUrl
                .request(type || "other")
                .then((res) => {
                  if (res.status === 200) {
                    let client = res?.data?.client;
                    let blobSAS = res?.data?.blobSAS;
                    let name = file?.name.split(".");
                    let fileExtension = name.pop();
                    let fileName = `${name[0]}${Date.now()}.${fileExtension}`;
                    const finalUrl = `${client}/${fileName}?${blobSAS}`;
                    fetch(`${finalUrl}`, {
                      method: "PUT",
                      body: file,
                      headers: {
                        "x-ms-blob-type": "BlockBlob",
                        "Content-Type": "multipart/form-data",
                      },
                    })
                      .then((response) => {
                        if (
                          response.status === 201 ||
                          response.status === 200
                        ) {
                          let fileUrl = response?.url?.substring(
                            0,
                            response?.url?.indexOf("?")
                          );
                          var path = fileUrl.split("/");
                          fileUrl = process.env.REACT_APP_CDN_URL
                            ? `${process.env.REACT_APP_CDN_URL}/${path[3]}/${path[4]}`
                            : fileUrl;
                          let payload = {
                            fileurl: fileUrl,
                            filename: fileName,
                            type: type,
                          };
                          addAssetsToDB(type, payload);
                          setUploadModal(false);
                          setSubmitting(false);
                          resetForm();
                          setOpenSnackbar(true);
                        }
                      })
                      .catch((error) => {
                        let errorObj = error?.response?.data;
                        setSubmitting(false);
                        alert(errorObj?.message);
                        console.log(errorObj);
                      });
                  }
                })
                .catch((error) => {
                  let errorObj = error?.response?.data;
                  setSubmitting(false);
                  alert(errorObj?.message);
                  console.log(errorObj);
                });
            }}
          >
            {({ isValid, isSubmitting, handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit} encType="multipart/form-data">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <input
                      id="file"
                      name="file"
                      type="file"
                      accept="image/*,video/*,audio/*"
                      onChange={(event) => {
                        setFieldValue("asset", event.currentTarget.files[0]);
                      }}
                      className="form-control"
                    />
                  </Grid>
                </Grid>
                <DialogActions>
                  <CssButton
                    sx={{
                      color: "#fff",
                      backgroundColor: "#673068",
                      width: "40px",
                    }}
                    variant="contained"
                    size="small"
                    onClick={handleCloseModal}
                    disabled={isSubmitting}
                  >
                    Close
                  </CssButton>
                  <CssButton
                    sx={{
                      color: "#fff",
                      backgroundColor: "#673068",
                      width: "40px",
                    }}
                    variant="contained"
                    size="small"
                    onClick={handleSubmit}
                    type="Submit"
                    disabled={!isValid || isSubmitting}
                  >
                    {isSubmitting ? <CssCircularProgress size={20} /> : "Submit"}
                  </CssButton>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <CustomSnackbar
        open={openSnackbar}
        message={"Asset Uploaded Successfully"}
        handleClose={handleCloseSnackbar}
      />
    </>
  );
};
export default AssetsList;
