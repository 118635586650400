import React, { useState, useCallback } from "react";
import { Grid, Card, IconButton, Stack, Typography, TextField, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { FastField } from "formik";
import CssTextField from "Components/CssTextField";
import useRequests from "services/request-hook";
import useApi from "services/api-hook";
import { Avatar, CircularProgress } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageView from "../../Components/ImageView";
import AddLinkIcon from '@mui/icons-material/AddLink';
// flex-wrap: wrap

const CssCard = styled(Card)({
  marginTop: "30px !important",
  padding: "6px 20px 20px",
  position: "relative",
  backgroundColor: "transparent",
  border: "1px solid #673068",
});

const CssCloseIconButton = styled(IconButton)({
  position: "absolute",
  top: "4px",
  right: "4px",
  border: "1px solid #F00",
  padding: 0,
});

const CssCloseIcon = styled(CloseIcon)({
  color: "#F00",
  fontSize: "1.2rem",
});

const CssUploadButton = styled(IconButton)({
  position: "relative",
  top: "20px",
});

const CssCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  position: "relative",
  top: "20px",
  left: "20px",
}));

const CssUploadIcon = styled(CloudUploadIcon)({
  color: "#FFF",
});
const CssDeleteIcon = styled(DeleteIcon)({
  color: "#FFF",
});
const CssLinkIcon = styled(AddLinkIcon)({
  color: "#FFF",
  marginTop: "-40px",
})

const CssAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: "transparent",
  border: "1px dashed",
  borderColor: theme.palette.primary.contrastText,
  top: "18px",
  left: "12px",
  "&.avatar": {
    borderWidth: 0,
  },
  "& svg": {
    display: "none",
  },
}));

const Choices = (props) => {
  const {
    index,
    enableClose,
    onRemove,
    onSetFieldValue,
    errors,
    touched,
    kpi,
    values,
    handleBlur,
    choice,
    isPublished,
    bucket,
    choiceType,
    onMultipleDeleteNode,
  } = props;

  const { getSignedUrlApi, createAssetsApi } = useRequests();
  const getSignedUrl = useApi(getSignedUrlApi);
  const createAssets = useApi(createAssetsApi);
  const [showLoader, setShowLoader] = useState(false);
  const [setFlag, setFlagValue] = useState(true);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [openURL, setOpenURL] = useState(false);
  const [invalidUrlError, setInvalidUrlError] = useState(false);
  const [url, setUrl] = React.useState("");
  
  const addAssetsToDB = (payload) => {
    createAssets
      .request(bucket, payload)
      .then((res) => {
        if (res.status === 200) {
          console.log("res", res);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const fetchAndUploadFile = (file) => {
    // if (!file || !file.name.match(/^[a-zA-Z0-9_\-]+\.[a-zA-Z0-9]+$/)) {
    //   // Invalid file name
    //   setShowLoader(false);
    //   setInvalidUrlError(true);
    //   return null;
    // } 
    let selectedFile = file;
    let formData = new FormData();
    formData.append("file", selectedFile);
    getSignedUrl
      .request(bucket || "other")
      .then((res) => {
        if (res.status === 200) {
          let client = res?.data?.client;
          let blobSAS = res?.data?.blobSAS;
          let name = file?.name.split(".");
          let fileExtension = name?.pop();
          let fileName = `${name[0]}${Date.now()}.${fileExtension}`;
          const finalUrl = `${client}/${fileName}?${blobSAS}`;
          fetch(`${finalUrl}`, {
            method: "PUT",
            body: file,
            headers: {
              "x-ms-blob-type": "BlockBlob",
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
                        if (response.status === 201 || response.status === 200) {
                          let fileUrl = response?.url?.substring(
                            0,
                            response?.url?.indexOf("?")
                          );
                          var path = fileUrl.split("/");
                          fileUrl = process.env.REACT_APP_CDN_URL
                            ? `${process.env.REACT_APP_CDN_URL}/${path[3]}/${path[4]}`
                            : fileUrl;
                          onSetFieldValue(`choices.${index}.icon`, { url: fileUrl });
                          let payload = {
                            fileurl: fileUrl,
                            filename: fileName,
                            type: bucket,
                          };
                          addAssetsToDB(payload);
                          setShowLoader(false);
                          setUrl("")
                          setOpenURL(false)
                        }
                      })
            .catch((error) => {
              console.log("error", error);
              setShowLoader(false);
              setInvalidUrlError(true);
            });
        }
      })
      .catch((error) => {
        console.log("error", error);
        setShowLoader(false);
        // setInvalidUrlError(true);
      });
  };

  const handleUploadFile = (file) => {
    setShowLoader(true);
    setInvalidUrlError(false);
    if (file instanceof File) {
      fetchAndUploadFile(file);
    } else {
      fetch(file)
        .then((response) => response.blob())
        .then((blob) => {
          const urlFile = new File([blob], `urlFile.${blob.type.split('/')[1]}`, {
            type: blob.type,
            lastModified: Date.now(),
          });
          if (!urlFile.name.match(/^[a-zA-Z0-9_\-]+\.[a-zA-Z0-9]+$/)) {
            setShowLoader(false);
            setInvalidUrlError(true);
            return null;
          } else {
            fetchAndUploadFile(urlFile);
          }
        })
        .catch((error) => {
          console.error("Error fetching file from URL:", error);
          setShowLoader(false);
          setInvalidUrlError(true);
        });
    }
  };
  
  // const handleUploadFile = (file) => {
  //   setShowLoader(true);
  //   setInvalidUrlError(false);
    
  //   getSignedUrl
  //     .request(bucket || "other")
  //     .then((res) => {
  //       if (res.status === 200) {
  //         let client = res?.data?.client;
  //         let blobSAS = res?.data?.blobSAS;
  //         let name = file?.name.split(".");
  //         let fileExtension = name.pop();
  //         let fileName = `${name[0]}${Date.now()}.${fileExtension}`;
  //         const finalUrl = `${client}/${fileName}?${blobSAS}`;
  //         fetch(`${finalUrl}`, {
  //           method: "PUT",
  //           body: file,
  //           headers: {
  //             "x-ms-blob-type": "BlockBlob",
  //             "Content-Type": "multipart/form-data",
  //           },
  //         })
  //           .then((response) => {
  //             if (response.status === 201 || response.status === 200) {
  //               let fileUrl = response?.url?.substring(
  //                 0,
  //                 response?.url?.indexOf("?")
  //               );
  //               var path = fileUrl.split("/");
  //               fileUrl = process.env.REACT_APP_CDN_URL
  //                 ? `${process.env.REACT_APP_CDN_URL}/${path[3]}/${path[4]}`
  //                 : fileUrl;
  //               onSetFieldValue(`choices.${index}.icon`, { url: fileUrl });
  //               let payload = {
  //                 fileurl: fileUrl,
  //                 filename: fileName,
  //                 type: bucket,
  //               };
  //               addAssetsToDB(payload);
  //               setShowLoader(false);
  //             }
  //           })
  //           .catch((error) => {
  //             console.log("error", error);
  //             setShowLoader(false);
  //           });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //       setShowLoader(false);
  //     });
  // };

  const openImageViewer = useCallback((index, imageUrl, e) => {
    setImages([imageUrl]);
    setCurrentImage(index);
    setIsViewerOpen(true);
    e.stopPropagation();
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handleOpenURL = () => {
    setOpenURL((open) => !open)
  }

  return (
    <Grid item container component={CssCard} className="card-wrapper">
      <Grid item xs={5} style={{ maxWidth: "32.666667%" }}>
        <FastField
          component={CssTextField}
          variant="outlined"
          size="small"
          name={`choices.${index}.label`}
          id={`choices.${index}.label`}
          onBlur={handleBlur}
          value={values.choices[index].label}
          InputProps={{
            className: "text-field",
          }}
          inputProps={{ readOnly: isPublished }}
          onChange={(e) => {
            onSetFieldValue(`choices.${index}.label`, e.target.value);
          }}
          label="Label*"
        />
        {errors &&
          errors.choices &&
          errors.choices[index] &&
          errors.choices[index].label &&
          touched &&
          touched.choices &&
          touched.choices[index] &&
          touched.choices[index].label && (
            <div className="field-error">{errors.choices[index].label}</div>
          )}
      </Grid>

      <Grid item xs={5} style={{ maxWidth: "32.666667%" }}>
        <FastField
          component={CssTextField}
          variant="outlined"
          type="text"
          size="small"
          name={`choices.${index}.index`}
          id={`choices.${index}.index`}
          onChange={(e) => {
            const re = /^[0-9]+$/;
            if (e.target.value === "" || re.test(e.target.value))
              onSetFieldValue(`choices.${index}.index`, e.target.value);
          }}
          onBlur={handleBlur}
          value={values.choices[index].index}
          InputProps={{
            className: "text-field",
          }}
          inputProps={{ readOnly: isPublished }}
          label="Index"
        />
      </Grid>

      <Grid
        item
        xs={5}
        className="icon-file-grid"
        style={{ maxWidth: "32.666667%" }}
      >
        <Stack spacing={2} direction="row">
          <CssAvatar
            alt={"Icon"}
            src={choice?.icon?.url}
            className={choice?.icon?.url ? "avatar" : ""}
            onClick={(e) => openImageViewer(0, choice?.icon?.url, e)}
            style={{ cursor: "pointer" }}
          />
          <label htmlFor={`choices.${index}.icon`}>
            <input
              id={`choices.${index}.icon`}
              name={`choices.${index}.icon`}
              type="file"
              disabled={isPublished}
              onChange={(event) =>
                handleUploadFile(event.currentTarget.files[0])
              }
              className="form-control"
              style={{ display: "none" }}
              accept="image/*"
              inputProps={{ readOnly: isPublished }}
            />
            {showLoader ? (
              <CssCircularProgress size={30} />
            ) : (
              <>
                <Typography sx={{ fontSize: "17px", padding: "0px" }}>
                  Choice Icon
                </Typography>
                <div  style={{display:'flex'}}>
                <CssUploadButton
                  aria-label="upload"
                  variant="contained"
                  component="span"
                  sx={{ top: "0px" }}
                >
                  <CssUploadIcon />
                </CssUploadButton>
                <CssUploadButton
                  variant="contained"
                >
                  <CssLinkIcon onClick={handleOpenURL}/>
                </CssUploadButton>
                <CssUploadButton
                  aria-label="upload"
                  variant="contained"
                  component="span"
                  sx={{ cursor: "pointer", top: "0px" }}
                  onClick={(e) => {
                    onSetFieldValue(`choices.${index}.icon`, { url: "" });
                    e.preventDefault();
                  }}
                >
                  <CssDeleteIcon />
                </CssUploadButton>
                </div>
                {openURL && <div style={{ display: "flex", justifyContent: "flex-end",alignItems:"center", marginTop: "8px" }}>
                  {/* <div style={{ fontSize: "14px", color: "white", marginRight: "10px"}}>{`URL`} </div> */}
                  <div>
                  <TextField
                    aria-label="upload"
                    placeholder="Enter URL"
                    onChange={(event) => {
                      setUrl(event.target.value) 
                      setInvalidUrlError(false)}}
                    variant="outlined"
                    margin="dense"
                    InputProps={{ style: { height: "30px",width:'16.3rem',marginLeft:'-4.2rem'} }}
                  />
                  {/* {invalidUrlError && <div style={{ color: 'red', fontSize: '14px', marginLeft: '4rem', marginTop: '4px' }}>{`Invalid File Format`}</div>} */}
                  </div>
                    <Button onClick={(e) => {
                      handleUploadFile(e.target.value)
                      e.preventDefault();
                    }} style={{ color: 'white' }} >
                      Add
                    </Button>
                </div>}
              </>
            )}
          </label>
        </Stack>
      </Grid>
      {kpi &&
        kpi.length > 0 &&
        kpi.map((o, i) => (
          <Grid item xs={5} key={i} style={{ maxWidth: "32.666667%" }}>
            <FastField
              component={CssTextField}
              variant="outlined"
              size="small"
              type="text"
              onKeyDown={(evt) =>
                (evt.key === "e" || evt.key === "E") && evt.preventDefault()
              }
              name={`choices.${index}.kpis_to_impact.${o.uid}`}
              value={
                values?.choices[index]?.kpis_to_impact &&
                values?.choices[index]?.kpis_to_impact[o.uid]
              }
              InputProps={{
                className: "text-field",
              }}
              inputProps={{ readOnly: isPublished }}
              onChange={(e) => {
                const re = /^-?[0-9]*\.?[0-9]*$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  onSetFieldValue(
                    `choices.${index}.kpis_to_impact.${o.uid}`,
                    e.target.value
                  );
                  setFlagValue(false);
                } else {
                  if (setFlag)
                    onSetFieldValue(
                      `choices.${index}.kpis_to_impact.${o.uid}`,
                      ""
                    );
                }
              }}
              label={o.name}
            />
            {errors &&
              errors.choices &&
              errors.choices[index] &&
              errors.choices[index][o.uid] &&
              touched &&
              touched.choices &&
              touched.choices[index] &&
              touched.choices[index].kpis_to_impact &&
              touched.choices[index].kpis_to_impact[o.uid] && (
                <div className="field-error">
                  {errors.choices[index].kpis_to_impact[o.uid]}
                </div>
              )}
          </Grid>
        ))}
      {enableClose && !isPublished && (
        <CssCloseIconButton
          onClick={() => {
            if (
              choiceType === "question" ||
              choice?.id === "" ||
              choice?.id === undefined
            ) {
              onRemove(index);
            } else {
              let is_sure = window.confirm(
                "Are you sure to delete this record?"
              );
              if (is_sure) {
                onMultipleDeleteNode(choice.id);
                onRemove(index);
              }
            }
          }}
        >
          <CssCloseIcon />
        </CssCloseIconButton>
      )}
      {isViewerOpen && (
        <ImageView
          currentImage={currentImage}
          openImageViewer={openImageViewer}
          closeImageViewer={closeImageViewer}
          isViewerOpen={isViewerOpen}
          imageNames=""
          viewFullScreen={false}
          images={images}
        />
      )}
    </Grid>
  );
};

export default Choices;
