import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  padding: '16px 16px 16px 20px',
  background: `linear-gradient(-45deg, ${theme.palette.background.default}, ${theme.palette.background.light}, ${theme.palette.background.dark})`,
  boxShadow: '0px 2px 10px #000000',
  opacity: '1'
}));

export default AppBar;