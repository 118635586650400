import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Grid, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import CssDialogBox from "Components/CssDialogBox";
import assets from "data/assets.json";
import folder_img from "Assets/folder_img.png";
import SelectField from "Components/FormikComp/SelectField";
import CssButton from "Components/CssButton";
import useRequests from "services/request-hook";
import useApi from "services/api-hook";
import CssTitle from "Components/CssTitle";
import CustomSnackbar from "Components/CustomSnackbar";
import styled from "@emotion/styled";
import CssCircularProgress from "Components/CssCircularProgress";

const CssDialogBoxes = styled(CssDialogBox)(({ theme }) => ({
  "& .MuiPaper-root": {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "& .MuiOutlinedInput-input": {
      color: theme.palette.primary.contrastText,
    },
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.primary.contrastText,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.contrastText,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.contrastText,
    },
  },
  "& label": {
    color: theme.palette.primary.contrastText,
    "&.Mui-focused": {
      color: theme.palette.primary.contrastText,
    },
  },
}));

const validationSchema = Yup.object().shape({
  type: Yup.string().required("Title is required"),
  asset: Yup.mixed().required("please Upload file is required"),
});

const Media = (props) => {
  const navigate = useNavigate();
  const { getSignedUrlApi, createAssetsApi } = useRequests();
  const getSignedUrl = useApi(getSignedUrlApi);
  const createAssets = useApi(createAssetsApi);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  //close model
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  //open model
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //navigates to assets page
  const handleFolder = (assetType) => {
    if (assetType) {
      navigate(`/media/${assetType}`);
    }
  };

  const addAssetsToDB = (bucket, payload) => {
    createAssets
      .request(bucket, payload)
      .then((res) => {
        if (res.status === 200) {
          console.log("res", res);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className={"title-wrapper"}>
          <CssTitle variant="h4">Welcome to Media</CssTitle>
          <CssButton
            variant="contained"
            onClick={handleOpenModal}
            sx={{ width: "max-content" }}
          >
            Add Asset
          </CssButton>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {assets?.map((list, i) => (
              <Grid
                item
                xs={1}
                key={`${list.value}`}
                sx={{ cursor: "pointer", marginRight: "1.25rem" }}
              >
                <div>
                  <img
                    src={folder_img}
                    alt=""
                    className={"folder"}
                    onClick={() => handleFolder(list?.value)}
                  />
                  <CssTitle variant="body1" sx={{ paddingTop: "5px" }}>
                    {list?.label}
                  </CssTitle>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <CssDialogBoxes
          onClose={handleCloseModal}
          aria-labelledby="customized-dialog-title"
          open={openModal}
          fullWidth={true}
          maxWidth="xs"
        >
          <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
            Add Asset
          </DialogTitle>
          <DialogContent dividers>
            <>
              <Formik
                initialValues={{
                  type: "",
                  asset: null,
                }}
                validationSchema={validationSchema}
                validateOnMount={true}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  let bucket = values?.type;
                  let file = values.asset;
                  let formData = new FormData();
                  formData.append("file", file);
                  getSignedUrl
                    .request(bucket || "other")
                    .then((res) => {
                      if (res.status === 200) {
                        let client = res?.data?.client;
                        let blobSAS = res?.data?.blobSAS;
                        let name = file?.name.split(".");
                        let fileExtension = name.pop();
                        let fileName = `${
                          name[0]
                        }${Date.now()}.${fileExtension}`;
                        const finalUrl = `${client}/${fileName}?${blobSAS}`;
                        fetch(`${finalUrl}`, {
                          method: "PUT",
                          body: file,
                          headers: {
                            "x-ms-blob-type": "BlockBlob",
                            "Content-Type": "multipart/form-data",
                          },
                        })
                          .then((response) => {
                            if (
                              response.status === 201 ||
                              response.status === 200
                            ) {
                              let fileUrl = response?.url?.substring(
                                0,
                                response?.url?.indexOf("?")
                              );
                              var path = fileUrl.split("/");
                              fileUrl = process.env.REACT_APP_CDN_URL
                                ? `${process.env.REACT_APP_CDN_URL}/${path[3]}/${path[4]}`
                                : fileUrl;
                              let payload = {
                                fileurl: fileUrl,
                                filename: fileName,
                                type: bucket,
                              };
                              addAssetsToDB(bucket, payload);
                              setOpenModal(false);
                              setSubmitting(false);
                              resetForm();
                              setOpenSnackbar(true);
                            }
                          })
                          .catch((error) => {
                            console.log("error", error);
                            setSubmitting(false);
                          });
                      }
                    })
                    .catch((error) => {
                      console.log("error", error);
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  values,
                  isValid,
                  errors,
                  touched,
                  isSubmitting,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <div className="form_control">
                          <SelectField
                            name="type"
                            id="type"
                            label="Type*"
                            options={assets}
                          />
                          {errors.type && touched.type ? (
                            <div className="errorText">{errors.type}</div>
                          ) : null}
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="form_control">
                          <input
                            id="asset"
                            name="asset"
                            type="file"
                            accept="image/*,video/*,audio/*"
                            onChange={(event) => {
                              setFieldValue(
                                "asset",
                                event.currentTarget.files[0]
                              );
                            }}
                            className="form-control"
                          />
                          {errors.asset && touched.asset ? (
                            <div className="errorText">{errors.asset}</div>
                          ) : null}
                        </div>
                      </Grid>
                    </Grid>
                    <DialogActions>
                      <CssButton
                        sx={{
                          color: "#fff",
                          backgroundColor: "#673068",
                          width: "40px",
                        }}
                        variant="contained"
                        size="small"
                        onClick={handleCloseModal}
                        disabled={isSubmitting}
                      >
                        Close
                      </CssButton>
                      <CssButton
                        sx={{
                          color: "#fff",
                          backgroundColor: "#673068",
                          width: "40px",
                        }}
                        variant="contained"
                        size="small"
                        onClick={handleSubmit}
                        type="Submit"
                        disabled={!isValid || isSubmitting}
                      >
                        {isSubmitting ? <CssCircularProgress size={20} /> : "Submit"}
                      </CssButton>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </>
          </DialogContent>
        </CssDialogBoxes>
        <CustomSnackbar
          open={openSnackbar}
          message={"Asset Added Successfully"}
          handleClose={handleCloseSnackbar}
        />
      </Grid>
    </>
  );
};
export default Media;
