import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const CssButton = styled(Button)(({ theme }) => ({
  width: '100%',
  textTransform: 'uppercase',
  fontWeight: 600,
  // '&:hover': {  
  //   backgroundColor: '#24062C'
  // },
  '&.MuiButton-contained': {
    backgroundColor: '#673068',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#24062C'
    }
  },
  // '&.MuiButton-outlined': {
  //   color: '#FFF',
  //   borderColor: '#673068'
  // },
  '&.Mui-disabled': {
    color: '#fff',
    opacity: '0.7'
  }
}));

export default CssButton;