/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import CssTitle from "Components/CssTitle";
import CssButton from "Components/CssButton";
import ModalContainer from "Components/ModalContainer";
import { Formik, Form, FastField } from "formik";
import { Grid, Stack, Container } from "@mui/material";
import CssTextField from "Components/CssTextField";
import useRequests from "services/request-hook";
import ApaThemesList from "./ApaThemesList";
import useApi from "services/api-hook";
import InputFileUpload from "Components/InputFileUpload";
import * as Yup from "yup";
import CustomSnackbar from "Components/CustomSnackbar";
import ConfirmDialog from "Components/ConfirmDialog";
import { keyBy } from "lodash";
import CssCircularProgress from "Components/CssCircularProgress";
import { useSelector } from "react-redux";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "minimum of 3 characters")
    .max(30, "maximum of 30 characters")
    .matches(/^[A-Za-z]{2}[ A-Za-z0-9_-]*$/, "Name must be alphanumeric  only")
    .required("Name is required"),
});

let obj;

const Apas = () => {
  const {
    getApaThemesListApi,
    createApaThemeApi,
    getApaThemeApi,
    updateApaThemeApi,
    updateApaThemeStatusApi,
  } = useRequests();
  const roles = useSelector(state => state?.userReducer?.roles);
  const hasModificationAccess = !roles?.includes("Super Admin");
  const getApaThemesList = useApi(getApaThemesListApi);
  const createApaTheme = useApi(createApaThemeApi);
  const getApaTheme = useApi(getApaThemeApi);
  const updateApaTheme = useApi(updateApaThemeApi);
  const updateApaThemeStatus = useApi(updateApaThemeStatusApi);
  const [open, setOpen] = useState(false);
  const [callUpdate, setCallUpdate] = useState(false);
  const [themeName, setThemeName] = useState("");
  const [apaThemesDataList, setApaThemesDataList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [bgUrl, setBgUrl] = useState("");
  const [bgImgLogo, setBgImgLogo] = useState("");
  const [ldImg, setLdImg] = useState("");
  const [tqImg, setTqImg] = useState("");
  const [seImg, setSeImg] = useState("");
  const [footerLeftImg, setFooterLeftImg] = useState("");
  const [footerCenterImg, setFooterCenterImg] = useState("");
  const [footerRightImg, setFooterRightImg] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [updateText, setUpdateText] = useState("");
  const [openApaThemeStatusAlert, setOpenApaThemeStatusAlert] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: "",
    wl_screen_text: "",
    ld_text: "",
    tq_text: "",
    primary_color: "",
    secondary_color: "",
    text_color: "",
    timer_color: "",
    bg_img: "",
    bg_img_logo: "",
    ld_img: "",
    se_img: "",
    tq_img: "",
    footer_left: "",
    footer_center: "",
    footer_right: ""
  });

  const setSnackBar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const handleOpen = () => {
    setInitialValues({});
    setOpen(true);
  };

  const fetchApaThemesList = (pageNO, rowsPerPages) => {
    getApaThemesList
      .request(pageNO + 1, rowsPerPages)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            setRowsPerPage(+res?.headers?.limit);
            setCount(+res?.headers?.total_count);
            setApaThemesDataList([...res.data]);
          } else {
            setApaThemesDataList([]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchApaThemesList(page, rowsPerPage);
  }, []);

  const onFileUpload = ({ url }, stateName) => {
    if (url) {
      let finalUrl = url?.substring(0, url?.indexOf("?"));
      var path = finalUrl.split("/");
      finalUrl = process.env.REACT_APP_CDN_URL
        ? `${process.env.REACT_APP_CDN_URL}/${path[3]}/${path[4]}`
        : finalUrl;
      stateName(finalUrl);
    }
  };

  const handelOpenEdit = (apa_theme_name) => {
    if (apa_theme_name) {
      getApaTheme
        .request(apa_theme_name)
        .then((res) => {
          if (res.status === 200) {
            setThemeName(res.data._id);
            setCallUpdate(true);
            setBgUrl(res?.data?.bg_img);
            setBgImgLogo(res?.data?.bg_img_logo);
            setTqImg(res?.data?.tq_img);
            setLdImg(res?.data?.ld_img);
            setSeImg(res?.data?.se_img);
            const obj = keyBy(res?.data?.sponsors, "side");
            setFooterLeftImg(
              obj["left"] !== undefined ? obj["left"]["img_logo"] : ""
            );
            setFooterCenterImg(
              obj["center"] !== undefined ? obj["center"]["img_logo"] : ""
            );
            setFooterRightImg(
              obj["right"] !== undefined ? obj["right"]["img_logo"] : ""
            );
            res.data["footer_left"] = obj["left"] !== undefined ? obj["left"]["img_logo"] : "";
            res.data["footer_center"] = obj["center"] !== undefined ? obj["center"]["img_logo"] : ""
            res.data["footer_right"] = obj["right"] !== undefined ? obj["right"]["img_logo"] : ""
            setInitialValues(res.data);
            setOpen(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const onApaThemeStatusUpdate = (isUpdate) => {
    if (isUpdate) {
      setUpdateText("");
      setOpenApaThemeStatusAlert(false);
      updateThemeStatusApi(obj.apa_theme_id, obj.status);
    } else {
      setUpdateText("");
      setOpenApaThemeStatusAlert(false);
    }
  };

  const handelUpdateStatus = (apa_theme_id, status) => {
    setUpdateText(status === true ? "inactivate" : "activate");
    setOpenApaThemeStatusAlert(true);
    obj = { apa_theme_id: apa_theme_id, status: status };
  };

  const updateThemeStatusApi = (apa_theme_id, status) => {
    let themeStatus = status === true ? false : true;
    if (apa_theme_id) {
      const status_payload = {
        status: themeStatus,
      };
      updateApaThemeStatus
        .request(apa_theme_id, status_payload)
        .then((res) => {
          if (res.status === 200) {
            fetchApaThemesList(page, rowsPerPage);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            spacing={2}
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
          >
            <CssTitle variant="h4">APA Theme</CssTitle>
            {hasModificationAccess && <CssButton
              variant="contained"
              onClick={handleOpen}
              sx={{ width: "max-content" }}
            >
              Add APA Theme
            </CssButton>}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <ApaThemesList
            apaThemesDataList={apaThemesDataList}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            setCount={setCount}
            count={count}
            fetchApaThemesList={fetchApaThemesList}
            handelOpenEdit={handelOpenEdit}
            handelUpdateStatus={handelUpdateStatus}
            hasModificationAccess={hasModificationAccess}
          />
        </Grid>
      </Grid>
      <ModalContainer
        open={open}
        handleClose={() => setOpen(false)}
        title={"Add APA Theme"}
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          validateOnMount={true}
          isInitialValid={false}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            const payload = {
              name: values.name,
              wl_screen_text: values.wl_screen_text,
              ld_text: values.ld_text,
              tq_text: values.tq_text,
              primary_color: values.primary_color,
              secondary_color: values.secondary_color,
              text_color: values.text_color,
              timer_color: values.timer_color,
              bg_img: bgUrl,
              bg_img_logo: bgImgLogo,
              ld_img: ldImg,
              se_img: seImg,
              tq_img: tqImg,
              sponsors: [
                {
                  side: "left",
                  img_logo: footerLeftImg,
                },
                {
                  side: "center",
                  img_logo: footerCenterImg,
                },
                {
                  side: "right",
                  img_logo: footerRightImg,
                },
              ],
            };
            if (callUpdate) {
              updateApaTheme
                .request(themeName, payload)
                .then((res) => {
                  setSubmitting(false);
                  fetchApaThemesList(page, rowsPerPage);
                  setOpen(false);
                  setSnackBar();
                })
                .catch((error) => {
                  console.log("error", error);
                  setSubmitting(false);
                });
            } else {
              createApaTheme
                .request(payload)
                .then((res) => {
                  if (res.status === 200) {
                    setSubmitting(false);
                    fetchApaThemesList(page, rowsPerPage);
                    setOpen(false);
                    setSnackBar();
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                  setSubmitting(false);
                });
            }
          }}
        >
          {({
            dirty,
            isValid,
            values,
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            handleBlur,
          }) => (
            <Form
              onSubmit={handleSubmit}
              noValidate={true}
              autoComplete="off"
              className="form-wrapper formSpace"
            >
              <Container sx={{ marginTop: "10px" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <div className="form_control">
                      <FastField
                        component={CssTextField}
                        variant="outlined"
                        size="small"
                        name="name"
                        id="name"
                        label="Theme Name*"
                        focused
                        autoComplete="Off"
                        value={values.name}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("name", e.target.value);
                        }}
                        sx={{ marginTop: "0px" }}
                      />
                      {errors.name && touched.name ? (
                        <div className="errorText">{errors.name}</div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="form_control">
                      <FastField
                        component={CssTextField}
                        variant="outlined"
                        size="small"
                        name="wl_screen_text"
                        id="wl_screen_text"
                        label="Welcome Screen Text"
                        type="text"
                        focused
                        autoComplete="Off"
                        value={values.wl_screen_text}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("wl_screen_text", e.target.value);
                        }}
                        sx={{ marginTop: "0px" }}
                        helperText="Enter the Welcome Screen Message"
                      />
                      {errors.wl_screen_text && touched.wl_screen_text ? (
                        <div className="errorText">{errors.wl_screen_text}</div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="form_control">
                      <FastField
                        component={CssTextField}
                        variant="outlined"
                        size="small"
                        name="tq_text"
                        id="tq_text"
                        focused
                        label="Thank You Text"
                        autoComplete="Off"
                        value={values.tq_text}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("tq_text", e.target.value);
                        }}
                        sx={{ marginTop: "0px" }}
                        helperText="Enter the End Screen/Participation Screen Message"
                      />
                      {errors.tq_text && touched.tq_text ? (
                        <div className="errorText">{errors.tq_text}</div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="form_control">
                      <FastField
                        component={CssTextField}
                        variant="outlined"
                        size="small"
                        name="ld_text"
                        id="ld_text"
                        label="Loader Text"
                        focused
                        autoComplete="Off"
                        value={values.ld_text}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("ld_text", e.target.value);
                        }}
                        sx={{ marginTop: "0px" }}
                        helperText="Enter the Loader Text"
                      />
                      {errors.ld_text && touched.ld_text ? (
                        <div className="errorText">{errors.ld_text}</div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={5}>
                    <div className="form_control">
                      <FastField
                        component={CssTextField}
                        variant="outlined"
                        size="small"
                        name="primary_color"
                        id="primary_color"
                        value={values.primary_color}
                        onBlur={handleBlur}
                        type="color"
                        onChange={(e) => {
                          setFieldValue("primary_color", e.target.value);
                        }}
                        InputProps={{
                          className: "text-field",
                        }}
                        label="Primary Color"
                        helperText="Select Primary color for the background gradient"
                      />
                      {errors.primary_color && touched.primary_color ? (
                        <div className="errorText">{errors.primary_color}</div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={5}>
                    <div className="form_control">
                      <FastField
                        component={CssTextField}
                        variant="outlined"
                        size="small"
                        name="secondary_color"
                        id="secondary_color"
                        value={values.secondary_color}
                        onBlur={handleBlur}
                        type="color"
                        onChange={(e) => {
                          setFieldValue("secondary_color", e.target.value);
                        }}
                        InputProps={{
                          className: "text-field",
                        }}
                        label="Secondary Color"
                        helperText="Select Secondary color for the background gradient"
                      />
                      {errors.secondary_color && touched.secondary_color ? (
                        <div className="errorText">
                          {errors.secondary_color}
                        </div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ position: "relative" }}>
                      <div
                        style={{
                          margin: 0,
                          position: "absolute",
                          top: "50%",
                          transform: `translate("-50%, -50%")`,
                          backgroundColor: "",
                          height: "75px",
                          width: "80px",
                          border: "2px solid white",
                          backgroundImage: `linear-gradient(0deg, ${values.primary_color}, ${values.secondary_color})`,
                        }}
                      ></div>
                    </div>
                  </Grid>
                  <Grid item xs={5}>
                    <div className="form_control">
                      <FastField
                        component={CssTextField}
                        variant="outlined"
                        size="small"
                        name="text_color"
                        id="text_color"
                        value={values.text_color}
                        onBlur={handleBlur}
                        type="color"
                        onChange={(e) => {
                          setFieldValue("text_color", e.target.value);
                        }}
                        InputProps={{
                          className: "text-field",
                        }}
                        label="Text Color"
                        helperText=" Select the Text color for the content"
                      />
                      {errors.text_color && touched.text_color ? (
                        <div className="errorText">{errors.text_color}</div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={5}>
                    <div className="form_control">
                      <FastField
                        component={CssTextField}
                        variant="outlined"
                        size="small"
                        name="timer_color"
                        id="timer_color"
                        value={values.timer_color}
                        onBlur={handleBlur}
                        type="color"
                        onChange={(e) => {
                          setFieldValue("timer_color", e.target.value);
                        }}
                        InputProps={{
                          className: "text-field",
                        }}
                        label="Timer Color"
                        helperText="Select Timer color"
                      />
                      {errors.timer_color && touched.timer_color ? (
                        <div className="errorText">{errors.timer_color}</div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={2} direction="row">
                      <InputFileUpload
                        onFileUpload={(...arg) =>
                          onFileUpload(...arg, setBgUrl)
                        }
                        bucket={"other"}
                        acceptType="image/*"
                        stackInput={{
                          name: "bg_img",
                          label: "Background Image",
                          placeholder: "Background Image",
                        }}
                        id="background-image"
                        helperText="Upload a background image"
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={2} direction="row">
                      <InputFileUpload
                        onFileUpload={(...arg) =>
                          onFileUpload(...arg, setBgImgLogo)
                        }
                        bucket={"other"}
                        acceptType="image/*"
                        stackInput={{
                          name: "bg_img_logo",
                          label: "Logo",
                          placeholder: "Logo",
                        }}
                        id="theme-logo"
                        helperText="Upload a logo which appear above the background"
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={2} direction="row">
                      <InputFileUpload
                        onFileUpload={(...arg) =>
                          onFileUpload(...arg, setLdImg)
                        }
                        bucket={"other"}
                        acceptType="image/*"
                        stackInput={{
                          name: "ld_img",
                          label: "Loader Image",
                          placeholder: "Loader Image",
                        }}
                        id="loader-image"
                        helperText="Upload a Image for loader"
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={2} direction="row">
                      <InputFileUpload
                        onFileUpload={(...arg) =>
                          onFileUpload(...arg, setTqImg)
                        }
                        bucket={"other"}
                        acceptType="image/*"
                        stackInput={{
                          name: "tq_img",
                          label: "Thank You Image",
                          placeholder: "Thank You Image",
                        }}
                        id="thank-you-image"
                        helperText="Upload a image for thank you page"
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={2} direction="row">
                      <InputFileUpload
                        onFileUpload={(...arg) =>
                          onFileUpload(...arg, setSeImg)
                        }
                        bucket={"other"}
                        acceptType="image/*"
                        stackInput={{
                          name: "se_img",
                          label: "Session Expired Image",
                          placeholder: "Session Expired Image",
                        }}
                        id="session-expired"
                        helperText="Upload a image for time out screen"
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={2} direction="row">
                      <InputFileUpload
                        onFileUpload={(...arg) =>
                          onFileUpload(...arg, setFooterLeftImg)
                        }
                        bucket={"other"}
                        acceptType="image/*"
                        stackInput={{
                          name: "footer_left",
                          label: "Footer Logo(Left)",
                          placeholder: "Footer Logo(Left)",
                        }}
                        id="footer-left-image"
                        helperText="Upload a Image for footer logo in Left side"
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={2} direction="row">
                      <InputFileUpload
                        onFileUpload={(...arg) =>
                          onFileUpload(...arg, setFooterCenterImg)
                        }
                        bucket={"other"}
                        acceptType="image/*"
                        stackInput={{
                          name: "footer_center",
                          label: "Footer Logo(Center)",
                          placeholder: "Footer Logo(Center)",
                        }}
                        id="footer-center-image"
                        helperText="Upload a Image for footer logo in Center side"
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={2} direction="row">
                      <InputFileUpload
                        onFileUpload={(...arg) =>
                          onFileUpload(...arg, setFooterRightImg)
                        }
                        bucket={"other"}
                        acceptType="image/*"
                        stackInput={{
                          name: "footer_right",
                          label: "Footer Logo(Right)",
                          placeholder: "Footer Logo(Right)",
                        }}
                        id="footer-right-image"
                        helperText="Upload a Image for footer logo in Right side"
                      />
                    </Stack>
                  </Grid>
                  <Grid className="button-stack form-btn-wrapper">
                    <Stack spacing={2} direction="row">
                      <CssButton
                        variant="contained"
                        size="medium"
                        type="submit"
                        // disabled={!isValid || isSubmitting}
                      >
                        {isSubmitting ? <CssCircularProgress size={20} /> : "Save"}
                      </CssButton>
                      <CssButton
                        variant="contained"
                        sx={{ backgroundColor: "#673068" }}
                        size="medium"
                        type="reset"
                        disabled={!dirty}
                      >
                        Reset
                      </CssButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Container>
            </Form>
          )}
        </Formik>
      </ModalContainer>
      <ConfirmDialog
        open={openApaThemeStatusAlert}
        text={`Are you sure, you would like to ${updateText}?`}
        handleYesNo={onApaThemeStatusUpdate}
      />
      <CustomSnackbar
        open={openSnackbar}
        message={"Data Saved Successfully"}
        handleClose={handleCloseSnackbar}
      />
    </>
  );
};

export default Apas;
