import React, { useContext, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import "./Simulation.css";
import ModalContainer from "Components/ModalContainer";
import SimulationForm from "../Category/SimulationForm";
import { GlobalState } from "context/GlobalContext";
import CssButton from "Components/CssButton";
import CssTitle from "Components/CssTitle";
import CssGrid from "Components/CssGrid";
import CustomSnackbar from "Components/CustomSnackbar";
import { useSelector } from "react-redux";

const Header = () => {
  const { scenarioDetails, openEditForm, setOpenEditForm, setOpenKpi } =
    useContext(GlobalState);

  let initialValues = {
    title: scenarioDetails?.title,
    banner: "",
    type: scenarioDetails?.type,
    category: scenarioDetails?.category_id,
    status: scenarioDetails?.status,
    tags: scenarioDetails?.tags,
    trailer: scenarioDetails?.trailer_url || "",
    bg_img: "",
    files: null,
    files2: null,
    track_selection: scenarioDetails?.track_selection === true ? "True" : "False",
    isPublic: scenarioDetails?.isPublic === true ? "True" : "False",
    playback:""
  };

  const roles = useSelector(state => state?.userReducer?.roles);
  const hasSRModificationAccess = !roles?.includes("Super Admin");
  const [fileUrl, setFileUrl] = useState("");
  const [file1Url, setFile1Url] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [file2Url, setFile2Url] = useState("");
  const [file3Url, setFile3Url] = useState("");

  const handleOpenDrawer = () => {
    setOpenKpi(true);
  };

  const handelOpenEdit = () => {
    setOpenEditForm(true);
  };

  const handleCloseEdit = () => {
    setOpenEditForm(false);
  };

  const onFileUpload = ({ url }) => {
    if (url) {
      let fileUrl = url?.substring(0, url?.indexOf("?"));
      var path = fileUrl.split("/");
      fileUrl = process.env.REACT_APP_CDN_URL
        ? `${process.env.REACT_APP_CDN_URL}/${path[3]}/${path[4]}`
        : fileUrl;
      setFileUrl(fileUrl);
    } else {
      setFileUrl("");
    }
  };
  const onImageUpload = ({ url }) => {
    if (url) {
      let fileUrl = url?.substring(0, url?.indexOf("?"));
      var path = fileUrl.split("/");
      fileUrl = process.env.REACT_APP_CDN_URL
        ? `${process.env.REACT_APP_CDN_URL}/${path[3]}/${path[4]}`
        : fileUrl;
      setFile1Url(fileUrl);
    } else {
      setFile1Url("");
    }
  };
  const onVideoUpload = ({ url }) => {
    if (url) {
      let fileUrl = url?.substring(0, url?.indexOf("?"));
      var path = fileUrl.split("/");
      fileUrl = process.env.REACT_APP_CDN_URL
        ? `${process.env.REACT_APP_CDN_URL}/${path[3]}/${path[4]}`
        : fileUrl;
      setFile2Url(fileUrl);
    } else {
      setFile2Url("");
    }
  };

  const onAudioUpload = ({ url }) => {
    if (url) {
      let fileUrl = url?.substring(0, url?.indexOf("?"));
      var path = fileUrl.split("/");
      fileUrl = process.env.REACT_APP_CDN_URL
        ? `${process.env.REACT_APP_CDN_URL}/${path[3]}/${path[4]}`
        : fileUrl;
      setFile3Url(fileUrl);
    } else {
      setFile3Url("");
    }
  };

  const setEditSnackBar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <CssGrid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="title-section"
        sx={{ padding: "0.75rem" }}
      >
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <CssTitle variant="h5" className="head-title">
            {scenarioDetails.title ? scenarioDetails.title : "---"}
            {hasSRModificationAccess && <IconButton onClick={handelOpenEdit}>
              <Tooltip title="Edit" placement="top">
                <EditIcon />
              </Tooltip>
            </IconButton>}
          </CssTitle>
          {hasSRModificationAccess && <CssButton
            variant="contained"
            size="small"
            sx={{ width: "100px", color: "#663267" }}
            onClick={handleOpenDrawer}
          >
            ADD KPI's
          </CssButton>}
        </Grid>
      </CssGrid>
      <ModalContainer
        open={openEditForm}
        handleClose={handleCloseEdit}
        title={"Edit Simulation Basic info"}
      >
        <SimulationForm
          initialValues={initialValues}
          onVideoUpload={onVideoUpload}
          edit={true}
          saveEditImg={true}
          scenarioDetails={scenarioDetails}
          setOpenEditForm={setOpenEditForm}
          onFileUpload={onFileUpload}
          fileUrl={fileUrl}
          setFileUrl={setFileUrl}
          onImageUpload={onImageUpload}
          file1Url={file1Url}
          setFile1Url={setFile1Url}
          file2Url={file2Url}
          file3Url={file3Url}
          setFile2Url={setFile2Url}
          setFile3Url={setFile3Url}
          onAudioUpload={onAudioUpload}
          setSnackBar={setEditSnackBar}
        />
      </ModalContainer>
      <CustomSnackbar
        open={openSnackbar}
        message={"Data Saved Successfully"}
        handleClose={handleCloseSnackbar}
      />
    </>
  );
};

export default Header;
