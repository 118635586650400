import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import "./FileUpload.css";
import { IconButton } from "@mui/material";
import { GlobalState } from "../../context/GlobalContext";

const checkFileType = (fileUrl) => {
  // Define arrays of image and video extensions
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.webp', '.svg'];
  const videoExtensions = ['.mp4', '.avi', '.webm'];

  // Get the lowercase version of the URL
  let lowerUrl;
  if (typeof fileUrl === "string") {
    lowerUrl = fileUrl?.toLowerCase();
    // Check for image extensions
    if (imageExtensions.some(ext => lowerUrl.endsWith(ext))) {
      return 'image';
    }
  
    // Check for video extensions
    if (videoExtensions.some(ext => lowerUrl.endsWith(ext))) {
      return 'video';
    }
  } else {
    lowerUrl = fileUrl?.url?.toLowerCase();
  }

  

  return ""
}

const CssCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  position: "relative",
  top: "8px",
  right: "8px",
}));

const ImagePreview = (props) => {
  const { isPublished } = useContext(GlobalState);
  const {
    imgPreview,
    fileData,
    imgPath,
    isSpinnerEnabled,
    onImageRemove,
    onImageView,
    onPlayVideo,
    onMusicVideo,
    accept,
  } = props;

  return (
    <>
      {accept["image/*"] && (fileData?.type?.includes("image") || checkFileType(imgPreview) === "image") && (
        <div className="image-container">
          <img src={imgPreview} alt={imgPath} className="image" />
          <div className="overlay">
            {isSpinnerEnabled && <CssCircularProgress size={24} />}
            {isPublished ? null : (
              <IconButton style={{ color: "#FFF" }} onClick={onImageRemove}>
                <DeleteIcon style={{ fontSize: "30px" }} />
              </IconButton>
            )}
            <IconButton style={{ color: "#FFF" }} onClick={onImageView}>
              <VisibilityOutlinedIcon style={{ fontSize: "30px" }} />
            </IconButton>
          </div>
        </div>
      )}
      {accept["video/*"] && (fileData?.type?.includes("video") || checkFileType(imgPreview) === "video") && (
        <div className="image-container">
          <video src={imgPreview} alt={imgPath} className="image" />
          <div className="overlay">
            {isSpinnerEnabled && <CssCircularProgress size={24} />}
            {isPublished ? null : (
              <IconButton style={{ color: "#FFF" }} onClick={onImageRemove}>
                <DeleteIcon style={{ fontSize: "30px" }} />
              </IconButton>
            )}
            <IconButton style={{ color: "#FFF" }} onClick={(e)=>onPlayVideo(e,"video")}>
              <PlayCircleOutlineIcon style={{ fontSize: "30px" }} />
            </IconButton>
          </div>
        </div>
      )}
      {accept["audio/*"] && (
        <div className="image-container">
          <img src={"/images/AudioImage.jpg"} alt={imgPath} className="image" />
          <div className="overlay">
            {isSpinnerEnabled && <CssCircularProgress size={24} />}
            {isPublished ? null : (
              <IconButton style={{ color: "#FFF" }} onClick={onImageRemove}>
                <DeleteIcon style={{ fontSize: "30px" }} />
              </IconButton>
            )}
            <IconButton style={{ color: "#FFF" }} onClick={(e)=>onMusicVideo(e, "audio")}>
              <PlayCircleOutlineIcon style={{ fontSize: "30px" }} />
            </IconButton>
          </div>
        </div>
      )}
    </>
  );
};
export default ImagePreview;
