import useAxios from "./axios-hook";

const useRequests = () => {
  const { axios, axios_v2 } = useAxios();

  const fileUploadApi = async (type, file) => {
    return axios.post(`/assets?type=${type}`, file, {
      "Content-Type": "multipart/form-data",
    });
  };

  const getScenarioApi = async (scenarioId) => {
    return axios.get(`/scenarios/${scenarioId}`);
  };

  const trackApi = async (scenarioId, payload) => {
    return axios.post(`/scenarios/${scenarioId}/flows`, payload);
  };

  const getTrackApi = async (scenarioId, flowId) => {
    return axios.get(`/scenarios/${scenarioId}/flows/${flowId}`);
  };

  const updateTrackApi = async (scenarioId, flowId, payload) => {
    return axios.put(`/scenarios/${scenarioId}/flows/${flowId}`, payload);
  };

  const flowStructureApi = async (scenarioId, payload) => {
    return axios_v2.post(`/scenarios/${scenarioId}/flow_structures`, payload);
  };

  const updateFlowStructureApi = async (scenarioId, flowId, payload) => {
    return axios_v2.put(
      `/scenarios/${scenarioId}/flow_structures/${flowId}`,
      payload
    );
  };

  const getFlowStructureApi = async (scenarioId) => {
    return axios_v2.get(`/scenarios/${scenarioId}/flow_structures`);
  };

  const feedbackSlideApi = async (payload) => {
    return axios.post(`/injects/feedback_slides`, payload);
  };

  const updateFeedbackSlideApi = async (feedbackSlideId, payload) => {
    return axios.put(`/injects/feedback_slides/${feedbackSlideId}`, payload);
  };

  const introTrailerApi = async (payload) => {
    return axios.post(`/injects/introduction_trailer`, payload);
  };

  const updateIntroTrailerApi = async (introTrailerId, payload) => {
    return axios.put(
      `/injects/introduction_trailer/${introTrailerId}`,
      payload
    );
  };

  const getInjectsApi = async (elementId) => {
    return axios.get(`/injects/${elementId}`);
  };

  const questionsApi = async (payload) => {
    return axios.post(`/questions`, payload);
  };

  const getQuestionsApi = async (questionId) => {
    return axios.get(`/questions/${questionId}`);
  };

  const updateQuestionsApi = async (questionId, payload) => {
    return axios.put(`/questions/${questionId}`, payload);
  };

  const sceneApi = async (payload) => {
    return axios.post(`/discussions`, payload);
  };

  const getSceneApi = async (sceneId) => {
    return axios.get(
      `/discussions/${sceneId}
      `
    );
  };
  const getIndividualMeetingApi = async (meeting_id) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/meeting-management/meetings/${meeting_id}`
    );
  };
  const updateMeetingApi = async (meeting_id, payload) => {
    return axios.put(
      `${process.env.REACT_APP_API_URL}/meeting-management/meetings/${meeting_id}`,
      payload
    );
  };

  const updateSceneApi = async (sceneId, payload) => {
    return axios.put(`/discussions/${sceneId}`, payload);
  };

  const getCategoriesApi = async () => {
    return axios.get(`/categories`);
  };

  const getScenarioListApi = async (categoryId, page, status, text) => {
    return axios.get(
      `/scenarios?category_id=${categoryId}&page=${page}&status=${status}&title=${text}`
    );
  };

  const scenarioApi = async (payload) => {
    return axios.post(`/scenarios`, payload);
  };

  const editScenarioApi = async (scenarioId, payload) => {
    return axios.put(`/scenarios/${scenarioId}`, payload);
  };

  const getAssetsApi = async (type, page, text) => {
    return axios.get(`/assets?type=${type}&filename=${text}&page=${page}`);
  };

  const kpiApi = async (scenarioId, payload) => {
    return axios.post(`/scenarios/${scenarioId}/kpis`, payload);
  };

  const getKpiApi = async (scenarioId) => {
    return axios.get(`/scenarios/${scenarioId}/kpis`);
  };

  const updateKpiApi = async (scenarioId, kpiId, payload) => {
    return axios.put(`/scenarios/${scenarioId}/kpis/${kpiId}`, payload);
  };

  const deleteKpiApi = async (scenarioId, kpiId) => {
    return axios.delete(`/scenarios/${scenarioId}/kpis/${kpiId}`);
  };

  const deleteSimulationApi = async (simulationId) => {
    return axios.delete(`/scenarios/${simulationId}/delete`);
  };

  const getPlayersApi = async () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL
      }/v1/user-management/players?conference_id=${true}&limit=10000`
    );
  };

  const getScenariosOptionsApi = async () => {
    return axios.get(`/scenarios?status=PUBLISHED&limit=10000`);
  };

  const createMeetingApi = async (payload) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/meeting-management/meetings`,
      payload
    );
  };

  const getMeetingsApi = async (page, limit) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/meeting-management/meetings?page=${page}&limit=${limit}`
    );
  };

  const getThemesApi = async (page, limit) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/v1/scenario-management/themes?page=${page}&limit=${limit}`
    );
  };

  const getMeetingDetailsApi = async (mId) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/meeting-management/meetings/${mId}`
    );
  };

  const getSignedUrlApi = async (type) => {
    return axios.get(`/assets/upload/init?container_name=${type}`);
  };

  const uploadFileToAzureApi = async (url, file) => {
    return axios.put(url, file, { headers: { "x-ms-blob-type": "BlockBlob" } });
  };

  const createAssetsApi = async (type, payload) => {
    return axios.post(`/assets/create?type=${type}`, payload);
  };

  const getUsersListApi = async (page, limit) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/v1/user-management/players?limit=${limit}&page=${page}`
    );
  };

  const getSRListApi = async (page, limit) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/v1/scenario-management/sr_desktops_data?limit=${limit}&page=${page}`
    );
  };

  const getSRThemApi = async (page, limit) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/v1/scenario-management/sr_desktops_data`
    );
  };

  const createUserApi = async (payload) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/v1/user-management/players`,
      payload
    );
  };

  const deleteUserApi = async (userId) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/v1/user-management/players/${userId}`);
  };

  const deleteSRDesktop = async (sr_desktop_data_id) => {
    return axios.delete(
      `${process.env.REACT_APP_API_URL}/v1/scenario-management/sr_desktops_data/${sr_desktop_data_id}`
    );
  };

  const createSRDesktopApi = async (payload) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/v1/scenario-management/sr_desktops_data`,
      payload
    );
  };

  const getChoicesApi = async (questionId, choiceId) => {
    return axios.get(`/questions/${questionId}/choice/${choiceId}`);
  };

  const getApaThemesListApi = async (page, limit) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/v1/scenario-management/apa_themes?page=${page}&limit=${limit}`
    );
  };

  const createApaThemeApi = async (payload) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/v1/scenario-management/apa_themes`, payload
    );
  };

  const getApaThemeApi = async (apaThemeName) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/v1/scenario-management/apa_themes/${apaThemeName}`
    );
  };

  const updateApaThemeApi = async (apaThemeName, payload) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/v1/scenario-management/apa_themes/${apaThemeName}`, payload);
  };

  const updateSrDestopApi = async (apaThemeName, payload) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/v1/scenario-management/sr_desktops_data/${apaThemeName}`, payload);
  };

  const updateApaThemeStatusApi = async (apaThemeId, payload) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/v1/scenario-management/apa_themes/${apaThemeId}`, payload);
  };

  const cloneSRApi = async (scenario_id) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/v1/scenario-management/scenarios/${scenario_id}/clone_sr`);
  };

  const unpublishSRApi = async (scenario_id) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/v1/scenario-management/scenarios/${scenario_id}/unpublish`);
  }

  const getStatsListApi = async (fromDate, toDate, category, responseType) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/v1/scenario-management/assets/scenarioexcel/overall/report?fromDate=${fromDate}&toDate=${toDate}&category=${category}&responsetype=${responseType}`
    );
  };

  const getDownloadStatsListApi = async (fromDate, toDate, category) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/v1/scenario-management/assets/scenarioexcel/overall/report?fromDate=${fromDate}&toDate=${toDate}&category=${category}&responsetype=${""}`, null, {
      responseType: 'blob', // Specify response type as blob
    }
    );
  };

  const getRolesListApi = async () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/v1/user-management/players/roles/list`
    );
  };

  const updateUserRolesApi = async (userId, payload) => {
    return axios.put(
      `${process.env.REACT_APP_API_URL}/v1/user-management/players/${userId}/role`, payload
    );
  };
  
  const getUserRolesApi = async () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/v1/user-management/players/roles`
    );
  };

  return {
    fileUploadApi,
    getScenarioApi,
    flowStructureApi,
    updateFlowStructureApi,
    getFlowStructureApi,
    trackApi,
    getTrackApi,
    updateTrackApi,
    feedbackSlideApi,
    updateFeedbackSlideApi,
    introTrailerApi,
    updateIntroTrailerApi,
    getInjectsApi,
    questionsApi,
    getQuestionsApi,
    updateQuestionsApi,
    sceneApi,
    getSceneApi,
    getIndividualMeetingApi,
    updateMeetingApi,
    updateSceneApi,
    getCategoriesApi,
    getScenarioListApi,
    scenarioApi,
    editScenarioApi,
    getAssetsApi,
    kpiApi,
    getKpiApi,
    updateKpiApi,
    deleteKpiApi,
    deleteSimulationApi,
    getPlayersApi,
    getScenariosOptionsApi,
    createMeetingApi,
    getMeetingsApi,
    getThemesApi,
    getMeetingDetailsApi,
    getSignedUrlApi,
    uploadFileToAzureApi,
    createAssetsApi,
    getUsersListApi,
    getSRListApi,
    getSRThemApi,
    createUserApi,
    deleteUserApi,
    createSRDesktopApi,
    getChoicesApi,
    getApaThemesListApi,
    createApaThemeApi,
    getApaThemeApi,
    updateApaThemeApi,
    updateApaThemeStatusApi,
    cloneSRApi,
    unpublishSRApi,
    updateSrDestopApi,
    deleteSRDesktop,
    getStatsListApi,
    getDownloadStatsListApi,
    getRolesListApi,
    updateUserRolesApi,
    getUserRolesApi
  };
};

export default useRequests;