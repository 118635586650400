import React, { createContext, useState } from "react";

export const GlobalState = createContext(null);

const GlobalContextProvider = (props) => {
  const [Keycloak, setkeycloak] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [scenarioDetails, setScenarioDetails] = useState({})
  const [openEditForm, setOpenEditForm] = useState(false);
  const [openKpi, setOpenKpi] = useState(false);
  const [openApiSnackbar, setOpenApiSnackbar] = useState(false);
  const [apiSnackbarMessage, setApiSnackbarMessage] = useState('');
  const [apiSnackbarSeverity, setApiSnackbarSeverity] = useState('');
  const [nodesDetails, setNodesDetails] = useState({});
  const [isPublished, setIsPublished] = useState(false);
  const [showSystemAssets, setShowSystemAssets] = useState(false);
  const [mode, setMode] = useState('dark');
  const [folder, setFolder] = useState({});
  const [isUpdateFlowStructure, setIsUpdateFlowStructure] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [simulationStatus, setSimulationStatus] = useState('ALL')
  const [playId, setPlayId] = useState("");

  return (
    <GlobalState.Provider
      value={{
        Keycloak,
        setkeycloak,
        setCategoryList,
        categoryList,
        scenarioDetails,
        setScenarioDetails,
        openEditForm,
        setOpenEditForm,
        openKpi,
        setOpenKpi,
        openApiSnackbar,
        setOpenApiSnackbar,
        apiSnackbarMessage,
        setApiSnackbarMessage,
        apiSnackbarSeverity,
        setApiSnackbarSeverity,
        nodesDetails,
        setNodesDetails,
        isPublished,
        setIsPublished,
        showSystemAssets,
        setShowSystemAssets,
        mode,
        setMode,
        setFolder,
        folder,
        isUpdateFlowStructure,
        setIsUpdateFlowStructure,
        isFullscreen,
        setIsFullscreen,
        simulationStatus,
        setSimulationStatus,
        playId,
        setPlayId
      }}
    >
      {props.children}
    </GlobalState.Provider>
  );
};
export default GlobalContextProvider;
