import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import './Simulation.css';
import WorkFlow from './WorkFlow';
import Header from './Header';
import ModalContainer from 'Components/ModalContainer';
import { GlobalState } from 'context/GlobalContext';
import KpiDefinitionForm from './KpiDefinitionForm';
import { SnackbarProvider } from 'notistack';

const WorkFlowArea = () => {
  const { openKpi, setOpenKpi } = useContext(GlobalState);

  const handleKpiClose = () => {
    setOpenKpi(false);
  }

  return (
    <div className='SimulationWorkspace'>
      <Grid container className='simulation-workspace-wrapper'>
        <Header />
        <SnackbarProvider>
          <WorkFlow />
        </SnackbarProvider> 
      </Grid>

      <ModalContainer
        open={openKpi}
        handleClose={handleKpiClose}
        title={'KPI Definition'}
        className='max-width'
      >
        <KpiDefinitionForm />
      </ModalContainer>
    </div>
  );
}

export default WorkFlowArea;

