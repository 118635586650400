/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useContext, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { Field } from "formik";
import PropTypes from "prop-types";
import { Typography, ButtonGroup, Divider, TextField, Grid, Button, IconButton } from "@mui/material";
import "./FileUpload.css";
import { GlobalState } from "context/GlobalContext";
import CssButton from "Components/CssButton";
import SystemFiles from "Components/SystemFiles";
import useApi from "services/api-hook";
import useRequests from "services/request-hook";
import ImagePreview from "./ImagePreview";
import ImageView from "../../Components/ImageView";
import CustomizedDialogs from "../../Components/DialogBox";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CustomSnackbar from "Components/CustomSnackbar";

const CssButtonBox = styled(CssButton)(({ theme }) => ({
  "&.MuiButton-outlined": {
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
    },
    "&.active": {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
    },
  },
}));

const CssUploadIcon = styled(CloudUploadIcon)({
  fontSize: "3rem",
});

const CssDivider = styled(Divider)({
  margin: "14px 0",
  "&:before": {
    borderTopColor: "#F1F1F199",
  },
  "&:after": {
    borderTopColor: "#F1F1F199",
  },
});

const isVimeoUrl = (url) => {
  const vimeoRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com\/)([0-9]{9})(.*)?$/;
  return vimeoRegex.test(url);
}

const DropZoneField = (props) => {
  const {
    id,
    accept,
    disabled,
    maxFiles,
    multiple,
    saveImage,
    optionalImage,
    saveOnDisable,
    isPublished,
    field,
    form,
    selectedFile,
    onDisable,
    bucket,
    onFileUpload,
    handleUpdatedFile,
    simulationForm,
    optionalSaveImage,
    childFunc,
    title,
    handleSpinner,
  } = props;
  const { setShowSystemAssets } = useContext(GlobalState);
  const { getSignedUrlApi, createAssetsApi } = useRequests();
  const getSignedUrl = useApi(getSignedUrlApi);
  const createAssets = useApi(createAssetsApi);
  const [openSystemFiles, setOpenSystemFiles] = useState(false);
  const [isFileFromSystem, setIsFileFromSystem] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [capturedFile, setCapturedFile] = useState(null);
  const [systemFiles, setSystemFiles] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [url, setUrl] = React.useState("");
  const [vmType, setVmType] = React.useState("");
  const [showURL,setShowURL] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showInvalidError, setShowInvalidError] = useState(false);

  const imageNames = [];

  useEffect(() => {
    if (selectedFile) {
      setIsFileFromSystem(true);
    }
    childFunc.current = handleRemoveFile;
  }, []);

  useEffect(() => {
    if (selectedFile) {
      setCapturedFile(selectedFile);
      setImages([selectedFile]);
      handleSaveFile();
    }
    setShowInvalidError(false)
  }, [selectedFile]);

  const handleSaveFile = () => {
    if (optionalImage && !systemFiles) {
      optionalSaveImage(true);
    }
    let fd = new FormData();
    setIsFileFromSystem(true);
    setShowSpinner(true);
    handleSpinner(true);
    fd.append("file", capturedFile);
    getSignedUrl
      .request(bucket || "other")
      .then((res) => {
        if (res.status === 200) {
          let client = res?.data?.client;
          let blobSAS = res?.data?.blobSAS;
          let name = capturedFile?.name.split(".");
          let fileExtension = name?.pop();
          let fileName = `${name[0]}${Date.now()}.${fileExtension}`;
          const finalUrl = `${client}/${fileName}?${blobSAS}`;
          fetch(`${finalUrl}`, {
            method: "PUT",
            body: capturedFile,
            headers: {
              "x-ms-blob-type": "BlockBlob",
              "Content-Type": "multipart/form-data",
            },
          })
            .then((response) => {
              if (response.status === 201 || response.status === 200) {
                let fileUrl = response?.url?.substring(
                  0,
                  response?.url?.indexOf("?")
                );
                var path = fileUrl.split("/");
                fileUrl = process.env.REACT_APP_CDN_URL
                  ? `${process.env.REACT_APP_CDN_URL}/${path[3]}/${path[4]}`
                  : fileUrl;
                setImages([fileUrl]);
                let payload = {
                  fileurl: fileUrl,
                  filename: fileName,
                  type: bucket,
                };
                createAssets
                  .request(bucket, payload)
                  .then((res) => {
                    if (res.status === 200) {
                      console.log("res", res);
                    }
                  })
                  .catch((error) => {
                    console.log("error", error);
                  });
                onFileUpload(response);
                setShowSpinner(false);
                handleSpinner(false);
                if (optionalImage) {
                  optionalSaveImage(false);
                } else {
                  saveImage(true);
                }
              }
            })
            .catch((error) => {
              console.log("error", error);
              setShowSpinner(false);
              handleSpinner(false);
              setIsFileFromSystem(false);
            });
        }
      })
      .catch((error) => {
        console.log("error", error);
        setShowSpinner(false);
        handleSpinner(false);
        setIsFileFromSystem(false);
      });
  };

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    acceptedFiles.map((file) =>
      Object.assign(file, {
        url: URL.createObjectURL(file),
        errors: [],
      })
    );

    let finalData = [...acceptedFiles, ...rejectedFiles];

    const invalidFiles = rejectedFiles.filter((file) => {
      return !accept.includes(file.type);
    });
  
    // if (invalidFiles.length > 0) {
    //   setShowInvalidError(true);
    // } else {
      setCapturedFile(finalData[0]);
      setImages([finalData[0]]);
      form.setFieldValue(field.name, finalData[0]);
    // }
  }, []);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const handleRemoveFile = () => {
    if (!optionalImage) {
      saveImage(false);
    }
    onFileUpload({});
    if (simulationForm === true) {
      handleUpdatedFile(false);
    }
    setCapturedFile(null);
    setIsFileFromSystem(false);
    saveOnDisable(false);
    form.setFieldValue(field.name, null);
    setUrl("");
    setShowURL(false);
  };

  const openImageViewer = useCallback(
    (e) => {
      // setImages([capturedFile]);
      setIsViewerOpen(true);
      e.stopPropagation();
    },
    [capturedFile]
  );

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handleClickOpen = (event, vidMusType) => {
    setVmType(vidMusType);
    setUrl(typeof capturedFile === "string" ? capturedFile : capturedFile.url);
    setOpenDialog(true);
    event.stopPropagation();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onCaptureSystemFile = (image) => {
    setSystemFiles(true);
    onFileUpload(image);
    setIsFileFromSystem(true);
    setShowSystemAssets(false);
    setCapturedFile({ url: image });
    setImages([image]);
    form.setFieldValue(field.name, image);
    if (optionalImage) {
      optionalSaveImage(false);
    } else {
      saveImage(true);
    }
  };

  const onBrowseSystemFiles = () => {
    setOpenSystemFiles(true);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept,
    disabled,
    maxFiles,
    multiple,
    saveImage,
    onDrop,
    noClick: true,
  });

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

  const acceptedFileExtensions = accept && accept["image/*"];
  const hasValidExtension = Array.isArray(acceptedFileExtensions) && acceptedFileExtensions?.some(ext => url.toLowerCase().endsWith(ext));

  const handleUploadUrl = () => {
    console.log("Uploading URL:", url);
    // setCapturedFile(null);
    // setSystemFiles(false);
    const urlExtensionMatch = url.match(/\.([0-9a-zA-Z]+)(?:[?#]|$)/i);
    const urlExtension = urlExtensionMatch ? urlExtensionMatch[1].toLowerCase() : null;  
    // if (!urlExtension || !acceptedFileExtensions?.some(ext => url.toLowerCase().endsWith(ext)) || !Array.isArray(acceptedFileExtensions) || !hasValidExtension) {
    //   setShowInvalidError(true);
    //   return;
    // } 
    if (!isVimeoUrl(url)) {
      setShowInvalidError(true);
      return;
    } 
    else {
      setCapturedFile(null);
      setSystemFiles(false);
      form.setFieldValue(field.name, url);
      if (optionalImage) {
        optionalSaveImage(false);
      } else {
        saveImage(true);
      }
    }
  };

  const handleOpenURL=()=>{
    setShowURL((prevshowURL) => !prevshowURL);
  }

  return (
    <>
      <section id={id}>
        <h6>{title ? title : ""}</h6>
        {capturedFile === null ||
        capturedFile === undefined ||
        (capturedFile.errors && Object.keys(capturedFile.errors).length > 0) ? (
          <div {...getRootProps({ className: "dropZone" })}>
            <input {...getInputProps()} />
            <div className="placeholder-container">
              <CssUploadIcon />
              <Typography className="title">
                Drag 'n' drop file here*
              </Typography>
              <CssDivider className="title">Or</CssDivider>
              <ButtonGroup sx={{ width: "100%" }}>
                <CssButtonBox
                  disabled={isPublished}
                  onClick={onBrowseSystemFiles}
                >
                  System
                </CssButtonBox>
                <CssButtonBox disabled={isPublished} onClick={open}>
                  Browse...
                </CssButtonBox>
                <CssButtonBox className={showURL ? "active" : ""} onClick={handleOpenURL}>
                  URL
                </CssButtonBox>
              </ButtonGroup>
               {showURL && <div style={{ display: "flex", alignItems:"center", marginTop: "8px", gap: '8px', width: '100%' }}>
                  <TextField
                    value={url}
                    placeholder="Enter URL"
                    onChange={handleUrlChange}
                    variant="outlined"
                    margin="dense"
                    style={{ marginLeft: "0", width: '80%', margin: 0}}
                    InputProps={{ style: { height: "40px", width:'100%' } }}
                  />
                  {/* <IconButton style={{ color: "#FFF" }} onClick={handleUploadUrl}>
                    <VisibilityOutlinedIcon style={{ fontSize: "30px" }} />
                    </IconButton> */}
                  <Button style={{ color: 'white', border: '1px solid white', marginBlock: '8px', width: '20%' }} disabled={isPublished} onClick={handleUploadUrl}>
                    Add
                  </Button>
                </div>}
                {showInvalidError &&<div style={{color:'red'}}>{`Invalid File Format`}</div>}
            </div>
          </div>
        ) : (
          <ImagePreview
            imgPreview={capturedFile?.url || capturedFile}
            imgPath={capturedFile?.url || capturedFile}
            fileData={capturedFile}
            isFileFromSystem={isFileFromSystem}
            isSpinnerEnabled={showSpinner}
            onImageRemove={handleRemoveFile}
            onImageView={openImageViewer}
            onPlayVideo={handleClickOpen}
            onMusicVideo={handleClickOpen}
            onImageSave={handleSaveFile}
            accept={accept}
            onDisable={onDisable}
          />
        )}
      </section>
      {/* <CustomSnackbar
        open={openSnackbar}
        message={"Data not accepted"}
        handleClose={handleCloseSnackbar}
      /> */}
      <SystemFiles
        accept={accept}
        open={openSystemFiles}
        setOpenSystemFiles={setOpenSystemFiles}
        onCaptureFile={onCaptureSystemFile}
      />
      {openDialog && url && (
        <CustomizedDialogs
          handleClose={handleCloseDialog}
          open={openDialog}
          url={url}
          title={title}
          type={vmType}
        />
      )}
      {isViewerOpen && (
        <ImageView
          currentImage={currentImage}
          openImageViewer={openImageViewer}
          closeImageViewer={closeImageViewer}
          isViewerOpen={isViewerOpen}
          imageNames={imageNames}
          viewFullScreen={false}
          images={images}
        />
      )}
    </>
  );
};

const FileUpload = (props) => {
  const {
    name,
    id,
    accept,
    disabled,
    maxFiles,
    multiple,
    saveImage,
    optionalSaveImage,
    optionalImage,
    saveOnDisable,
    isPublished,
    onDisable,
    selectedFile,
    bucket,
    onFileUpload,
    handleUpdatedFile,
    simulationForm,
    childFunc,
    title,
    handleSpinner,
  } = props;

  return (
    <Field
      type="file"
      component={DropZoneField}
      name={name}
      id={id}
      accept={accept}
      disabled={disabled}
      maxFiles={maxFiles}
      multiple={multiple}
      saveImage={saveImage}
      optionalSaveImage={optionalSaveImage}
      optionalImage={optionalImage}
      saveOnDisable={saveOnDisable}
      isPublished={isPublished}
      selectedFile={selectedFile}
      onDisable={onDisable}
      bucket={bucket}
      onFileUpload={onFileUpload}
      handleUpdatedFile={handleUpdatedFile}
      simulationForm={simulationForm}
      childFunc={childFunc}
      title={title}
      handleSpinner={handleSpinner}
    />
  );
};

FileUpload.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  accept: PropTypes.object.isRequired,
  multiple: PropTypes.bool.isRequired,
  maxFiles: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

FileUpload.defaultProps = {
  name: "file",
  id: "file",
  disabled: false,
  accept: "",
  multiple: false,
  maxFiles: 1,
};

export default FileUpload;
