import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import './Simulation.css';
import Node from './Node';
import nodeItems from 'data/simulation-nodes.json';
import { GlobalState } from 'context/GlobalContext';
import CssTitle from 'Components/CssTitle';
import CssBox from 'Components/CssBox';

const Builder = () => {
  const { isPublished } = useContext(GlobalState);

  return (
    <CssBox className='SimulationBuilder' >
      <div className='simulation-builder-wrapper'>
        <CssTitle variant='h6' className='node-list-title'>Situation Room Builder</CssTitle>
        <Grid container className='nodes' justifyContent='center'>
          {isPublished ? null : nodeItems.map((item, index) => <Node nodeData={item} key={index} />)}
        </Grid>
      </div>
    </CssBox>
  );
}

export default Builder;
