/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { Formik, Form, FieldArray } from "formik";
import {
  Container,
  Grid,
  Stack,
  Typography,
  IconButton,
  Card,
} from "@mui/material";
import * as Yup from "yup";
import "./Simulation.css";
import { styled } from "@mui/material/styles";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import StaticInfo from "./StaticInfo";
import CssButton from "Components/CssButton";
import useApi from "services/api-hook";
import useRequests from "services/request-hook";
import Menus from "./Menus";
import { GlobalState } from "context/GlobalContext";
import CssTitle from "Components/CssTitle";
import FileUpload from "Components/FileUpload";
import CssCircularProgress from "Components/CssCircularProgress";

const SUPPORTED_VIDEO_FORMATS = ["video/mp4"];

const IntroTrailerFormSchema = Yup.object().shape({
  video: Yup.mixed()
    .nullable()
    .notRequired()
    .test(
      "FILE_FORMAT",
      "Unsupported File Format.",
      (value) =>
        !value || (value && SUPPORTED_VIDEO_FORMATS.includes(value.type))
    ),

  static_info: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .min(3, "minimum length of a text is 3")
        .max(30, "maximum length of a text is 30"),
      value: Yup.string()
        .min(3, "minimum length of a text is 3")
        .max(30, "maximum length of a text is 30"),
    })
  ),
  menus: Yup.array().of(
    Yup.object().shape({
      type: Yup.string()
        .min(3, "minimum length of a text is 3")
        .max(50, "maximum length of a text is 50"),
      name: Yup.string()
        .min(3, "minimum length of a text is 3")
        .max(50, "maximum length of a text is 50"),
    })
  ),
});

const CssAddIconButton = styled(IconButton)({
  position: "absolute",
  top: "33px",
  right: "30px",
});

const CssAddIcon = styled(AddCircleTwoToneIcon)({
  "& .CssAddIcon": {
    color: "#FFF",
    fontSize: "2rem",
  },
});

const CssCard = styled(Card)({
  marginTop: "20px !important",
  padding: "6px 20px 20px",
  display: "block !important",
  backgroundColor: "transparent",
  border: "1px solid #673068",
  textAlign: "center",
});

const IntroTrailerForm = ({ handleResponse, node }) => {
  const { isPublished } = useContext(GlobalState);
  const { introTrailerApi, getInjectsApi, updateIntroTrailerApi } =
    useRequests();
  const introTrailer = useApi(introTrailerApi);
  const injectDetails = useApi(getInjectsApi);
  const updateIntroTrailer = useApi(updateIntroTrailerApi);
  const [fileUrl, setFileUrl] = useState("");
  const [callUpdate, setCallUpdate] = useState(false);
  const childFunc = React.useRef(null);
  const [saveImg, setSaveImg] = useState(node?.elementId ? true : false);
  const [onDisable, setOnDisable] = useState(node?.elementId ? true : false);
  const [btnDisable, setBtnDisable] = React.useState(false);
  const [initialValues, setInitialValues] = useState({
    video: null,
    static_info: [],
    menus: [],
  });

  useEffect(() => {
    if (node && node.elementId) {
      injectDetails
        .request(node.elementId)
        .then((res) => {
          if (res.status === 200) {
            setInitialValues({ ...res?.data });
            setCallUpdate(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const saveOnDisable = (value) => {
    setOnDisable(value);
  };

  const saveImage = (value) => {
    setSaveImg(value);
  };

  const handleSpinner = (value) => {
    setBtnDisable(value);
  };

  const onFileUpload = ({ url }) => {
    if (url) {
      let finalUrl = url?.substring(0, url?.indexOf("?"));
      var path = finalUrl.split("/");
      finalUrl = process.env.REACT_APP_CDN_URL
        ? `${process.env.REACT_APP_CDN_URL}/${path[3]}/${path[4]}`
        : finalUrl;
      setFileUrl(finalUrl);
    } else {
      setFileUrl("");
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={IntroTrailerFormSchema}
      validateOnMount={true}
      isInitialValid={false}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const payload = values?.menus.map(item => {
          if (item?.name === "Custom Name") {
            const name = item?.customName;
            delete item?.customName;
            return {
              ...item,
              name
            }
          }
          return item;
        })
        values.menus = payload;
        values.video = fileUrl ? fileUrl : values.video;
        setSubmitting(true);
        if (callUpdate) {
          updateIntroTrailer.request(node.elementId, values).then((res) => {
            handleResponse(res);
            setSubmitting(false);
          });
        } else {
          introTrailer.request(values).then((res) => {
            handleResponse(res);
            setSubmitting(false);
          });
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        isValid,
        setFieldValue,
        isSubmitting,
        dirty,
        handleBlur,
      }) => (
        <Form noValidate={true} autoComplete="off" className="form-wrapper">
          {typeof values?.video === "string" ? delete errors.video : ""}
          <Container className="container">
            <Grid container className="form-wrapper" direction="row">
              <Grid item xs={8}>
                <div style={{ width: "870px", paddingTop: "5px" }}>
                  <FileUpload
                    name="video"
                    id="video"
                    multiple={false}
                    onDisable={onDisable}
                    saveOnDisable={saveOnDisable}
                    selectedFile={values?.video}
                    saveImage={saveImage}
                    onFileUpload={onFileUpload}
                    accept={{ "video/*": [".mp4"] }}
                    childFunc={childFunc}
                    bucket={"axontrailers"}
                    isValid={false}
                    title="Video*"
                    handleSpinner={handleSpinner}
                  />
                  {errors.video && errors.video ? (
                    <div
                      className="errorText"
                      style={{ top: 0, position: "relative" }}
                    >
                      {errors.video}
                    </div>
                  ) : null}
                </div>
              </Grid>
            </Grid>
            <Grid container className="form-wrapper" direction="row">
              <FieldArray name="static_info">
                {({ insert, remove, push }) => (
                  <>
                    <Grid item xs={12} className="field-array-title-wrapper">
                      <Typography variant="h5" className="field-array-title">
                        Static Info
                      </Typography>
                      {values?.static_info?.length < 6 &&
                        (isPublished ? null : (
                          <CssAddIconButton
                            onClick={() => push({ name: "", value: "" })}
                          >
                            <CssAddIcon />
                          </CssAddIconButton>
                        ))}
                    </Grid>
                    {values.static_info.length === 0 ? (
                      <Grid
                        container
                        component={CssCard}
                        className="card-wrapper"
                      >
                        <CssTitle
                          variant="h6"
                          sx={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            !isPublished && push({ name: "", value: "" })
                          }
                        >
                          Add Static Info
                        </CssTitle>
                      </Grid>
                    ) : (
                      values?.static_info?.map((item, index) => (
                        <StaticInfo
                          key={index}
                          index={index}
                          errors={errors}
                          touched={touched}
                          onRemove={remove}
                          staticInfos={item}
                          enableClose={values.static_info.length > 0}
                          onSetFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          isPublished={isPublished}
                        />
                      ))
                    )}
                  </>
                )}
              </FieldArray>
            </Grid>
            <Grid
              container
              className="form-wrapper menus-form-wrapper"
              direction="row"
            >
              <FieldArray name="menus">
                {({ insert, remove, push }) => (
                  <>
                    <Grid item xs={12} className="field-array-title-wrapper">
                      <Typography variant="h5" className="field-array-title">
                        Menus
                      </Typography>
                      {isPublished ? null : (
                        <CssAddIconButton
                          onClick={() =>
                            push({
                              type: "",
                              name: "",
                              menu_items: [
                                {
                                  label: "",
                                  content_type: "2D",
                                  content: "",
                                },
                              ],
                            })
                          }
                        >
                          <CssAddIcon />
                        </CssAddIconButton>
                      )}
                    </Grid>
                    {values?.menus?.length === 0 ? (
                      <Grid
                        container
                        component={CssCard}
                        className="card-wrapper"
                      >
                        <CssTitle
                          variant="h6"
                          sx={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            !isPublished &&
                            push({
                              type: "",
                              name: "",
                              menu_items: [
                                {
                                  label: "",
                                  content_type: "2D",
                                  content: "",
                                },
                              ],
                            })
                          }
                        >
                          Add Menus
                        </CssTitle>
                      </Grid>
                    ) : (
                      values.menus.map((item, index) => (
                        <Menus
                          key={index}
                          values={values}
                          index={index}
                          errors={errors}
                          touched={touched}
                          onRemove={remove}
                          menus={item}
                          enableClose={values.menus.length > 0}
                          onSetFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                        />
                      ))
                    )}
                  </>
                )}
              </FieldArray>
            </Grid>
          </Container>
          <Grid className="button-stack form-btn-wrapper">
            <Stack spacing={2} direction="row">
              {node?.elementId ? (
                <CssButton
                  variant="contained"
                  size="medium"
                  type="submit"
                  disabled={
                    // !isValid ||
                    isSubmitting ||
                    isPublished ||
                    !saveImg ||
                    !dirty ||
                    values.video === null ||
                    btnDisable
                  }
                >
                  {isSubmitting ? <CssCircularProgress size={20} /> : "Update"}
                </CssButton>
              ) : (
                <CssButton
                  variant="contained"
                  size="medium"
                  type="submit"
                  disabled={
                    !isValid ||
                    isSubmitting ||
                    isPublished ||
                    !saveImg ||
                    btnDisable
                  }
                >
                  {isSubmitting ? <CssCircularProgress size={20} /> : "Save"}
                </CssButton>
              )}
              {node && node.elementId ? null : (
                <CssButton
                  type="reset"
                  variant="contained"
                  size="medium"
                  disabled={!dirty}
                  onClick={() => {
                    childFunc.current();
                  }}
                  sx={{ backgroundColor: "#673068" }}
                >
                  Reset
                </CssButton>
              )}
            </Stack>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default IntroTrailerForm;
