import React, { useState, useContext, useEffect } from "react";
import { Formik, Form, FastField } from "formik";
import * as Yup from "yup";
import CssTextField from "Components/CssTextField";
import CssSelectField from "Components/CssSelectField";
import CssButton from "Components/CssButton";
import FileUpload from "Components/FileUpload";
import TagsInput from "Components/TagsInput";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  FormControl,
  MenuItem,
  InputLabel,
  Stack,
  Container,
  Button,
} from "@mui/material";
import useRequests from "services/request-hook";
import ModalContainer from "Components/ModalContainer";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import useApi from "services/api-hook";
import { GlobalState } from "../../context/GlobalContext";
import CssCircularProgress from "Components/CssCircularProgress";

const typeOptions = [
  { value: "2D", label: "2D" },
  { value: "3D", label: "3D" },
];

const statusOptions = [
  { value: "DRAFT", label: "Draft" },
  { value: "PUBLISHED", label: "Published" },
  { value: "ARCHIVED", label: "Archived" },
];
const SUPPORTED_AUDIO_FORMATS = ["audio/mp3", "audio/ogg", "audio/mpeg"];
const SUPPORTED_VIDEO_FORMATS = ["video/mp4"];
const SUPPORTED_FORMATS_IMAGE = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, "minimum length of a text is 3")
    .max(60, "maximum length of a text is 60")
    .matches(
      /^[A-Za-z]{2}[ A-Za-z0-9_-]*$/,
      "The Title must be alphanumeric characters only"
    )
    .required("Title is required"),
  type: Yup.string().required("Type is required"),
  category: Yup.string().required("Category is required"),
  status: Yup.string().required("Status is required"),
  track_selection: Yup.string().required("Track Selection is required"),
  isPublic: Yup.string().required("SR Type is required, Select Public/Private"),
  files: Yup.mixed().nullable()
    .notRequired().test('fileType', "Unsupported File Format", value => value === null || (SUPPORTED_FORMATS_IMAGE.includes(value?.type))),
  bg_img: Yup.mixed().nullable()
    .notRequired().test('fileType', "Unsupported File Format", value => value === null || (SUPPORTED_FORMATS_IMAGE.includes(value?.type))),
  trailer: Yup.mixed().nullable()
    .notRequired().test("FILE_FORMAT", "Unsupported File Format.",
      value => !value || (value && SUPPORTED_VIDEO_FORMATS.includes(value.type))),
  bgmusic: Yup.mixed().nullable()
    .notRequired().test("FILE_FORMAT", "Unsupported File Format.",
      value => !value || (value && SUPPORTED_AUDIO_FORMATS.includes(value.type)))
});
const SimulationForm = (props) => {
  const { category, sid } = useParams();
  const { scenarioApi, editScenarioApi, getThemesApi } = useRequests();
  const addScenario = useApi(scenarioApi);
  const getThemesList = useApi(getThemesApi);
  const editScenario = useApi(editScenarioApi);
  const {
    initialValues,
    edit,
    saveEditImg,
    scenarioDetails,
    setOpenEditForm,
    setOpenModal,
    setSnackBar,
    getScenariosList,
    onFileUpload,
    onImageUpload,
    onVideoUpload,
    onAudioUpload,
    fileUrl,
    file1Url,
    file2Url,
    file3Url,
    setFile2Url,
    setFile3Url,
    setFile1Url,
    setFileUrl,
    handleAfterSubmit,
  } = props;
  const selectedItem = JSON.parse(localStorage.getItem("selectedCategory"));
  const navigate = useNavigate();
  const optionalImage = true;
  const {
    isPublished,
    setOpenApiSnackbar,
    setApiSnackbarMessage,
    setApiSnackbarSeverity,
    simulationStatus,
  } = useContext(GlobalState);

  const childFunc = React.useRef(null);
  const childFunc1 = React.useRef(null);
  const childFunc2 = React.useRef(null);
  const childFunc3 = React.useRef(null);

  const [clickedCustom, setClickedCustom] = useState(
    !edit ? false : scenarioDetails?.theme_name === "base" ? false : true
  );
  const [clickContinue, setClickContinue] = useState("");
  const [saveImg, setSaveImg] = useState(saveEditImg ? saveEditImg : false);
  const [saveBgImg, setSaveBgImg] = useState(saveEditImg ? saveEditImg : false);
  const [optionalImageSave, setOptionalImageSave] = useState(false);
  const [saveAudio, setSaveAudio] = useState(saveEditImg ? saveEditImg : false);
  const [saveVideo, setSaveVideo] = useState(saveEditImg ? saveEditImg : false);
  const [openModalAgian, setOpenModalAgian] = useState(false);
  const [nameTheme, setNameTheme] = React.useState("");
  const [tags, setTags] = React.useState("");
  const [btnDisable, setBtnDisable] = React.useState(false);

  const [handleUpdateImage, setHandleUpdateImage] = useState(
    saveEditImg ? saveEditImg : false
  );
  const [handleUpdateBgImage, setHandleUpdateBgImage] = useState(
    saveEditImg ? saveEditImg : false
  );
  const [handleUpdateTrailer, setHandleUpdateTrailer] = useState(
    saveEditImg ? saveEditImg : false
  );
  const [handleUpdateBGMusic, setHandleUpdateBGMusic] = useState(
    saveEditImg ? saveEditImg : false
  );
  const [themeList, setThemeList] = useState([]);
  const [theme, setTheme] = React.useState(
    !edit ? "base" : scenarioDetails?.theme_name === "base" ? "base" : "custom"
  );
  const [themeValue, setThemeValue] = useState(
    edit ? scenarioDetails?.theme_name : "base"
  );

  const dental = [
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
    "11th",
    "12th",
  ];
  const cyber = [
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
  ];
  const medical = [];
  //   const [onDisable, setOnDisable] = useState(edit ? true : false)

  const saveImage = (value) => {
    setSaveImg(value);
  };

  const saveBgImage = (value) => {
    setSaveBgImg(value);
  };

  const saveVideoFile = (value) => {
    setSaveVideo(value);
  };

  const optionalSaveImage = (value) => {
    setOptionalImageSave(value);
  };
  useEffect(() => {
    setOptionalImageSave(false);
    fetchThemesList();
    setFile3Url(scenarioDetails?.bg_music_url);
  }, []);
  const saveOnDisable = (value) => {
    // setOnDisable(value);
  };
  const handleEdit = (payload) => {
    editScenario
      .request(sid, payload)
      .then((res) => {
        if (res.status === 200) {
          setOpenEditForm(false);
          setSnackBar();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchThemesList = () => {
    getThemesList
      .request(1, 100)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            setThemeList([...res.data]);
          } else {
            setThemeList([]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUpdatedFile = (value) => {
    setHandleUpdateImage(value);
  };
  const handleUpdatedBgImage = (value) => {
    setHandleUpdateBgImage(value);
  };
  const handleUpdatedTrailer = (value) => {
    setHandleUpdateTrailer(value);
  };
  const handleUpdatedBGMusic = (value) => {
    setHandleUpdateBGMusic(value);
  };

  const radioHandler = (value, themevalue) => {
    setTheme(themevalue);
    setClickedCustom(value);
    if (value == false && themevalue == "base") {
      setThemeValue(themevalue);
    }
  };

  const handleOpenDrawer = (themeName) => {
    setNameTheme(themeName);
    setOpenModalAgian(true);
  };
  const handleCloseDrawerAgain = () => {
    setOpenModalAgian(false);
  };

  const handleSpinner = (value) => {
    setBtnDisable(value);
  }

  function handleSelecetedTags(items) {
    setTags(items);
  }

  return (
    <>
      <Formik
        initialValues={{ ...initialValues }}
        enableReinitialize
        validateOnMount={true}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          let payload = {
            title: values.title,
            banner: {
              url:
                fileUrl || values?.files || scenarioDetails?.banner_url || "",
            },
            bg_img: {
              url:
                file1Url || values?.bg_img || scenarioDetails?.bg_img_url || "",
            },
            trailer: {
              url: file2Url || values.trailer || "",
            },
            bg_music: {
              url: values?.bgmusic || file3Url || "",
            },
            type: values.type,
            tags: tags,
            category_id: values.category,
            status: values.status,
            theme_name: values.theme_name,
            track_selection: values?.track_selection === "True" ? true : false,
            isPublic: values?.isPublic === "True" ? true : false,
            playback: values?.playback === "True" ? true : false,
            randomizer: values?.randomizer === "True" ? true : false,
          };
          setSubmitting(false);
          if (edit === true) {
            handleEdit(payload);
            setSubmitting(false);
          } else {
            addScenario
              .request(payload)
              .then((res) => {
                if (res.status === 200) {
                  if (clickContinue === "continue") {
                    localStorage.setItem(
                      "simulationItem",
                      JSON.stringify(res.data)
                    );
                    navigate(`/${category}/simulation/${res.data._id}`);
                  }
                  handleAfterSubmit();
                  setSubmitting(false);
                  setOpenModal(false);
                  setFileUrl("");
                  setFile1Url("");
                  setFile2Url("");
                  setFile3Url("");
                  getScenariosList(1, simulationStatus);
                  setSnackBar();
                  setClickContinue("save");
                  if (clickContinue === "save") {
                    setOpenApiSnackbar(true);
                    setApiSnackbarMessage("Data Saved Successfully");
                    setApiSnackbarSeverity("success");
                  }
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }}
      >
        {({
          dirty,
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          resetForm,
          submitForm,
          isSubmitting,
          handleBlur,
        }) => (
          <Form onSubmit={handleSubmit}>
            {(typeof (values?.files) === 'string') ? delete errors.files : ''}
            {(typeof (values?.bg_img) === 'string') ? delete errors.bg_img : ''}
            {(typeof (values?.trailer) === 'string') ? delete errors.trailer : ''}
            {(typeof (values?.bgmusic) === 'string') ? delete errors.bgmusic : ''}
            <Container sx={{ marginTop: "10px" }}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <div className="form_control">
                    <FastField
                      component={CssTextField}
                      variant="outlined"
                      size="small"
                      name="title"
                      id="title"
                      label="Title*"
                      autoComplete="off"
                      value={values.title}
                      onBlur={(e) => handleBlur(e)}
                      // disabled={isPublished}
                      inputProps={{ readOnly: isPublished }}
                      onChange={(e) => {
                        setFieldValue("title", e.target.value);
                      }}
                      sx={{ marginTop: "0px" }}
                    />
                    {errors.title && touched.title ? (
                      <div className="errorText">{errors.title}</div>
                    ) : null}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="form_control">
                    <FormControl fullWidth size="small">
                      <InputLabel id="type" className="select-label">
                        Type*
                      </InputLabel>
                      <FastField
                        component={CssSelectField}
                        labelId="type"
                        variant="outlined"
                        size="small"
                        name="type"
                        label="Type*"
                        id="type"
                        onBlur={(e) => handleBlur(e)}
                        value={values.type}
                        inputProps={{ readOnly: isPublished }}
                        onChange={(e) => {
                          setFieldValue("type", e.target.value);
                        }}
                      >
                        {typeOptions.map((item, i) => (
                          <MenuItem key={item.label} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </FastField>
                    </FormControl>
                    {errors.type && touched.type ? (
                      <div className="errorText">{errors.type}</div>
                    ) : null}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="form_control">
                    <FormControl fullWidth size="small">
                      <InputLabel
                        id="category"
                        className="select-label"
                      ></InputLabel>
                      <FastField
                        component={CssTextField}
                        variant="outlined"
                        size="small"
                        labelId="category"
                        id="category"
                        name="category"
                        label="Category"
                        value={selectedItem.name}
                        onBlur={(e) => handleBlur(e)}
                        inputProps={{ readOnly: true }}
                        onChange={(e) => {
                          setFieldValue("category", e.target.value);
                        }}
                        sx={{ marginTop: "0px" }}
                      ></FastField>
                    </FormControl>
                    {errors.category && touched.category ? (
                      <div className="errorText">{errors.category}</div>
                    ) : null}
                  </div>
                </Grid>
                {edit ? (
                  <Grid item xs={12}>
                    <div className="form_control">
                      <FormControl fullWidth size="small">
                        <InputLabel id="status" className="select-label">
                          Status*
                        </InputLabel>
                        <FastField
                          component={CssSelectField}
                          labelId="status"
                          variant="outlined"
                          name="status"
                          label="Status*"
                          id="status"
                          onBlur={(e) => handleBlur(e)}
                          value={values.status}
                          inputProps={{ readOnly: isPublished }}
                          onChange={(e) => {
                            setFieldValue("status", e.target.value);
                          }}
                        >
                          {statusOptions &&
                            statusOptions.map((item, i) => (
                              <MenuItem key={item.label} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                        </FastField>
                      </FormControl>
                      {errors.status && touched.status ? (
                        <div className="errorText">{errors.status}</div>
                      ) : null}
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <div className="form_control">
                      <FormControl fullWidth size="small">
                        <InputLabel
                          id="status"
                          className="select-label"
                        ></InputLabel>
                        <FastField
                          component={CssTextField}
                          labelId="status"
                          variant="outlined"
                          size="small"
                          name="status"
                          label="Status"
                          id="status"
                          onBlur={(e) => handleBlur(e)}
                          value={`Draft`}
                          inputProps={{ readOnly: true }}
                          onChange={(e) => {
                            setFieldValue("status", e.target.value);
                          }}
                          sx={{ marginTop: "0px" }}
                        ></FastField>
                      </FormControl>
                      {errors.status && touched.status ? (
                        <div className="errorText">{errors.status}</div>
                      ) : null}
                    </div>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <div className="form_control">
                    <TagsInput
                      selectedTags={handleSelecetedTags}
                      fullWidth
                      variant="outlined"
                      id="tags"
                      name="tags"
                      onBlur={(e) => handleBlur(e)}
                      tagsValue={values.tags}
                      setFieldValue={setFieldValue}
                      // placeholder="Add Tags"
                      label="Tags"
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="form_control">
                    <FormControl fullWidth size="small">
                      <InputLabel id="type" className="select-label">
                        Track Selection*
                      </InputLabel>
                      <FastField
                        component={CssSelectField}
                        labelId="track_selection"
                        variant="outlined"
                        size="small"
                        name="track_selection"
                        label="Track Selection*"
                        id="track_selection"
                        onBlur={(e) => handleBlur(e)}
                        value={values.track_selection}
                        inputProps={{ readOnly: isPublished }}
                        onChange={(e) => {
                          setFieldValue("track_selection", e.target.value);
                        }}
                      >
                        {["True", "False"].map((item, i) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </FastField>
                    </FormControl>
                    {errors?.track_selection && touched?.track_selection ? (
                      <div className="errorText">{errors?.track_selection}</div>
                    ) : null}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="form_control">
                    <FormControl fullWidth size="small">
                      <InputLabel id="type" className="select-label">
                        Public SR*
                      </InputLabel>
                      <FastField
                        component={CssSelectField}
                        labelId="isPublic"
                        variant="outlined"
                        size="small"
                        name="isPublic"
                        label="SR Type*"
                        id="isPublic"
                        onBlur={(e) => handleBlur(e)}
                        value={values.isPublic}
                        inputProps={{ readOnly: isPublished }}
                        onChange={(e) => {
                          setFieldValue("isPublic", e.target.value);
                        }}
                      >
                        {["True", "False"].map((item, i) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </FastField>
                    </FormControl>
                    {errors?.isPublic && touched?.isPublic ? (
                      <div className="errorText">{errors?.isPublic}</div>
                    ) : null}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="form_control">
                    <FormControl fullWidth size="small">
                      <InputLabel id="type" className="select-label">
                        Playback*
                      </InputLabel>
                      <FastField
                        component={CssSelectField}
                        labelId="playback"
                        variant="outlined"
                        size="small"
                        name="playback"
                        label="Playback"
                        id="playback"
                        onBlur={(e) => handleBlur(e)}
                        value={values.playback}
                        inputProps={{ readOnly: isPublished }}
                        onChange={(e) => {
                          setFieldValue("playback", e.target.value);
                        }}
                      >
                        {["True", "False"].map((item, i) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </FastField>
                    </FormControl>
                    {errors?.playback && touched?.playback ? (
                      <div className="errorText">{errors?.playback}</div>
                    ) : null}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="form_control">
                    <FormControl fullWidth size="small">
                      <InputLabel id="type" className="select-label">
                        Randomizer*
                      </InputLabel>
                      <FastField
                        component={CssSelectField}
                        labelId="randomizer"
                        variant="outlined"
                        size="small"
                        name="randomizer"
                        label="randomizer"
                        id="randomizer"
                        onBlur={(e) => handleBlur(e)}
                        value={values.randomizer}
                        inputProps={{ readOnly: isPublished }}
                        onChange={(e) => {
                          setFieldValue("randomizer", e.target.value);
                        }}
                      >
                        {["True", "False"].map((item, i) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </FastField>
                    </FormControl>
                    {errors?.randomizer && touched?.randomizer ? (
                      <div className="errorText">{errors?.randomizer}</div>
                    ) : null}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="form_control">
                    <FileUpload
                      name="files"
                      id="files"
                      multiple={false}
                      selectedFile={
                        handleUpdateImage
                          ? scenarioDetails?.banner_url
                          : values?.files
                      }
                      onFileUpload={onFileUpload}
                      saveImage={saveImage}
                      handleUpdatedFile={handleUpdatedFile}
                      simulationForm={true}
                      optionalImage={!optionalImage}
                      accept={{ "image/*": [".jpeg", ".png", ".jpg"] }}
                      saveOnDisable={saveOnDisable}
                      childFunc={childFunc1}
                      bucket={"scenario-banners"}
                      title="Poster*"
                      handleSpinner={handleSpinner}
                    />
                    {errors.files && errors.files ? (
                      <div className="errorText" style={{ top: 0, position: 'relative' }}>
                        {errors.files}
                      </div>
                    ) : null}
                  </div>
                  <div className="form_control" style={{ paddingTop: "25px" }}>
                    <FileUpload
                      name="bg_img"
                      id="bg_img"
                      multiple={false}
                      selectedFile={
                        handleUpdateBgImage
                          ? scenarioDetails?.bg_img
                          : values?.bg_img
                      }
                      onFileUpload={onImageUpload}
                      saveImage={saveBgImage}
                      handleUpdatedFile={handleUpdatedBgImage}
                      simulationForm={true}
                      optionalImage={!optionalImage}
                      accept={{ "image/*": [".jpeg", ".png", ".jpg"] }}
                      saveOnDisable={saveOnDisable}
                      childFunc={childFunc3}
                      bucket={"scenario-banners"}
                      title="Background Image"
                      handleSpinner={handleSpinner}
                    />
                    {errors.bg_img && errors.bg_img ? (
                      <div className="errorText" style={{ top: 0, position: 'relative' }}>
                        {errors.bg_img}
                      </div>
                    ) : null}
                  </div>
                  <div className="form_control" style={{ paddingTop: "25px" }}>
                    <FileUpload
                      name="trailer"
                      id="trailer"
                      multiple={false}
                      selectedFile={
                        handleUpdateTrailer
                          ? scenarioDetails?.trailer?.url
                          : values?.trailer
                      }
                      onFileUpload={onVideoUpload}
                      handleUpdatedFile={handleUpdatedTrailer}
                      saveImage={saveVideoFile}
                      optionalImage={!optionalImage}
                      simulationForm={true}
                      accept={{ "video/*": [".mp4"] }}
                      saveOnDisable={saveOnDisable}
                      childFunc={childFunc}
                      isPublished={isPublished}
                      bucket={"axontrailers"}
                      title="Open Credit Video"
                      handleSpinner={handleSpinner}
                    />
                    {errors.trailer && errors.trailer ? (
                      <div className="errorText" style={{ top: 0, position: 'relative' }}>
                        {errors.trailer}
                      </div>
                    ) : null}
                  </div>

                  <div className="form_control" style={{ paddingTop: "25px" }}>
                    <FileUpload
                      name="bgmusic"
                      id="bgmusic"
                      multiple={false}
                      selectedFile={
                        handleUpdateBGMusic
                          ? scenarioDetails?.bg_music?.url
                          : values?.bgmusic
                      }
                      onFileUpload={onAudioUpload}
                      saveImage={saveImage}
                      simulationForm={true}
                      optionalSaveImage={optionalSaveImage}
                      optionalImage={optionalImage}
                      handleUpdatedFile={handleUpdatedBGMusic}
                      accept={{ "audio/*": [".mp3", ".ogg"] }}
                      isPublished={isPublished}
                      saveOnDisable={saveOnDisable}
                      childFunc={childFunc2}
                      bucket={"bg-music"}
                      title="Background Music"
                      handleSpinner={handleSpinner}
                    />
                    {errors.bgmusic && errors.bgmusic ? (
                      <div className="errorText" style={{ top: 0, position: 'relative' }}>
                        {errors.bgmusic}
                      </div>
                    ) : null}
                  </div>

                  <Grid item xs={12}>
                    <div className="form_control">
                      <FormControl style={{ marginTop: "30px" }}>
                        <RadioGroup
                          row
                          name="theme-buttons-group"
                          onChange={(e) => {
                            if (!isPublished) {
                              if (e.target.value === "base") {
                                setFieldValue("theme_name", e.target.value);
                                radioHandler(false, e.target.value);
                              }
                              if (e.target.value === "custom") {
                                radioHandler(true, e.target.value);
                                setThemeValue("");
                              }
                            }
                          }}
                        >
                          <FormControlLabel
                            value="base"
                            control={<Radio />}
                            checked={theme === "base"}
                            label="Base Theme"
                          />
                          <FormControlLabel
                            value="custom"
                            control={<Radio />}
                            checked={theme === "custom"}
                            label="Custom Theme"
                          />
                        </RadioGroup>
                      </FormControl>
                      {clickedCustom && themeList && themeList.length > 0 && (
                        <div style={{ display: "flex" }}>
                          <FormControl
                            style={{ width: "180px", marginTop: "10px" }}
                            size="small"
                          >
                            <InputLabel id="type" className="select-label">
                              Theme*
                            </InputLabel>
                            <FastField
                              component={CssSelectField}
                              labelId="theme_name"
                              variant="outlined"
                              size="small"
                              name="theme_name"
                              label="Theme*"
                              id="theme_name"
                              onBlur={handleBlur}
                              value={themeValue || values.theme_name}
                              // defaultValue="dental"
                              inputProps={{ readOnly: isPublished }}
                              onChange={(e) => {
                                if (!isPublished) {
                                  setFieldValue("theme_name", e.target.value);
                                  setThemeValue(e.target.value);
                                }
                              }}
                            >
                              {themeList[0].docs?.map((item, i) => (
                                <MenuItem
                                  selected={i === 1}
                                  key={item.name}
                                  value={item.name}
                                >
                                  {item.name}
                                </MenuItem>
                              ))}
                            </FastField>
                          </FormControl>
                          <Grid item xs={6} ml={3} mt={1}>
                            <Stack
                              spacing={2}
                              direction="row"
                              justifyContent={"flex-start"}
                            >
                              <Button
                                variant="contained"
                                sx={{ width: "max-content !important" }}
                                onClick={() =>
                                  handleOpenDrawer(values.theme_name)
                                }
                              >
                                Preview
                              </Button>
                            </Stack>
                          </Grid>
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    spacing={2}
                    direction="row"
                    justifyContent={"flex-start"}
                  >
                    {edit ? null : (
                      <CssButton
                        variant="contained"
                        sx={{ width: "max-content !important" }}
                        onClick={() => {
                          setClickContinue("continue");
                          submitForm();
                        }}
                        disabled={
                          isSubmitting ||
                          !saveImg ||
                          optionalImageSave ||
                          !themeValue
                        }
                      >
                        {isSubmitting ? <CssCircularProgress size={20} /> : "Continue"}
                      </CssButton>
                    )}

                    {edit ? (
                      <CssButton
                        variant="contained"
                        sx={{ width: "max-content !important" }}
                        onClick={() => {
                          submitForm();
                        }}
                        disabled={
                          isSubmitting ||
                          isPublished ||
                          !dirty ||
                          !saveImg ||
                          optionalImageSave ||
                          !themeValue ||
                          btnDisable
                        }
                      >
                        {isSubmitting ? <CssCircularProgress size={20} /> : "Update"}
                      </CssButton>
                    ) : (
                      <CssButton
                        variant="contained"
                        sx={{ width: "max-content !important" }}
                        onClick={() => {
                          setClickContinue("save");
                          submitForm();
                        }}
                        disabled={
                          isSubmitting ||
                          isPublished ||
                          !saveImg ||
                          optionalImageSave ||
                          !themeValue ||
                          btnDisable
                        }
                      >
                        {isSubmitting ? <CssCircularProgress size={20} /> : "Save"}
                      </CssButton>
                    )}
                    {edit ? null : (
                      <CssButton
                        variant="contained"
                        type="reset"
                        sx={{
                          width: "max-content !important",
                          backgroundColor: "#673068",
                        }}
                        onClick={() => {
                          childFunc.current();
                          childFunc1.current();
                          childFunc2.current();
                          childFunc3.current();
                          resetForm();
                          setTheme("base");
                          setClickedCustom(false);
                        }}
                        disabled={!dirty}
                      >
                        Reset
                      </CssButton>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Container>
            <ModalContainer
              open={openModalAgian}
              handleClose={handleCloseDrawerAgain}
              title={`${nameTheme} preview `}
            >
              {nameTheme === "dental" &&
                dental.map((item, i) => (
                  <img
                    style={{
                      width: "100%",
                      height: "45%",
                      borderRadius: "4px",
                      marginTop: "4px",
                      border: "2px solid",
                    }}
                    src={`https://axonteststorage.blob.core.windows.net/themes/theme-preview/${nameTheme}/${nameTheme}/${item}.png`}
                    alt={`${item}.png`}
                  />
                ))}
              {nameTheme === "cyber" &&
                cyber.map((item, i) => (
                  <img
                    style={{
                      width: "100%",
                      height: "45%",
                      borderRadius: "4px",
                      marginTop: "4px",
                      border: "2px solid",
                    }}
                    src={`https://axonteststorage.blob.core.windows.net/themes/theme-preview/${nameTheme}/${nameTheme}/${item}.png`}
                    alt={`${item}.png`}
                  />
                ))}
              {nameTheme === "medical" && (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                  }}
                >
                  <h4
                    style={{
                      position: "absolute",
                      left: "50%",
                      transform: "translateX(-50%)",
                      lineHeight: "500px",
                    }}
                  >
                    No Preview
                  </h4>
                </div>
              )}
            </ModalContainer>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default SimulationForm;
