import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  FormControl,
  MenuItem,
  InputLabel,
  DialogTitle,
  DialogContent,
  TextField,
} from "@mui/material";
import CssTitle from "Components/CssTitle";
import FilterListIcon from "@mui/icons-material/FilterList";
import useApi from "services/api-hook";
import useRequests from "services/request-hook";
import * as Yup from "yup";
import { Formik, FastField, Form } from "formik";
import RunStatsTable from "./RunStatsList";
import CssSelectField from "Components/CssSelectField";
import CssDateTimePicker from "Components/CssDateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import CssButton from "Components/CssButton";
import moment from "moment";
import axios from "axios";
import Auth from "../../services/auth";
import CssDialogBox from "Components/CssDialogBox";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";

const CssDialogBoxes = styled(CssDialogBox)(({ theme }) => ({
  "& .MuiPaper-root": {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "& .MuiOutlinedInput-input": {
      color: theme.palette.primary.contrastText,
    },
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.primary.contrastText,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.contrastText,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.contrastText,
    },
  },
  "& label": {
    color: theme.palette.primary.contrastText,
    "&.Mui-focused": {
      color: theme.palette.primary.contrastText,
    },
  },
}));

const periodList = [
  { label: "Custom", value: "Custom" },
  { label: "This Week", value: "Week" },
  { label: `This Month (${moment().format('MMM - YYYY')})`, value: "Month" },
  { label: `Last Month (${moment().subtract(1, "month").format('MMM - YYYY')})`, value: "LastMonth" },
  { label: "Last 3 Months", value: "lastThree" },
  { label: "Last 6 Months", value: "lastSix" },
  { label: "Last 12 Months", value: "lastTwelve" },
  { label: `Current year (${moment().format('YYYY')})`, value: "currentYear" },
  { label: `Last year (${moment().subtract(1, "year").format('YYYY')})`, value: "lastYear" },
];
const RunStatistics = () => {
  const { getCategoriesApi, getStatsListApi, getDownloadStatsListApi } =
  useRequests();
  
  let categoryList = [];
  const roles = useSelector(state => state?.userReducer?.roles);

  if (roles?.includes("Super Admin") || roles?.includes("Super Configurator")) {
    categoryList = [
      { label: "ALL CATEGORIES", value: "all" },
      { label: "MEDICAL", value: "medical" },
      { label: "CYBER", value: "cyber" },
      { label: "DENTAL", value: "dental" },
      { label: "EMERGENCE", value: "emergency" },
    ];
  } else {
    if (roles?.includes("DomainConfigurator_Medical")) {
      categoryList.push({ label: "MEDICAL", value: "medical" });
    }
    if (roles?.includes("DomainConfigurator_Cyber")) {
      categoryList.push({ label: "CYBER", value: "cyber" });
    }
    if (roles?.includes("DomainConfigurator_Dental")) {
      categoryList.push({ label: "DENTAL", value: "dental" });
    }
    if (roles?.includes("DomainConfigurator_Emergence")) {
      categoryList.push({ label: "EMERGENCE", value: "emergency" });
    }
  }

  const getCategories = useApi(getCategoriesApi);
  const getStatsListData = useApi(getStatsListApi);
  const getDownloadStatsListData = useApi(getDownloadStatsListApi);

  const [simulationCategories, setSimulationCategories] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [category, setCategory] = useState(categoryList?.[0]?.value || "");
  const [period, setPeriod] = useState("");
  const [runStatsList, setRunStatsList] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const validationSchema = Yup.object().shape({
    simulation_type: Yup.string().required("Simulation Type is required"),
  });

  const calculateDates = (selectedPeriod) => {
    switch (selectedPeriod) {
      case "Week":
        setStartDate(moment().startOf("isoWeek").format("YYYY-MM-DD"));
        setEndDate(moment().endOf("isoWeek").format("YYYY-MM-DD"));
        break;
      case "Month":
        setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
        setEndDate(moment().endOf("month").format("YYYY-MM-DD"));
        break;
      case "LastMonth":
        setStartDate(
          moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD")
        );
        setEndDate(
          moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
        );
        break;
      case "lastThree":
        setStartDate(
          moment().subtract(3, "months").format("YYYY-MM-DD")
        );
        setEndDate(
          moment().format("YYYY-MM-DD")
        );
        break;
      case "lastSix":
        setStartDate(
          moment().subtract(6, "months").format("YYYY-MM-DD")
        );
        setEndDate(
          moment().format("YYYY-MM-DD")
        );
        break;
      case "lastTwelve":
        setStartDate(
          moment().subtract(12, "month").format("YYYY-MM-DD")
        );
        setEndDate(
          moment().format("YYYY-MM-DD")
        );
        break;

      case "currentYear":
        const currentYear = moment().format('YYYY');
        setStartDate(
          moment(`${currentYear}-01-01`).format('YYYY-MM-DD')
        );
        setEndDate(
          moment().format("YYYY-MM-DD")
        );
        break;
      case "lastYear":
        setStartDate(
          moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD")
        );
        setEndDate(
          moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD")
        );
        break;
      default:
        // Handle default case
        break;
    }
  };

  const FetchDownloadStatsListData = () => {
    let dateRange = periodList.find(item => item.value === period);
    axios({
      url: `${process.env.REACT_APP_API_URL
        }/v1/scenario-management/assets/scenarioexcel/overall/report?fromDate=${startDate}&toDate=${endDate}&category=${category}&dateRange=${dateRange.label}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: Auth.isLoggedIn()
          ? `Bearer ${Auth.getToken()}`
          : undefined,
      },
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", `SR-runstats-${moment(startDate).format("MM/DD/YYYY")}-to-${moment(endDate).format("MM/DD/YYYY")}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading Excel file:", error);
      });
  };

  const FetchStatsListData = () => {
    getStatsListData
      .request(startDate, endDate, category || "all", "json")
      .then((res) => {
        let data = res?.data?.scenariocount;
        if (data.length > 0) {
          setRunStatsList([...data]);
          setCount(res.data.totalCount);
        } else {
          setRunStatsList([]);
          setCount("");
        }
        handleCloseModal();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    calculateDates("Week");
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      FetchStatsListData();
    }
  }, [category, startDate, endDate]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CssTitle variant='h4'>Analytics</CssTitle>
      </Grid>
      <Grid item xs={12}>
        <Formik
          initialValues={{
            category: categoryList?.[0]?.value || "all",
            period: "Week",
            Axon_Version: "",
          }}
          enableReinitialize
          onSubmit={(values, actions) => {
            console.log(values);
            calculateDates(values.period);
            setCategory(values.category);
            setTimeout(() => {
              FetchDownloadStatsListData();
            }, 1000);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            errors,
            touched,
          }) => (
            <Form>
              <Grid container spacing={4} style={{ padding: "1rem" }}>
                <Grid item xs={5} style={{ display: "flex" }}>
                  <div className='form_control' style={{ width: "90%" }}>
                    <FormControl fullWidth size='small'>
                      <InputLabel id='category' className='select-label'>
                        Category*
                      </InputLabel>
                      <FastField
                        component={CssSelectField}
                        labelId='category'
                        variant='outlined'
                        size='small'
                        name='category'
                        label='Category*'
                        id='category'
                        onBlur={(e) => handleBlur(e)}
                        value={values.category}
                        onChange={(e) => {
                          setFieldValue("category", e.target.value);
                          setCategory(e.target.value);
                        }}
                        fullWidth
                      >
                        {categoryList.map((item, i) => (
                          <MenuItem key={item.label} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </FastField>
                    </FormControl>
                    {errors.category && touched.category ? (
                      <div className='errorText'>{errors.category}</div>
                    ) : null}
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <div className='form_control' style={{ width: "90%" }}>
                    <FormControl fullWidth size='small'>
                      <InputLabel id='period' className='select-label'>
                        Period*
                      </InputLabel>
                      <FastField
                        component={CssSelectField}
                        labelId='period'
                        variant='outlined'
                        size='small'
                        name='period'
                        label='Period*'
                        id='period'
                        onBlur={(e) => handleBlur(e)}
                        value={values.period}
                        onOpen={() => {
                          setFieldValue("period", "");
                          setPeriod("");
                        }}
                        onChange={(e) => {
                          setFieldValue("period", e.target.value);
                          if (e.target.value !== "Custom") {
                            calculateDates(e.target.value);
                            setPeriod(e.target.value);
                          } else {
                            setOpenModal(true);
                            setStartDate(null);
                            setEndDate(null);
                            setPeriod(e.target.value);
                          }
                        }}
                        fullWidth
                      >
                        {periodList.map((item, i) => (
                          <MenuItem key={item.label} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </FastField>
                    </FormControl>

                    {errors.period && touched.period ? (
                      <div className='errorText'>{errors.period}</div>
                    ) : null}
                  </div>
                  <CssTitle style={{ marginTop: "5px", marginLeft: "15px", fontSize: "12px" }}>
                    {startDate && endDate ? `${moment(startDate).format("MM-DD-YYYY")} to ${moment(endDate).format("MM-DD-YYYY")}` : ""}
                  </CssTitle>
                </Grid>
                <Grid item xs={2}>
                  <CssButton
                    variant='contained'
                    sx={{
                      width: "max-content !important",
                      float: "right",
                      height: "40px",
                    }}
                    type='submit'
                  >
                    {"Download"}
                  </CssButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>

      <Grid item xs={12}>
        <RunStatsTable
          tableData={runStatsList}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          setCount={setCount}
          count={count}
        />
      </Grid>
      <CssDialogBoxes
        onClose={handleCloseModal}
        aria-labelledby='customized-dialog-title'
        open={openModal}
        fullWidth={true}
        maxWidth='xs'
      >
        <DialogTitle id='customized-dialog-title' onClose={handleCloseModal}>
          Custom Dates
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DatePicker
                  label='From Date'
                  renderInput={(params) => <TextField {...params} fullWidth/>}
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(
                      moment(new Date(newValue)).format("YYYY-MM-DD")
                    );
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DatePicker
                  label='To Date'
                  renderInput={(params) => <TextField {...params} fullWidth/>}
                  value={endDate}
                  disabled={startDate === null}
                  minDate={new Date(startDate)}
                  onChange={(newValue) =>
                    setEndDate(moment(new Date(newValue)).format("YYYY-MM-DD"))
                  }
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
      </CssDialogBoxes>
    </Grid>
  );
};
export default RunStatistics;
