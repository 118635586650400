/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import ReactFlow from 'react-flow-renderer';
import '../modules/Simulation/Simulation.css';
import { nodeTypes } from '../modules/Simulation/NodeTypes';

const CustomReactFlow = (props) => {
  const {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    onInit,
    onDrop,
    onDragOver,
    onNodeClick,
    onEdgeClick
  } = props;

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      onInit={onInit}
      onDrop={onDrop}
      onDragOver={onDragOver}
      nodeTypes={nodeTypes}
      zoomto={{ zoomLevel: 0 }}
      onNodeClick={onNodeClick}
      onEdgeClick={onEdgeClick}

    />
  );
}

export default CustomReactFlow;

