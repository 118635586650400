import React from "react";
import { FastField } from "formik";
import CssTextField from "../../Components/CssTextField";
import { styled } from "@mui/material/styles";

import { Grid, IconButton, Card } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CssCloseIconButton = styled(IconButton)({
  position: "absolute",
  top: "4px",
  right: "4px",
  border: "1px solid #F00",
  padding: 0,
});

const CssCloseIcon = styled(CloseIcon)({
  color: "#F00",
  fontSize: "1.2rem",
});

const CssCard = styled(Card)({
  marginTop: "30px !important",
  padding: "6px 20px 20px",
  position: "relative",
  backgroundColor: 'transparent',
  border: '1px solid #673068'
});

const StaticInfo = (props) => {
  const { index, enableClose, errors, touched, onSetFieldValue, onRemove, staticInfos, handleBlur, isPublished } =
    props;
  return (
    <>
      <Grid container component={CssCard} className='card-wrapper'>
        <Grid item xs={5}>
          <FastField
            component={CssTextField}
            variant='outlined'
            size='small'
            name={`static_info.${index}.name`}
            id={`static_info.${index}.name`}
            InputProps={{
              className: 'text-field'
            }}
            autoComplete="Off"
            value={staticInfos.name}
            inputProps={{ readOnly: isPublished }}
            onBlur={handleBlur}
            onChange={(e) => {
              onSetFieldValue(`static_info.${index}.name`, e.target.value);
            }}
            label='Name'
          />
          {errors &&
            errors.static_info &&
            errors.static_info[index] &&
            errors.static_info[index].name &&
            touched &&
            touched.static_info &&
            touched.static_info[index] &&
            touched.static_info[index].name && (
              <div className='field-error'>{errors.static_info[index].name}</div>
            )}
        </Grid>
        <Grid item xs={5}>
          <FastField
            component={CssTextField}
            variant='outlined'
            size='small'
            name={`static_info.${index}.value`}
            id={`static_info.${index}.value`}
            value={staticInfos.value}
            onBlur={handleBlur}
            inputProps={{ readOnly: isPublished }}
            InputProps={{
              className: 'text-field'
            }}
            onChange={(e) => {
              onSetFieldValue(`static_info.${index}.value`, e.target.value);
            }}
            label='Value'
          />
          {errors &&
            errors.static_info &&
            errors.static_info[index] &&
            errors.static_info[index].value &&
            touched &&
            touched.static_info &&
            touched.static_info[index] &&
            touched.static_info[index].value && (
              <div className='field-error'>{errors.static_info[index].value}</div>
            )}
        </Grid>
        {(enableClose && !isPublished) &&
          <CssCloseIconButton onClick={() => onRemove(index)}>
            <CssCloseIcon />
          </CssCloseIconButton>
        }
      </Grid>
    </>
  );
};

export default StaticInfo;
