/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { GlobalState } from 'context/GlobalContext';
import CssTitle from 'Components/CssTitle';
import useApi from 'services/api-hook';
import useRequests from 'services/request-hook';
import { useSelector } from 'react-redux';
import CssCircularProgress from 'Components/CssCircularProgress';

const Home = () => {
  const navigate = useNavigate();
  const { getCategoriesApi } = useRequests();
  const getCategories = useApi(getCategoriesApi);
  const [isCategoryLoading, setIsCategoryLoading] = useState(false);
  const roles = useSelector(state => state?.userReducer?.roles);
  const { setCategoryList, categoryList } = useContext(GlobalState);
  const localThemes = localStorage.getItem("themes");
  const getAllSimulationCategories = () => {
    setIsCategoryLoading(true);
    getCategories.request()
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data?.length > 0) {
            let data = [];
            if (roles?.includes("Super Admin") || roles?.includes("Super Configurator") || roles?.includes("AssociateConfigurator")) {
              data = res?.data;
            } else {
              res?.data?.forEach(item => {
                if (item?.category === "medical" && (roles?.includes("DomainConfigurator_Medical") || roles?.includes("Associate Configurator_Medical"))) {
                  data.push(item);
                } else if (item?.category === "cyber" && (roles?.includes("DomainConfigurator_Cyber") || roles?.includes("Associate Configurator_Cyber"))) {
                  data.push(item);
                } else if (item?.category === "dental" && (roles?.includes("DomainConfigurator_Dental") || roles?.includes("Associate Configurator_Dental"))) {
                  data.push(item);
                } else if (item?.category === "emergency" && (roles?.includes("DomainConfigurator_Emergence") || roles?.includes("Associate Configurator_Emergence"))) {
                  data.push(item);
                }
              });
            }
            setIsCategoryLoading(false);
            setCategoryList(data);
          } else {
            setIsCategoryLoading(false);
            console.log('No Data Available');
          }
        }
      })
      .catch((error) => {
        let errorObj = error.response.data;
        setIsCategoryLoading(false);
        console.log('error', errorObj);
      });
  };

  useEffect(() => {
    if (roles) {
      getAllSimulationCategories();
    }
  }, [roles]);

  const handleCategory = (selectedItem) => {
    localStorage.setItem('selectedCategory', JSON.stringify(selectedItem));
    localStorage.setItem('categoryList', JSON.stringify(categoryList));
    const category = selectedItem.category;
    navigate(`/${category}`);
  };

  return (
    <>
      <CssTitle variant='h4'>Axon Configurator</CssTitle>
      <Grid
        container
        spacing={4}
        direction='row'
        alignItems='center'
        sx={{
          height: 'calc(100% - 10%)',
        }}
      >
        {isCategoryLoading ?
          <CssCircularProgress size={80} sx={{ margin: 'auto' }} />
        : categoryList?.map((simulationItem, i) => (
          <Grid item xs={3} key={simulationItem._id} sx={{ margin: categoryList?.length === 1 ? 'auto' : 0 }}>
            <div
              className={'simulation-container'}
              onClick={() => handleCategory(simulationItem)}
            >
              <img
                src={localThemes === "light" ? simulationItem?.light_theme_poster?.url : simulationItem?.dark_theme_poster?.url }
                alt=''
                className={'simulation-image'}
              />
              <CssTitle variant='h4' className='simulation-title'>
                {simulationItem?.name}
              </CssTitle>
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
export default Home;
