import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment"
import TablePagination from '@mui/material/TablePagination';
import { styled } from '@mui/material/styles';
import _ from "lodash";

const CssTablePagination = styled(TablePagination)(({ theme }) => ({
    '& .MuiTablePagination-selectLabel': {
        marginBottom: "0px"
    },
    '& .MuiTablePagination-displayedRows': {
        marginBottom: "0px"
    }
}));

const MeetingList = (props) => {
    const { meetingList, page, setPage, setRowsPerPage, rowsPerPage, count, fetchMeetingList, handleClickRow, handleOpenEdit } = props

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
        fetchMeetingList(0, event.target.value);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        fetchMeetingList(newPage, rowsPerPage);
    };

    return (
        <Paper elevation={0} sx={{ width: '100%', overflow: 'hidden', backgroundColor: "transparent" }}>
            <TableContainer sx={{ height: 400, }}>
                <Table stickyHeader aria-label="Meeting table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" className="tableHeader">Title</TableCell>
                            <TableCell align="left" className="tableHeader">Moderator</TableCell>
                            <TableCell align="left" className="tableHeader">Scenarios</TableCell>
                            <TableCell align="left" className="tableHeader">Location</TableCell>
                            <TableCell align="left" className="tableHeader">Start Date</TableCell>
                            <TableCell align="left" className="tableHeader">End Date</TableCell>
                            <TableCell align="left" className="tableHeader">Edit </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {meetingList?.map((row, i) => (
                            <TableRow key={row?._id} sx={{ cursor: "pointer" }} >
                                <TableCell align="left" onClick={() => handleClickRow(row._id)}>{_.truncate(row?.title) || `---`}</TableCell>
                                <TableCell align="left" onClick={() => handleClickRow(row._id)}>{row?.moderator_name || `---`}</TableCell>
                                <TableCell align="left" onClick={() => handleClickRow(row._id)}>{row?.scenario_title || `---`}</TableCell>
                                <TableCell align="left" onClick={() => handleClickRow(row._id)}>{_.truncate(row?.location?.state) || `---`}</TableCell>
                                <TableCell align="left" onClick={() => handleClickRow(row._id)}>{moment(row?.start_at).format('MMM-DD-YYYY, h:mm:ss a') || `---`}</TableCell>
                                <TableCell align="left" onClick={() => handleClickRow(row._id)}>{moment(row?.end_at).format('MMM-DD-YYYY, h:mm:ss a') || `---`}</TableCell>
                                <TableCell align="left">{moment(new Date()).isAfter(row.end_at) ? null : <EditIcon onClick={() => handleOpenEdit(row)} />}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <CssTablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};
export default MeetingList;
