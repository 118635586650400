/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CssTitle from "Components/CssTitle";
import CssButton from "Components/CssButton";
import ModalContainer from "Components/ModalContainer";
import { Formik, Form, FastField, FieldArray } from "formik";
import {
  Grid,
  FormControl,
  MenuItem,
  InputLabel,
  Stack,
  Container,
  Typography,
  IconButton,
} from "@mui/material";
import CssTextField from "Components/CssTextField";
import CssSelectField from "Components/CssSelectField";
import CssDateTimePicker from "Components/CssDateTimePicker";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Sponsors from "./Sponsors";
import { styled } from "@mui/material/styles";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import useRequests from "services/request-hook";
import useApi from "services/api-hook";
import * as Yup from "yup";
import MeetingList from "./MeetingList";
import { useNavigate } from "react-router-dom";
import './Meeting.css'
import CustomSnackbar from 'Components/CustomSnackbar';
import CssCircularProgress from "Components/CssCircularProgress";


const DateTextField = styled(TextField)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  "& .MuiIconButton-root": {
    padding: 0,
  }
}));

const CssAddIconButton = styled(IconButton)({
  position: "absolute",
  top: "33px",
  right: "30px",
});

const CssAddIcon = styled(AddCircleTwoToneIcon)({
  '& addIcon ': {
    color: "#FFF",
    fontSize: "2rem",
  }
});

const ITEM_HEIGHT = 48;

const validationSchema = Yup.object().shape({
  title: Yup.string().matches(/^[A-Za-z]{2}[ A-Za-z0-9_-]*$/, "Title must be alphanumeric  only").required("Title is required"),
  start_at: Yup.date().min(new Date(), `Please check start Date and Time`).required("Start Date is required").nullable(),
  end_at: Yup.date().min(Yup.ref('start_at'), 'Please check End Date and Time').required("End Date is required").nullable(),
  moderator_id: Yup.string().required("Moderator is required"),
  scenario_id: Yup.string().required("Scenario Id is required"),
  location: Yup.object().shape({
    city: Yup.string().matches(/^[A-Za-z]{2}[ A-Za-z0-9_-]*$/, "City must be alphanumeric only").required("City is required"),
    state: Yup.string().matches(/^[A-Za-z]{2}[ A-Za-z0-9_-]*$/, "State must be alphanumeric only").required("State is required"),
    country: Yup.string().matches(/^[A-Za-z]{2}[ A-Za-z0-9_-]*$/, "Country must be alphanumeric only").required("Country is required"),
  }),
});

const Meeting = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const {
    getPlayersApi,
    getScenariosOptionsApi,
    createMeetingApi,
    updateMeetingApi,
    getMeetingsApi,
    getIndividualMeetingApi,
  } = useRequests();
  const getPlayers = useApi(getPlayersApi);
  const getScenariosOptions = useApi(getScenariosOptionsApi);
  const createMeeting = useApi(createMeetingApi);
  const updateMeeting = useApi(updateMeetingApi);
  const getMeetingsList = useApi(getMeetingsApi);
  const getIndividualMeeting = useApi(getIndividualMeetingApi);
  const [moderatorOptions, setModeratorOptions] = useState([]);
  const [scenarioOptions, setScenarioOptions] = useState([]);
  const [meetingList, setMeetingList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [editMeetingData, setEditMeetingData] = useState(null)

  const setSnackBar = () => {
    setOpenSnackbar(true);
  };

  const [initialValues, setInitialValues] = useState({
    title: "",
    moderator_id: "",
    start_at: null,
    end_at: null,
    scenario_id: "",
    location: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      country: "",
    },
    sponsors: [
      {
        name: "",
        logo_url: "",
        place_holder: "",
      },
    ],
  });

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleOpen = () => {
    setInitialValues({
      title: "",
      moderator_id: "",
      start_at: null,
      end_at: null,
      scenario_id: "",
      location: {
        line1: "",
        line2: "",
        city: "",
        state: "",
        country: "",
      },
      sponsors: [
        {
          name: "",
          logo_url: "",
          place_holder: "",
        }
      ],
    })
    setOpen(true);
  };
  const handleModalClose = () => {
    setOpen(false);
    setEdit(false);
    setEditMeetingData(null);
    setInitialValues({
      title: "",
      moderator_id: "",
      start_at: null,
      end_at: null,
      scenario_id: "",
      location: {
        line1: "",
        line2: "",
        city: "",
        state: "",
        country: "",
      },
      sponsors: [
        {
          name: "",
          logo_url: "",
          place_holder: "",
        }
      ],
    })
  };
  const handleOpenEdit = (rowData) => {
    const meetingId = rowData?._id
    if (meetingId) {
      getIndividualMeeting
        .request(rowData._id)
        .then((res) => {
          if (res.status === 200) {
            setInitialValues(res.data)
            setEditMeetingData(rowData);
            setOpen(true)
            setEdit(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }


  const fetchPlayers = () => {
    getPlayers
      .request()
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            setModeratorOptions([...res.data]);
          } else {
            setModeratorOptions([]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchScenariosOptions = () => {
    getScenariosOptions
      .request()
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            setScenarioOptions([...res.data]);
          } else {
            setScenarioOptions([]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchMeetingList = (pageNO, rowsPerPages) => {
    getMeetingsList
      .request(pageNO + 1, rowsPerPages)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            setRowsPerPage(+res?.headers?.limit);
            // setPage(+res?.headers?.current_page);
            setCount(+res?.headers?.total_count);
            setMeetingList([...res.data]);
          } else {
            setMeetingList([]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchPlayers();
    fetchScenariosOptions();
    fetchMeetingList(page, rowsPerPage);
  }, []);

  const handleClickRow = (id) => {
    navigate(`/meetings/${id}`);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            spacing={2}
            direction='row'
            justifyContent={"space-between"}
            alignItems='center'
          >
            <CssTitle variant='h4'>Create Meetings</CssTitle>
            <CssButton
              variant='contained'
              onClick={handleOpen}
              sx={{ width: "max-content" }}
            >
              New Meeting
            </CssButton>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <MeetingList
            meetingList={meetingList}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            setCount={setCount}
            count={count}
            fetchMeetingList={fetchMeetingList}
            handleClickRow={handleClickRow}
            handleOpen={handleOpen}
            handleOpenEdit={handleOpenEdit}
          />
        </Grid>
      </Grid>
      <ModalContainer
        open={open}
        handleClose={handleModalClose}
        title={edit ? "Edit Meeting" : "Meeting"}
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          validateOnMount={true}
          isInitialValid={false}
          resetForm={true}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(true)
            if (!edit) {
              createMeeting
                .request(values)
                .then((res) => {
                  if (res.status === 200) {
                    fetchMeetingList(page, rowsPerPage);
                    setSnackBar();
                    setOpen(false);
                    setSubmitting(false);
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setSubmitting(false)
                });
            } else {
              updateMeeting
                .request(editMeetingData?._id, values)
                .then((res) => {
                  if (res.status === 200) {
                    fetchMeetingList(page, rowsPerPage);
                    setSnackBar();
                    setOpen(false);
                    setEdit(false);
                    setSubmitting(false);
                    setEditMeetingData(null);
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setSubmitting(false)
                });
            }
          }}
        >
          {({
            dirty,
            isValid,
            values,
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            handleBlur,
            setFieldTouched
          }) => (
            <Form
              onSubmit={handleSubmit}
              noValidate={true}
              autoComplete='off'
              className='form-wrapper formSpace'
            >
              <Container sx={{ marginTop: "10px" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <div className='form_control'>
                      <FastField
                        component={CssTextField}
                        variant='outlined'
                        size='small'
                        name='title'
                        id='title'
                        label='Title*'
                        autoComplete='Off'
                        value={values.title}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue('title', e.target.value);
                        }}
                        sx={{ marginTop: "0px" }}
                      />
                      {errors.title && touched.title ? (
                        <div className='errorText'>{errors.title}</div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className='form_control'>
                      <FormControl fullWidth size='small'>
                        <InputLabel id='moderator_id' className='select-label'>
                          Moderator*
                        </InputLabel>
                        <FastField
                          component={CssSelectField}
                          labelId='moderator_id'
                          variant='outlined'
                          size='small'
                          name='moderator_id'
                          label='moderator*'
                          onBlur={(e) => handleBlur(e)}
                          id='moderator_id'
                          value={values.moderator_id ? values.moderator_id : ""}
                          onChange={(e) => {
                            setFieldValue('moderator_id', e.target.value);
                          }}
                        >
                          {moderatorOptions?.map((item, i) => (
                            <MenuItem key={item._id} value={item._id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </FastField>
                      </FormControl>
                      {errors.moderator_id && touched.moderator_id ? (
                        <div className='errorText'>{errors.moderator_id}</div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className='form_control'>
                      <LocalizationProvider sx={{ borderColor: "#FFF" }} dateAdapter={AdapterDateFns}>
                        <FastField
                          component={CssDateTimePicker}
                          label='Start Date*'
                          name='start_at'
                          id='start_at'
                          format={"MM/DD/YYYY"}
                          color="primary"
                          value={values.start_at}
                          minDateTime={new Date()}
                          onChange={(newValue) => {
                            if (errors.start_at) {
                              setFieldTouched("start_at", true)
                            }
                            setFieldValue('start_at', newValue);
                          }}
                          inputProps={{ readOnly: true }}
                          disablePast={true}
                          renderInput={(params) => (
                            <DateTextField
                              {...params}
                              autoComplete={"Off"}
                              size='small'
                            // InputLabelProps={{
                            //   style: { color: '#FFF' },
                            // }}
                            // sx={{
                            //   svg: { color: '#FFF' },
                            // }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      {errors?.start_at && touched?.start_at ? (
                        <div className='errorText'>{errors?.start_at}</div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className='form_control'>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <CssDateTimePicker
                          label='End Date*'
                          name='end_at'
                          id='end_at'
                          format={"MM/DD/YYYY"}
                          value={values.end_at}
                          onBlur={handleBlur}
                          onChange={(newValue) => {
                            if (errors.end_at) {
                              setFieldTouched("end_at", true)
                            }
                            setFieldValue('end_at', newValue);

                          }}
                          inputProps={{ readOnly: true }}
                          minDateTime={edit ? new Date(values?.start_at) : new Date(values?.start_at)}

                          disabled={values?.start_at === null}
                          disablePast={true}
                          renderInput={(params) => (
                            <DateTextField
                              {...params}
                              autoComplete={"Off"}
                              size='small'
                            // InputLabelProps={{
                            //   style: { color: '#FFF' },
                            // }}
                            // sx={{
                            //   svg: { color: '#FFF' },
                            // }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      {errors.end_at && touched.end_at ? (
                        <div className='errorText'>{errors?.end_at}</div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className='form_control'>
                      <FormControl fullWidth size='small'>
                        <InputLabel id='scenario_id' className='select-label'>
                          Scenario id*
                        </InputLabel>
                        <FastField
                          component={CssSelectField}
                          labelId='scenario_id'
                          variant='outlined'
                          size='small'
                          name='scenario_id'
                          label='Scenario id*'
                          id='scenario_id'
                          onBlur={handleBlur}
                          defaultValue=''
                          value={values?.scenario_id}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                              },
                            },
                          }}
                          onChange={(e) => {
                            setFieldValue('scenario_id', e.target.value);
                          }}
                        >
                          {scenarioOptions?.map((item, i) => (
                            <MenuItem key={item._id} value={item._id}>
                              {item.title}
                            </MenuItem>
                          ))}
                        </FastField>
                      </FormControl>
                      {errors.scenario_id && touched.scenario_id ? (
                        <div className='errorText'>{errors.scenario_id}</div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5" className='title'>Location</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <div className='form_control'>
                      <FastField
                        component={CssTextField}
                        variant='outlined'
                        size='small'
                        name='location.line1'
                        id='location.line1'
                        label='line1'
                        autoComplete={"Off"}
                        value={values?.location?.line1}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue('location.line1', e.target.value);
                        }}
                        sx={{ marginTop: "0px" }}
                      />
                      {errors?.location?.line1 && touched?.location?.line1 ? (
                        <div className='errorText'>
                          {errors?.location?.line1}
                        </div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className='form_control'>
                      <FastField
                        component={CssTextField}
                        variant='outlined'
                        size='small'
                        name='location.line2'
                        id='location.line2'
                        label='line2'
                        autoComplete={"Off"}
                        value={values?.location?.line2}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue('location.line2', e.target.value);
                        }}
                        sx={{ marginTop: "0px" }}
                      />
                      {errors?.location?.line2 && touched?.location?.line2 ? (
                        <div className='errorText'>
                          {errors?.location?.line2}
                        </div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className='form_control'>
                      <FastField
                        component={CssTextField}
                        variant='outlined'
                        size='small'
                        name='location.city'
                        id='location.city'
                        label='City*'
                        autoComplete={"Off"}
                        value={values?.location?.city}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue('location.city', e.target.value);
                        }}
                        sx={{ marginTop: "0px" }}
                      />
                      {errors?.location?.city && touched?.location?.city ? (
                        <div className='errorText'>
                          {errors?.location?.city}
                        </div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className='form_control'>
                      <FastField
                        component={CssTextField}
                        variant='outlined'
                        size='small'
                        name='location.state'
                        id='location.state'
                        label='State*'
                        autoComplete={"Off"}
                        value={values?.location?.state}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue('location.state', e.target.value);
                        }}
                        sx={{ marginTop: "0px" }}
                      />
                      {errors?.location?.state && touched?.location?.state ? (
                        <div className='errorText'>
                          {errors?.location?.state}
                        </div>
                      ) : null}
                    </div>
                  </Grid>{" "}
                  <Grid item xs={6}>
                    <div className='form_control'>
                      <FastField
                        component={CssTextField}
                        variant='outlined'
                        size='small'
                        name='location.country'
                        id='location.country'
                        label='Country*'
                        autoComplete={"Off"}
                        value={values?.location?.country}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue('location.country', e.target.value);
                        }}
                        sx={{ marginTop: "0px" }}
                      />
                      {errors?.location?.country &&
                        touched?.location?.country ? (
                        <div className='errorText'>
                          {errors?.location?.country}
                        </div>
                      ) : null}
                    </div>
                  </Grid>
                </Grid>
                <Grid container className='form-wrapper' direction='row'>
                  <FieldArray name='sponsors'>
                    {({ insert, remove, push }) => (
                      <>
                        <Grid
                          item
                          xs={12}
                          className='field-array-title-wrapper'
                        >
                          <Typography
                            variant='h5'
                            className='field-array-title'
                          >
                            Sponsors
                          </Typography>
                          {values?.sponsors?.length < 6 && (
                            <CssAddIconButton
                              onClick={() =>
                                push({
                                  name: "",
                                  logo_url: "",
                                  place_holder: "",
                                })
                              }
                            >
                              <CssAddIcon />
                            </CssAddIconButton>
                          )}
                        </Grid>
                        {values?.sponsors?.map((item, index) => (
                          <Sponsors
                            key={index}
                            index={index}
                            errors={errors}
                            touched={touched}
                            onRemove={remove}
                            sponsor={item}
                            enableClose={values.sponsors.length > 1}
                            onSetFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                          />
                        ))}
                      </>
                    )}
                  </FieldArray>
                </Grid>
                <Grid className='button-stack form-btn-wrapper'>
                  <Stack spacing={2} direction='row'>
                    {!edit ?
                      <CssButton
                        variant='contained'
                        size='medium'
                        type='submit'
                        disabled={!isValid || isSubmitting}
                      >
                        {isSubmitting ? <CssCircularProgress size={20} /> : "Save"}
                      </CssButton>
                      : <CssButton
                        variant='contained'
                        size='medium'
                        type='submit'
                        disabled={!dirty || !isValid}
                      >
                        {isSubmitting ? <CssCircularProgress size={20} /> : "Update"}
                      </CssButton>}

                    {edit ? null :
                      (<CssButton variant='contained' sx={{ backgroundColor: "#673068" }} size='medium' type='reset' disabled={!dirty}>
                        Reset
                      </CssButton>)}
                  </Stack>
                </Grid>
              </Container>
            </Form>
          )}
        </Formik>
      </ModalContainer>
      <CustomSnackbar open={openSnackbar} message={"Data Saved Successfully"} handleClose={handleCloseSnackbar} />

    </>
  );
};

export default Meeting;


