/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  Stack,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CircleIcon from "@mui/icons-material/Circle";
import ModalContainer from "Components/ModalContainer";
import Tooltip from "@mui/material/Tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import MediaCard from "Components/MediaCard";
import CssButton from "Components/CssButton";
import SimulationForm from "./SimulationForm";
import useRequests from "services/request-hook";
import useApi from "services/api-hook";
import CssTitle from "Components/CssTitle";
import "./Category.css";
import CustomSnackbar from "Components/CustomSnackbar";
// import {styled} from "@emotion/styled";
import { GlobalState } from "context/GlobalContext";
import Snackbar from "@mui/material/Snackbar";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useSelector } from "react-redux";
import CssSelectField from "Components/CssSelectField";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 1.0),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 1.0),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "25ch",
      "&:focus": {
        width: "25ch",
      },
    },
  },
}));

const Category = () => {
  const { category } = useParams();
  const { simulationStatus, setSimulationStatus } = useContext(GlobalState);
  const selectedItem = JSON.parse(localStorage.getItem("selectedCategory"));
  const roles = useSelector(state => state?.userReducer?.roles);
  const hasSRModificationAccess = !roles?.includes("Super Admin");
  const navigate = useNavigate();
  const { getScenarioListApi, cloneSRApi } = useRequests();
  const getScenarioList = useApi(getScenarioListApi);
  const cloneSR = useApi(cloneSRApi);
  const [simulationList, setSimulationList] = useState([]);
  const [fileUrl, setFileUrl] = useState("");
  const [file1Url, setFile1Url] = useState("");
  const [file2Url, setFile2Url] = useState("");
  const [file3Url, setFile3Url] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [simulationTotals, setSimulationTotals] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openIdSnackbar, setOpenIdSnackbar] = useState(false);
  const containerRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [triggerObserver, setTriggerObserver] = useState(false);
  const [isAfterSubmit, setIsAfterSubmit] = useState(false);
  const [statusField, setStatusField] = useState();
  const [isScroll, setIsScroll] = useState(false);
  const [text, setText] = useState("");
  const [isColned, setIsCloned] = useState(null)

  const containerOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0.3,
  };

  const statusOptions = [
    { value: "ALL", label: "ALL" },
    { value: "DRAFT", label: "DRAFT" },
    { value: "PUBLISHED", label: "PUBLISHED" },
    { value: "UNPUBLISH", label: "UNPUBLISHED" },
    { value: "ARCHIVED", label: "ARCHIVED" },
  ];

  const initialValues = {
    title: "",
    banner: "",
    type: "",
    bg_music: "",
    category: selectedItem?._id || "",
    status: selectedItem?.status || "DRAFT",
    trailer: "",
    files: null,
    bg_img: null,
    trailer: null,
    theme_name: selectedItem?.theme_name || "base",
    track_selection: "True",
    isPublic: "False",
    playback: "True",
    randomizer: "False",
  };

  const handleAfterSubmit = () => {
    setIsAfterSubmit(true);
  };
  const handleOpenDrawer = () => {
    setOpenModal(true);
  };
  const handleCloseDrawer = () => {
    setOpenModal(false);
    setFile2Url("");
    setFile3Url("");
  };

  const handleCloneSr = (id) => {
    setSimulationList([]);
    if (window.confirm('Are you sure you want to clone SR?')) {
      // Clone it!
      cloneSR
      .request(id)
      .then((res) => {
        if (res.status === 200) {
          setIsCloned({ ...res.data })
        }
      })
      .catch((error) => {
        console.log("error", error)
      });
    } else {
      // Do nothing!
    }
  };

  const getScenariosList = (page, status) => {
    const categoryId = selectedItem?._id;
    getScenarioList
      .request(categoryId, page, status || "ALL", text)
      .then((res) => {
        if (res.status === 200) {
          const {
            completed_simulation,
            total_count,
            recent_uploaded_simulations,
            current_page,
            total_pages,
          } = res?.headers;
          if (res.data.length > 0) {
            let totals = {
              completed_simulation,
              total_count,
              recent_uploaded_simulations,
              total_pages,
            };

            setSimulationTotals({ ...totals });
            if (isAfterSubmit) {
              setIsAfterSubmit(false);
              setSimulationList([]);
              setSimulationList(res.data);
            } else {
              setSimulationList((sls) => sls.concat(res.data));
            }
            if (simulationStatus && isScroll) {
              setSimulationList([]);
              setSimulationList((sls) => sls.concat(res.data));
            } else {
              if (simulationList < res?.data?.length) {
                setSimulationList(res.data);
              }
            }
            if (parseInt(total_pages) > parseInt(current_page)) {
              setCurrentPage(current_page);
              setTriggerObserver(false);
            } else {
              setTriggerObserver(true);
            }
          } else {
            setSimulationList([]);
            setTriggerObserver(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onVideoUpload = ({ url }) => {
    if (url) {
      let fileUrl = url?.substring(0, url?.indexOf("?"));
      var path = fileUrl.split("/");
      fileUrl = process.env.REACT_APP_CDN_URL
        ? `${process.env.REACT_APP_CDN_URL}/${path[3]}/${path[4]}`
        : fileUrl;
      setFile2Url(fileUrl);
    } else {
      setFile2Url("");
    }
  };
  const onFileUpload = ({ url }) => {
    if (url) {
      let fileUrl = url?.substring(0, url?.indexOf("?"));
      var path = fileUrl.split("/");
      fileUrl = process.env.REACT_APP_CDN_URL
        ? `${process.env.REACT_APP_CDN_URL}/${path[3]}/${path[4]}`
        : fileUrl;
      setFileUrl(fileUrl);
    } else {
      setFileUrl("");
    }
  };

  const onImageUpload = ({ url }) => {
    if (url) {
      let fileUrl = url?.substring(0, url?.indexOf("?"));
      var path = fileUrl.split("/");
      fileUrl = process.env.REACT_APP_CDN_URL
        ? `${process.env.REACT_APP_CDN_URL}/${path[3]}/${path[4]}`
        : fileUrl;
      setFile1Url(fileUrl);
    } else {
      setFile1Url("");
    }
  };

  const onAudioUpload = ({ url }) => {
    if (url) {
      let fileUrl = url?.substring(0, url?.indexOf("?"));
      var path = fileUrl.split("/");
      fileUrl = process.env.REACT_APP_CDN_URL
        ? `${process.env.REACT_APP_CDN_URL}/${path[3]}/${path[4]}`
        : fileUrl;
      setFile3Url(fileUrl);
    } else {
      setFile3Url("");
    }
  };

  const handleNavigate = (data) => {
    localStorage.setItem("simulationItem", JSON.stringify(data));
    navigate(`/${category}/simulation/${data._id}`);
  };

  const setSnackBar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCloseIdSnackbar = () => {
    setOpenIdSnackbar(false);
  };

  const handleColor = (status) => {
    let color = "";
    if (status === "PUBLISHED") {
      color = "#66bb6a";
    } else if (status === "ARCHIVED") {
      color = "#f44336";
    } else if (status === "DRAFT") {
      color = "yellow";
    } else if (status === "UNPUBLISH") {
      color = "chocolate";
    }
    return color;
  };

  const onInteraction = (entities, observer) => {
    const [entity] = entities;

    if (entity.isIntersecting && !triggerObserver) {
      setTriggerObserver(true);
      setIsScroll(false);
      if (!isNaN(parseInt(currentPage))) {
        getScenariosList(parseInt(currentPage) + 1, simulationStatus);
      }
    }
  };

  useEffect(() => {
    getScenariosList(1, simulationStatus);
  }, [simulationStatus]);

  useEffect(() => { 
    // setSimulationList([]);
    setCurrentPage(1);
    getScenariosList(1, simulationStatus);
  },[isColned])

  useEffect(() => {
    if (isAfterSubmit) {
      getScenariosList(1, simulationStatus);
    }
  }, [isAfterSubmit]);

  useEffect(() => {
    setSimulationList([]);
    setCurrentPage(1);
    getScenariosList(1, simulationStatus);
  }, [text]);

  useEffect(() => {
    const observer = new IntersectionObserver(onInteraction, containerOptions);
    if (containerRef.current) observer.observe(containerRef.current);

    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, [containerRef, containerOptions]);

  return (
    <div>
      <CssTitle variant="h6"><NavLink to={'/'}>Home</NavLink> <NavigateNextIcon /> <span style={{ textTransform: "capitalize"}}> {category} Simulation </span></CssTitle>
      <Grid
        container
        spacing={3}
        alignItems='center'
        style={{ marginTop: "0rem" }}
      >
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <img
            src={selectedItem?.poster?.url}
            alt=''
            className={"banner-image"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={8}>
          <CssTitle variant='h5'>
            Total Number of Simulations : {simulationTotals?.total_count}
          </CssTitle>
          <CssTitle variant='h5'>
            Completed Simulations : {simulationTotals?.completed_simulation}
          </CssTitle>
          <CssTitle variant='h5'>
            Recently Uploaded Simulation :{" "}
            {simulationTotals?.recent_uploaded_simulations}
          </CssTitle>
        </Grid>
        <Grid item xs={12}>
          <CssTitle variant='h5'>{selectedItem?.name}</CssTitle>
        </Grid>
        <Box sx={{ width: '100%', display: "flex", alignItems: "center", justifyContent: "space-between", paddingLeft: '1rem', marginTop: '24px'}}>
          {hasSRModificationAccess && <Grid item xs={2}>
            <CssButton
              variant='contained'
              startIcon={<AddIcon />}
              onClick={handleOpenDrawer}
              sx={{ width: "max-content !important", marginLeft: "8px" }}
            >
              Add Simulation
            </CssButton>
          </Grid>}
          <Grid item xs={6}>
            <FormControl sx={{ m: 1, minWidth: 180 }} size='small'>
              <InputLabel id='status'>Status</InputLabel>
              <CssSelectField
                labelId='status'
                id='status'
                name='status'
                value={simulationStatus}
                label='Status'
                defaultValue={""}
                onChange={(event) => {
                  setIsScroll(true);
                  setSimulationStatus(event.target.value);
                }}
              >
                {statusOptions?.map((item, i) => (
                  <MenuItem key={i} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </CssSelectField>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder='Search…'
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => setText(e.target.value)}
              />
            </Search>
          </Grid>
        </Box>
      </Grid>

      <Grid container spacing={4} sx={{ marginTop: "1rem" }}>
        {simulationList &&
          simulationList.map((item, i) => (
            <Grid item xs={3} key={i}>
              <MediaCard
                image={
                  item?.banner?.url?.substring(
                    0,
                    item?.banner?.url?.indexOf("?")
                  ) || item?.banner?.url
                }
                alt={item?.title}
                title={item?.title}
                subTitle={""}
                handleClick={() => handleNavigate(item)}
                actionShow={false}
              >
                <Stack direction='row' sx={{ justifyContent: "space-between" }}>
                  <Box direction='row' alignItems='center' display='flex'>
                    <CircleIcon
                      fontSize='small'
                      sx={{ marginRight: "5px", color: handleColor(item?.status) }}
                    />
                    <Typography>{item?.status === "UNPUBLISH" ? item?.status?.toLowerCase() + "ed" : item?.status?.toLowerCase()}</Typography>
                  </Box>
                  {item?.status && (
                    <Box>
                      {hasSRModificationAccess && <Tooltip title='Clone Simulation' placement='top'>
                        <FileCopyIcon
                          sx={{ marginLeft: "10px" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCloneSr(item?._id)
                            // setOpenIdSnackbar(true);
                            // navigator.clipboard.writeText(item?._id);
                          }}
                        />
                      </Tooltip>}
                      <Tooltip title='Preview SR Configuration' placement='top'>
                        <RemoveRedEyeIcon
                          sx={{ marginLeft: "10px" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            window.open("/sr/" + item?._id);
                          }}
                        />
                      </Tooltip>
                      <Tooltip title='Copy Scenario Id' placement='top'>
                        <ContentCopyIcon
                          sx={{ marginLeft: "10px" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenIdSnackbar(true);
                            navigator.clipboard.writeText(item?._id);
                          }}
                        />
                      </Tooltip>
                    </Box>
                  )}
                </Stack>
              </MediaCard>
              <Snackbar
                open={openIdSnackbar}
                autoHideDuration={1000}
                message={"Scenario Id Copied Successfully"}
                onClose={handleCloseIdSnackbar}
              />
            </Grid>
          ))}
        {/** DO NOT REMOVE THIS EMPTY GRID - START */}
        <Grid item xs={3} ref={containerRef} sx={{ height: 50 }}></Grid>
        {/** DO NOT REMOVE THIS EMPTY GRID - END */}
      </Grid>
      <ModalContainer
        open={openModal}
        handleClose={handleCloseDrawer}
        title={"Simulation Basic Info"}
      >
        <SimulationForm
          initialValues={initialValues}
          edit={false}
          setSnackBar={setSnackBar}
          setOpenModal={setOpenModal}
          getScenariosList={getScenariosList}
          onFileUpload={onFileUpload}
          onImageUpload={onImageUpload}
          onAudioUpload={onAudioUpload}
          onVideoUpload={onVideoUpload}
          fileUrl={fileUrl}
          file1Url={file1Url}
          file3Url={file3Url}
          file2Url={file2Url}
          setFile2Url={setFile2Url}
          setFileUrl={setFileUrl}
          setFile3Url={setFile3Url}
          setFile1Url={setFile1Url}
          handleAfterSubmit={handleAfterSubmit}
        />
      </ModalContainer>
      <CustomSnackbar
        open={openSnackbar}
        message={"Data Saved Successfully"}
        handleClose={handleCloseSnackbar}
      />
    </div>
  );
};

export default Category;
