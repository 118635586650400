import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";

const CssSelectField = styled(Select)(({ theme }) => ({
  width: "100%",
  color: `${theme?.palette?.primary?.contrastText} !important`,
  "& label": {
    color: `${theme?.palette?.primary?.contrastText} !important`,
    background: 'black',
  },
  "& label.Mui-focused": {
    color: `${theme?.palette?.primary?.contrastText} !important`,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: theme?.palette?.primary?.contrastText,
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: theme?.palette?.primary?.contrastText,
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: theme?.palette?.primary?.contrastText,
  },
   
}));

export default CssSelectField;
