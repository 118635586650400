import React, { useState, useRef, useContext } from "react";
import { styled } from "@mui/material/styles";
import { Avatar, IconButton, CircularProgress } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { FastField, useFormikContext } from "formik";
import CssTextField from "Components/CssTextField";
import useApi from "services/api-hook";
import useRequests from "services/request-hook";
import { GlobalState } from "context/GlobalContext";
import ImageView from "./ImageView";

const Input = styled("input")({
  display: "none",
});

const CssUploadButton = styled(IconButton)({
  position: "relative",
  top: "20px",
});

const CssCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  position: "relative",
  top: "20px",
}));

const CssUploadIcon = styled(CloudUploadIcon)({
  "& CssUploadIcon": {
    color: "#FFF",
  },
});

const CssPreviewButton = styled(IconButton)({
  position: "relative",
  top: "20px",
});

const CssPreviewIcon = styled(VisibilityIcon)({
  "& PreviewIcon": {
    color: "#FFF",
  },
});

const CssAvatar = styled(Avatar)({
  backgroundColor: "transparent",
  border: "1px dashed #FFF",
  top: "18px",
  left: "12px",
  "&.avatar": {
    borderWidth: 0,
  },
  "& svg": {
    display: "none",
  },
});

const InputFileUpload = (props) => {
  const { getSignedUrlApi, createAssetsApi } = useRequests();
  const { isPublished } = useContext(GlobalState);
  const getSignedUrl = useApi(getSignedUrlApi);
  const createAssets = useApi(createAssetsApi);
  const formContext = useFormikContext();
  const fileRef = useRef(null);
  const { onFileUpload, bucket, stackInput, acceptType, isAvatar, iconUrl, isDisabled = false } =
    props;
  const [showLoader, setShowLoader] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState("");
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [images, setImages] = useState([formContext.values[stackInput.name]]);

  const imageNames = [];

  const addAssetsToDB = (payload) => {
    createAssets
      .request(bucket, payload)
      .then((res) => {
        if (res.status === 200) {
          console.log("res", res);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const uploadFile = ({
    target: {
      files: [file],
    },
  }) => {
    setShowLoader(true);
    let formData = new FormData();
    formData.append("file", file);
    getSignedUrl
      .request(bucket || "other")
      .then((res) => {
        if (res.status === 200) {
          let client = res?.data?.client;
          let blobSAS = res?.data?.blobSAS;
          let name = file?.name.split(".");
          let fileExtension = name.pop();
          let fileName = `${name[0]}${Date.now()}.${fileExtension}`;
          const finalUrl = `${client}/${fileName}?${blobSAS}`;
          fetch(`${finalUrl}`, {
            method: "PUT",
            body: file,
            headers: {
              "x-ms-blob-type": "BlockBlob",
              "Content-Type": "multipart/form-data",
            },
          })
            .then((response) => {
              if (response.status === 201 || response.status === 200) {
                setAvatarUrl(response.url);
                onFileUpload(response);
                setShowLoader(false);
                formContext.setFieldValue(stackInput.name, fileName);
                fileRef.current.value = "";
                let fileUrl = response?.url?.substring(
                  0,
                  response?.url?.indexOf("?")
                );
                var path = fileUrl.split("/");
                fileUrl = process.env.REACT_APP_CDN_URL
                  ? `${process.env.REACT_APP_CDN_URL}/${path[3]}/${path[4]}`
                  : fileUrl;
                setImages([fileUrl]);
                let payload = {
                  fileurl: fileUrl,
                  filename: fileName,
                  type: bucket,
                };
                addAssetsToDB(payload);
              }
            })
            .catch((error) => {
              console.log("error", error);
              setShowLoader(false);
            });
        }
      })
      .catch((error) => {
        console.log("error", error);
        setShowLoader(false);
      });
  };

  const openImageViewer = () => {
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      {stackInput && (
        <FastField
          component={CssTextField}
          variant="outlined"
          size="small"
          name={stackInput.name}
          id={stackInput.name}
          value={formContext.values[stackInput.name]}
          label={stackInput.label}
          // placeholder={stackInput.placeholder}
          disabled={isDisabled}
          focused
          InputProps={{ readOnly: true }}
          helperText={props.helperText}
        />
      )}
      {isAvatar && (
        <CssAvatar
          alt={"Icon"}
          src={avatarUrl || iconUrl}
          className={avatarUrl || iconUrl ? "avatar" : ""}
        />
      )}
      <label
        htmlFor={props.id}
        style={{ display: "flex", alignItems: "start" }}
      >
        <Input
          accept={acceptType || "image/*"}
          ref={fileRef}
          id={props.id}
          disabled={isDisabled}
          type="file"
          onChange={uploadFile}
        />
        {showLoader ? (
          <CssCircularProgress size={25} />
        ) : isPublished ? null : (
          <CssUploadButton
            disabled={isDisabled}
            aria-label="upload"
            variant="contained"
            component="span"
          >
            <CssUploadIcon />
          </CssUploadButton>
        )}
        {typeof images[0] !== 'undefined' && images[0] !=="" && (
          <CssPreviewButton>
            <CssPreviewIcon onClick={openImageViewer} />
          </CssPreviewButton>
        )}
      </label>
      {isViewerOpen && (
        <ImageView
          currentImage={currentImage}
          openImageViewer={openImageViewer}
          closeImageViewer={closeImageViewer}
          isViewerOpen={isViewerOpen}
          imageNames={imageNames}
          viewFullScreen={false}
          images={images}
        />
      )}
    </>
  );
};

export default InputFileUpload;
