import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Vimeo from "@u-wave/react-vimeo";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "& .MuiOutlinedInput-input": {
      color: theme.palette.primary.contrastText,
    },
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function isVimeoUrl(url) {
  const vimeoRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com\/)([0-9]{9})(.*)?$/;
  return vimeoRegex.test(url);
}

export default function CustomizedDialogs(props) {
  const [videoDuration, setVideoDuration] = useState(+localStorage.getItem("duration") || 0);
  const [currentTime, setCurrentTime] = useState(videoDuration);

  const handleVideoPause = () => {
    setVideoDuration(currentTime);
    localStorage.setItem("duration", currentTime);
  };

  const handleVideoEnd = () => {
    setVideoDuration(0);
    localStorage.removeItem("duration");
  };

  let media = "";
  if (props.type === "audio") {
    media = (
      <audio autoPlay height={400} style={{ width: "450px" }} controls>
        <source src={props.url} />
      </audio>
    );
  } else if (props.type === "video") {
      if (isVimeoUrl(props?.url)) {
        media = (
          <div
            style={{
              width: "100%",
              height: "99%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Vimeo
              video={props?.url}
              autoplay
              preload
              start={videoDuration}
              responsive
              style={{
                width: "850px",
                height: "100%",
                padding: "0 !important",
                position: "static !important",
              }}
              onPause={handleVideoPause}
              onEnd={handleVideoEnd}
              onTimeUpdate={(time) =>
                setCurrentTime(time.seconds)
              }
            />
          </div>
        );
      } else {
        media = (
          <video autoPlay height={450} width={850} controls>
            <source src={props.url} type="video/mp4" />
          </video>
        );
      }
  } else {
    media = <img src={props.url} alt="" />;
  }

  return (
    <div>
      <BootstrapDialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth="xl"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props.handleClose}
          style={{
            // display: "block",
            width: "95%",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {props.title}
        </BootstrapDialogTitle>
        <DialogContent dividers sx={{ textAlign: "center" }}>
          {media}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
