/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, FastField, FieldArray } from "formik";
import {
  Container,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  IconButton,
  Card,
} from "@mui/material";
import * as Yup from "yup";
import "./Simulation.css";
import CssTextField from "Components/CssTextField";
import CssButton from "Components/CssButton";
import useApi from "services/api-hook";
import useRequests from "services/request-hook";
import { GlobalState } from "../../context/GlobalContext";
import CssSelectField from "Components/CssSelectField";
import FileUpload from "Components/FileUpload";
import { styled } from "@mui/material/styles";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import CssTitle from "Components/CssTitle";
import Menus from "./Menus";
import CssCircularProgress from "Components/CssCircularProgress";

const SUPPORTED_FORMATS_IMAGE = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
];

const TrackFormSchema = Yup.object().shape({
  name: Yup.string()
    // .min(5, "minimum length of a text is 5")
    .max(100, "maximum length of a text is 100")
    .matches(
      /^[A-Za-z]{2}[ A-Za-z0-9_-]*$/,
      "The Title must be alphanumeric characters only"
    ),
    // .required("Name is required"),
  calculation_type: Yup.string().required("Calculation Type is required"),
  game_duration: Yup.number()
    .typeError("Game Duration must be a number")
    .nullable(),
  decision_accuracy_threshold_percentage: Yup.number()
    .typeError("Decision accuracy threshold must be a number")
    .nullable(),
  poster_url: Yup.mixed()
    .nullable()
    .notRequired()
    .test(
      "fileType",
      "Unsupported File Format",
      (value) => value === null || SUPPORTED_FORMATS_IMAGE.includes(value?.type)
    ),
});

const CssAddIconButton = styled(IconButton)({
  position: "absolute",
  top: "33px",
  right: "30px",
});

const CssAddIcon = styled(AddCircleTwoToneIcon)({
  "& .CssAddIcon": {
    color: "#FFF",
    fontSize: "2rem",
  },
});

const CssCard = styled(Card)({
  marginTop: "20px !important",
  padding: "6px 20px 20px",
  display: "block !important",
  backgroundColor: "transparent",
  border: "1px solid #673068",
  textAlign: "center",
});

const TrackForm = ({ node, handleResponse }) => {
  const { sid } = useParams();
  const { isPublished } = useContext(GlobalState);
  const [fileUrl, setFileUrl] = useState("");
  const { trackApi, getTrackApi, updateTrackApi } = useRequests();
  const track = useApi(trackApi);
  const getTrack = useApi(getTrackApi);
  const updateTrack = useApi(updateTrackApi);
  const [callUpdate, setCallUpdate] = useState(false);
  const childFunc = React.useRef(null);
  const [saveImg, setSaveImg] = useState(node?.elementId ? true : false);
  const [onDisable, setOnDisable] = useState(node?.elementId ? true : false);
  const [btnDisable, setBtnDisable] = React.useState(false);

  const [initialValues, setInitialValues] = useState({
    name: "",
    calculation_type: "",
    game_duration: "",
    decision_accuracy_threshold_percentage: "",
    poster: null,
    poster_url: null,
    menus: [],
  });

  const onFileUpload = ({ url }) => {
    if (url) {
      let finalUrl = url?.substring(0, url?.indexOf("?"));
      var path = finalUrl.split("/");
      finalUrl = process.env.REACT_APP_CDN_URL
        ? `${process.env.REACT_APP_CDN_URL}/${path[3]}/${path[4]}`
        : finalUrl;
      setFileUrl(finalUrl);
    } else {
      setFileUrl("");
    }
  };

  const saveImage = (value) => {
    setSaveImg(value);
  };

  const saveOnDisable = (value) => {
    setOnDisable(value);
  };

  useEffect(() => {
    if (node && node.elementId) {
      getTrack.request(sid, node.elementId).then((res) => {
        if (res.data) {
          setInitialValues(res.data);
          setCallUpdate(true);
        }
      });
    }
  }, []);

  const handleSpinner = (value) => {
    setBtnDisable(value);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={TrackFormSchema}
      isInitialValid={false}
      validateOnMount={true}
      onSubmit={(values) => {
        values.poster_url = fileUrl ? fileUrl : values.poster_url;

        if (callUpdate) {
          updateTrack.request(sid, node.elementId, values).then((res) => {
            handleResponse(res);
          });
        } else {
          track.request(sid, values).then((res) => {
            handleResponse(res);
          });
        }
      }}
    >
      {({
        dirty,
        values,
        errors,
        touched,
        setFieldValue,
        isSubmitting,
        handleBlur,
        setFieldTouched,
      }) => (
        <Form noValidate={true} autoComplete='off' className='form-wrapper'>
          {typeof values?.poster_url === "string"
            ? delete errors.poster_url
            : ""}
          <Container></Container>
          <Container>
            <Grid container className='form-wrapper' direction='row'>
              <Grid item xs={12}>
                <FastField
                  component={CssTextField}
                  variant='outlined'
                  size='small'
                  name='name'
                  id='name'
                  autoComplete={"Off"}
                  value={values.name}
                  onBlur={(e) => handleBlur(e)}
                  onChange={(event) => {
                    setFieldValue("name", event.target.value);
                  }}
                  inputProps={{ readOnly: isPublished }}
                  label='Name'
                />
                {errors.name && touched.name ? (
                  <div className='field-error'>{errors.name}</div>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth size='small' sx={{ marginTop: "20px" }}>
                  <InputLabel id='type' className='select-label'>
                    Calculation Type*
                  </InputLabel>
                  <FastField
                    component={CssSelectField}
                    variant='outlined'
                    size='small'
                    name='calculation_type'
                    id='calculation_type'
                    autoComplete={"Off"}
                    value={values.calculation_type}
                    onBlur={(e) => handleBlur(e)}
                    onChange={(event) => {
                      setFieldValue("calculation_type", event.target.value);
                    }}
                    inputProps={{ readOnly: isPublished }}
                    label='Calculation Type*'
                  >
                    <MenuItem key={0} value={"kpi"}>
                      {`Kpi`}
                    </MenuItem>
                    <MenuItem key={1} value={"score"}>
                      {`Score`}
                    </MenuItem>
                  </FastField>
                  {errors.calculation_type && touched.calculation_type ? (
                    <div className='field-error'>{errors.calculation_type}</div>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FastField
                  component={CssTextField}
                  variant='outlined'
                  size='small'
                  name='game_duration'
                  id='game_duration'
                  type='text'
                  autoComplete={"Off"}
                  onKeyDown={(evt) =>
                    (evt.key === "e" ||
                      evt.key === "E" ||
                      evt.key === "." ||
                      evt.key === "-") &&
                    evt.preventDefault()
                  }
                  value={values.game_duration}
                  onBlur={(e) => handleBlur(e)}
                  onChange={(e) => {
                    const re = /^[0-9]+$/;
                    if (e.target.value === "" || re.test(e.target.value))
                      setFieldValue("game_duration", e.target.value);
                  }}
                  inputProps={{ readOnly: isPublished }}
                  label='Game Duration'
                />
                {errors.game_duration && touched.game_duration ? (
                  <div className='field-error'>{errors.game_duration}</div>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <FastField
                  component={CssTextField}
                  variant='outlined'
                  size='small'
                  name='decision_accuracy_threshold_percentage'
                  id='decision_accuracy_threshold_percentage'
                  type='text'
                  autoComplete={"Off"}
                  onBlur={(e) => handleBlur(e)}
                  onKeyDown={(evt) =>
                    (evt.key === "e" || evt.key === "E" || evt.key === "-") &&
                    evt.preventDefault()
                  }
                  value={values.decision_accuracy_threshold_percentage}
                  onChange={(e) => {
                    const re = /^[0-9]*\.?[0-9]*$/;
                    if (e.target.value === "" || re.test(e.target.value))
                      setFieldValue(
                        "decision_accuracy_threshold_percentage",
                        e.target.value
                      );
                  }}
                  inputProps={{ readOnly: isPublished }}
                  label='Decision Accuracy Threshold'
                />
                {errors.decision_accuracy_threshold_percentage &&
                  touched.decision_accuracy_threshold_percentage ? (
                  <div className='field-error'>
                    {errors.decision_accuracy_threshold_percentage}
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <div style={{ width: "100%", paddingTop: "5px" }}>
                  <FileUpload
                    name='poster_url'
                    id='poster_url'
                    multiple={false}
                    selectedFile={values?.poster_url}
                    saveImage={saveImage}
                    onFileUpload={onFileUpload}
                    accept={{ "image/*": [".jpeg", ".png"] }}
                    childFunc={childFunc}
                    bucket={"track-images"}
                    isValid={false}
                    onDisable={onDisable}
                    saveOnDisable={saveOnDisable}
                    title='Poster*'
                    handleSpinner={handleSpinner}
                  />
                  {errors.poster_url && errors.poster_url ? (
                    <div
                      className='errorText'
                      style={{ top: 0, position: "relative" }}
                    >
                      {errors.poster_url}
                    </div>
                  ) : null}
                </div>
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: "100px" }}>
                <Grid
                  container
                  className='form-wrapper menus-form-wrapper'
                  direction='row'
                >
                  <FieldArray name='menus'>
                    {({ insert, remove, push }) => (
                      <>
                        <Grid
                          item
                          xs={12}
                          className='field-array-title-wrapper'
                        >
                          <Typography
                            variant='h5'
                            className='field-array-title'
                          >
                            Menus
                          </Typography>
                          {isPublished ? null : (
                            <CssAddIconButton
                              onClick={() =>
                                push({
                                  type: "",
                                  name: "",
                                  menu_items: [
                                    {
                                      label: "",
                                      content_type: "2D",
                                      content: "",
                                    },
                                  ],
                                })
                              }
                            >
                              <CssAddIcon />
                            </CssAddIconButton>
                          )}
                        </Grid>
                        {values?.menus?.length === 0 ? (
                          <Grid
                            container
                            component={CssCard}
                            className='card-wrapper'
                          >
                            <CssTitle
                              variant='h6'
                              sx={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                !isPublished &&
                                push({
                                  type: "",
                                  name: "",
                                  menu_items: [
                                    {
                                      label: "",
                                      content_type: "2D",
                                      content: "",
                                    },
                                  ],
                                })
                              }
                            >
                              Add Menus
                            </CssTitle>
                          </Grid>
                        ) : (
                          values?.menus?.map((item, index) => (
                            <Menus
                              key={index}
                              values={values}
                              index={index}
                              errors={errors}
                              touched={touched}
                              onRemove={remove}
                              menus={item}
                              enableClose={values.menus.length > 0}
                              onSetFieldValue={setFieldValue}
                              handleBlur={handleBlur}
                            />
                          ))
                        )}
                      </>
                    )}
                  </FieldArray>
                </Grid>
              </Grid>
              <Grid
                className='button-stack form-btn-wrapper'
                item
                xs={12}
                style={{ padding: 0 }}
              >
                <Stack spacing={2} direction='row'>
                  {node?.elementId ? (
                    <CssButton
                      variant='contained'
                      size='medium'
                      type='submit'
                      disabled={
                        !dirty ||
                        isPublished ||
                        isSubmitting ||
                        !saveImg ||
                        values.poster_url === null ||
                        btnDisable
                      }
                    >
                      {isSubmitting ? <CssCircularProgress size={20} /> : "Update"}
                    </CssButton>
                  ) : (
                    <CssButton
                      variant='contained'
                      size='medium'
                      type='submit'
                      disabled={
                        !saveImg || isPublished || isSubmitting || btnDisable
                      }
                    >
                      {isSubmitting ? <CssCircularProgress size={20} /> : "Save"}
                    </CssButton>
                  )}
                  {node && node.elementId ? null : (
                    <CssButton
                      type='reset'
                      variant='contained'
                      onClick={() => {
                        childFunc.current();
                      }}
                      size='medium'
                      sx={{ backgroundColor: "#673068" }}
                      disabled={!dirty}
                    >
                      Reset
                    </CssButton>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default TrackForm;
