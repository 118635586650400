import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useRequests from "services/request-hook";
import useApi from "services/api-hook";
import "./Meeting.css";
import { Grid, Avatar, Stack, Typography, IconButton } from "@mui/material";
import CssTitle from "Components/CssTitle";
import moment from "moment";
import QRCode from "qrcode.react";
import MediaCard from "Components/MediaCard";
import CssButton from "Components/CssButton";
import KeyboardBackspace from "@mui/icons-material/KeyboardBackspace";

const MeetingDetails = (props) => {
    const { mid } = useParams();
    const { getMeetingDetailsApi } = useRequests();
    const getMeetingDetails = useApi(getMeetingDetailsApi);
    const [meetingData, setMeetingData] = useState({});
    const navigate = useNavigate()
    useEffect(() => {
        getMeetingDetails
            .request(mid)
            .then((res) => {
                if (res.status === 200) {
                    setMeetingData(res.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDownload = () => {
        const canvas = document.getElementById("qrCode");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${meetingData?.meeting_url}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };
    
    return (
        <>
            <Grid container spacing={2}> 
                <Grid item xs={12}>
                   <CssTitle variant='h4' align="left">
                        <IconButton
                            edge='start'
                            onClick={() => navigate('/meetings')}
                            aria-label='back'
                            className='icon-close'
                            size='small'
                            title="Back" 
                            sx={{marginRight: '10px'}}
                        >
                           <KeyboardBackspace />
                        </IconButton>
                        Meeting Details
                    </CssTitle>
                </Grid>
                <Grid item xs={12}>
                    <div>
                        <CssTitle variant='h5' className="truncate">Title: {meetingData?.title}</CssTitle>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className='container-Text'>
                        <div className='date-Text'>
                            <CssTitle variant='h5'>
                                Start Date:{" "}
                                {moment(meetingData?.start_at).format(
                                    "MMM/DD/YYYY,h:mm:ss a"
                                ) || `---`}
                            </CssTitle>
                            <CssTitle variant='h5'>
                                End Date:{" "}
                                {moment(meetingData?.end_at).format("MMM/DD/YYYY,h:mm:ss a") ||
                                    `---`}
                            </CssTitle>
                        </div>
                        <div className="addContainer">
                            <CssTitle component='p' className="truncate">
                                City: {meetingData?.location?.city || `---`}
                            </CssTitle>
                            <CssTitle component='p' className="truncate" >
                                State: {meetingData?.location?.state || `---`}
                            </CssTitle>
                            <CssTitle component='p' className="truncate">
                                Country: {meetingData?.location?.country || `---`}
                            </CssTitle>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        spacing={2}
                    >
                        <Grid item xs={8}>
                            <Stack direction='row' alignItems='center' spacing={7}>
                                <Grid item xs={4}>
                                    <CssTitle component='p' sx={{ padding: "5px" }}>
                                        Simulation
                                    </CssTitle>
                                    <MediaCard
                                        image={meetingData?.scenario_banner_img}
                                        alt={""}
                                        title={meetingData?.scenario_title}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <CssTitle component='p' sx={{ padding: "5px" }}>
                                        Moderator Name
                                    </CssTitle>
                                    <MediaCard
                                        image={meetingData?.moderator_avatar}
                                        alt={""}
                                        title={meetingData?.moderator_name}
                                        actionShow={false}
                                    />
                                </Grid>
                            </Stack>
                        </Grid>
                        <Grid item xs={2}>
                            <CssTitle component='p' sx={{ padding: "5px" }}>
                                QR Code
                            </CssTitle>
                            <Typography
                                component='div'
                                sx={{
                                    textAlign: "center",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    background: "#673068",
                                    padding: "10px",
                                }}
                            >
                                <QRCode
                                    id='qrCode'
                                    size={180}
                                    value={meetingData?.meeting_url || ""}
                                />
                            </Typography>
                            <CssButton
                                variant='contained'
                                onClick={handleDownload}
                                sx={{ marginTop: "5px" }}
                            >
                                Download
                            </CssButton>
                        </Grid>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <CssTitle variant='h5' sx={{ marginBottom: "10px" }}>
                        Sponsors
                    </CssTitle>
                    <Stack direction='row' spacing={7}>
                        {meetingData?.sponsors?.map((item, i) => (
                            <div>
                                <Avatar
                                    alt=''
                                    src={item?.logo_url}
                                    sx={{ width: 60, height: 60 }}
                                />
                                <CssTitle
                                    component='p'
                                    textAlign={"center"}
                                    sx={{ marginTop: "5px" }}
                                >
                                    {item?.name}
                                </CssTitle>
                            </div>
                        ))}
                    </Stack>
                </Grid>
            </Grid>
        </>
    );
};
export default MeetingDetails;
