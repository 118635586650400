import keyCloakOptions from '../keycloak.json';
import CustomKeycloak from './CustomKeycloak';

const orgRealm = 'axon';

const keyCloakInitOptions = {
  ...keyCloakOptions,
  realm: orgRealm,
  url: process.env.REACT_APP_KEYCLOAK_AUTH,
  resource: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  clientId: `${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`,
};

const _kc = new CustomKeycloak(keyCloakInitOptions);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc.init({
    onLoad: 'login-required'
  })
    .then((authenticated) => {
      if (!authenticated) {
        console.log('user is not authenticated..!');
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.customUpdateToken(5)
    .then(successCallback)
    .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const getProfile = async () => {
  let profile = null;

  profile = await _kc.loadUserProfile()
    .then((p) => p).catch(() => {
      console.log('Failed to load user profile');
    });

  return profile;
};

const Auth = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getProfile
};

export default Auth;