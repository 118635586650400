/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { GlobalState } from 'context/GlobalContext';
import AppBar from 'Components/AppBar';
import Drawer from 'Components/Drawer';
import CssMainContainer from 'Components/CssMainContainer';
import CustomSnackbar from 'Components/CustomSnackbar';
import CssIconButton from 'Components/CssIconButton';
import Auth from '../services/auth';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import ConfirmDialog from 'Components/ConfirmDialog';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { useSelector } from 'react-redux';
import userAllowedRoles from "data/roles.json";

const listItems = [
  { icon: <HomeIcon />, label: 'Home', to: '/' },
  { icon: <NotStartedIcon />, label: 'Media', to: '/media' },
  // { icon: <CalendarMonthIcon />, label: 'Meetings', to: '/meetings' }, if meeting menu is required uncomment this
  { icon: <PersonAddIcon />, label: 'Create User', to: '/create-user' },
  { icon: <DynamicFeedIcon />, label: 'APA Theme', to: '/create-apa-theme' },
  // { icon: <InstallDesktopIcon />, label: 'SR Desktop', to: '/SR-Desktop' },
  { icon: <QueryStatsIcon />, label: "Run Statistics", to: "/run-statistics" }
];

const Layout = ({ children }) => {
  const theme = useTheme();
  const roles = useSelector(state => state?.userReducer?.roles);
  let listItemsToBeShown = listItems;
  const isRunStatistics = roles?.find(item => userAllowedRoles?.runStatesReport?.includes(item));
  const location = useLocation();

  if (!roles?.includes("Super Admin")) {
    // We remove User tab if user role is not Super Admin
    listItemsToBeShown = listItemsToBeShown.filter(item => item.label !== "Create User");
  }
  if (!isRunStatistics) {
    // We remove Run Statistics tab if user role is not "Associate Configurator_Medical", "Associate Configurator_Dental", "Associate Configurator_Cyber", "Associate Configurator_Emergence", 
    listItemsToBeShown = listItemsToBeShown.filter(item => item.label !== "Run Statistics");
  }

  const navigate = useNavigate();
  const {
    openApiSnackbar,
    setOpenApiSnackbar,
    apiSnackbarMessage,
    apiSnackbarSeverity,
    mode,
    setMode,
    isFullscreen,
    setIsFullscreen
  } = useContext(GlobalState);
  const [open, setOpen] = useState(false);
  const [logoutStatusAlert, setLogoutStatusAlert] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const localThemes = localStorage.getItem("themes")

  useEffect(() => {
    if (localThemes) {
      setMode(localThemes);
    }
  }, [mode])

  const getFullscreenElement = () => {
    return document.fullscreenElement
      || document.webkitFullscreenElement
      || document.mozFullscreenElement
      || document.msFullscreenElement
  }

  const toggleFullscreen = () => {
    if (getFullscreenElement()) {
      document.exitFullscreen();
      setIsFullscreen(false)
    } else {
      setIsFullscreen(true)
      document.documentElement.requestFullscreen().catch((err) => console.log(err));
    }
  }

  const handleToggleDrawer = () => {
    setOpen(prev => !prev);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    setLogoutStatusAlert(true)
  };

  const handleCloseApiSnackbar = () => {
    setOpenApiSnackbar(false);
  };

  const onApaThemeStatusUpdate = (isUpdate) => {
    if (isUpdate) {
      setLogoutStatusAlert(false);
      Auth.doLogout();
    } else {
      setLogoutStatusAlert(false);
    }
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  return (
    <>
      <AppBar position='sticky' open={open}>
        <div style={{ display: 'flex' }}>
          <div className='logo-container' onClick={() => navigate('/')}>
            {theme.palette.mode === 'dark' ? (
              <img
                src='/images/Axon_config.png'
                alt='logo'
                style={{ width: 'inherit' }}
              />
            ) : (
              <img
                src='/images/Axon_config.png'
                alt='logo'
                style={{ width: 'inherit', objectFit: 'cover' }}
              />
            )}
            {/* <Typography sx={{ fontSize: '12px' }}>
              Experience Realism
            </Typography> */}
          </div>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {!isFullscreen ? (
              <CssIconButton
                size='large'
                aria-label='show 4 new mails'
                title='Full Screen'
                onClick={toggleFullscreen}
              >
                <FullscreenIcon />
              </CssIconButton>
            ) : (
              <CssIconButton
                size='large'
                aria-label='show 4 new mails'
                title='Exit Full Screen'
                onClick={toggleFullscreen}
              >
                <FullscreenExitIcon />
              </CssIconButton>
            )
            }
            <CssIconButton size='large'
              onClick={
                () => {
                  let modes = mode === 'dark' ? 'light' : 'dark'
                  setMode(modes);
                  localStorage.setItem("themes", modes);
                }}
            >
              {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </CssIconButton>
            <CssIconButton
              size='large'
              edge='end'
              aria-label='account of current user'
              aria-haspopup='true'
              onClick={handleOpenPopover}
            >
              <PersonOutlineOutlinedIcon />
            </CssIconButton>
          </Box>
        </div>
      </AppBar>
      <Box sx={{ display: 'flex', height: 'calc(100vh - 80px)' }}>
        <Drawer
          variant='permanent'
          open={open}
        >
          <List sx={{ marginTop: '6rem' }}>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={handleToggleDrawer}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {open ? (
                    <CssIconButton
                      sx={{ padding: '0px' }}
                    >
                      <CloseIcon />
                    </CssIconButton>
                  ) : (
                    <CssIconButton
                      sx={{ padding: '0px' }}
                    >
                      <MenuIcon />
                    </CssIconButton>
                  )}
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            {listItemsToBeShown.map((item, i) => (
              <ListItem disablePadding sx={{ display: 'block', background: location.pathname === item?.to ? (mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)') : 'transparent' }} key={i}>
                <NavLink to={item?.to} sx={{ textDecoration: 'none' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center'
                      }}
                      title={item.label}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.label}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </NavLink>
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Popover
          id={id}
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box onClick={handleLogout} sx={{ display: 'flex', height: 'inherit', p: 2, gap: 2, cursor: 'pointer', background: '#673068', color: '#fff' }}>
            <CssIconButton
              sx={{ padding: '0px', color: '#fff' }}
            >
              <LogoutIcon />
            </CssIconButton>
            Logout
          </Box>
        </Popover>
        <CssMainContainer
          component='main'
          sx={{ flexGrow: 1, p: 3 }}
          className='scrollbar-Wrapper'
        >
          {children}
        </CssMainContainer>
        <CustomSnackbar open={openApiSnackbar} message={apiSnackbarMessage} severity={apiSnackbarSeverity} handleClose={handleCloseApiSnackbar} />
        <ConfirmDialog
          open={logoutStatusAlert}
          text={`Are you sure, you would like to logout?`}
          handleYesNo={onApaThemeStatusUpdate}
        />
      </Box>
    </>
  );
};

export default Layout;
