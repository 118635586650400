export const ROLES_ACTION = {
  UPDATE_ROLES: "UPDATE_ROLES"
};

// For reference
// const roles = [
//   "Super Admin",
//   "Super Configurator",
//   "DomainConfigurator_Medical",
//   "DomainConfigurator_Cyber",
//   "DomainConfigurator_Dental",
//   "DomainConfigurator_Emergence",
//   "AssociateConfigurator",
//   "Associate Configurator_Medical",
//   "Associate Configurator_Dental",
//   "Associate Configurator_Cyber",
//   "Associate Configurator_Emergence",
// ];

export const updateRoles = (data) => {
  return {
    type: ROLES_ACTION.UPDATE_ROLES,
    data,
  };
};