import { useCallback, useContext, useState } from 'react';
import {
  Grid, Card, IconButton, Typography, FormControl, InputLabel, MenuItem,
  CircularProgress,
  Avatar,
  CardMedia,
  TextField,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import { FastField, FieldArray } from 'formik';
import CssTextField from 'Components/CssTextField';
import CssSelectField from 'Components/CssSelectField'
import MenuItems from './MenuItems';
import { GlobalState } from "context/GlobalContext";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddLinkIcon from '@mui/icons-material/AddLink';
import useApi from 'services/api-hook';
import useRequests from 'services/request-hook';
import ImageView from 'Components/ImageView';


const CssCard = styled(Card)({
  marginTop: '30px !important',
  padding: '6px 20px 20px',
  position: 'relative',
  backgroundColor: 'transparent',
  border: '1px solid #673068'
});

const CssAddIconButton = styled(IconButton)({
  position: 'absolute',
  top: '-8px',
  right: '-8px'
});

const CssAddIcon = styled(AddCircleTwoToneIcon)({
  color: '#673068',
  fontSize: '2rem'
});

const CssCloseIconButton = styled(IconButton)({
  position: 'absolute',
  top: '4px',
  right: '4px',
  border: '1px solid #F00',
  padding: 0
});

const CssCloseIcon = styled(CloseIcon)({
  color: '#F00',
  fontSize: '1.2rem'
});

const CssUploadButton = styled(IconButton)({
  position: "relative",
});

const CssUploadIcon = styled(CloudUploadIcon)({
  color: "#FFF",
});

const CssLinkIcon = styled(AddLinkIcon)({
  color: "#FFF",
})

const CssCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  position: "relative",
  left: "20px",
}));

const CssAvatar = styled(Avatar)(({ theme }) => ({
  position: 'static',
  backgroundColor: "transparent",
  border: "1px dashed",
  borderColor: theme.palette.primary.contrastText,
  marginRight: '5px',
  "&.avatar": {
    borderWidth: 0,
  },
  "& svg": {
    display: "none",
  },
}));

const CssAvatarCard = styled(CardMedia)({
  backgroundColor: "transparent",
  border: "1px dashed #FFF",
  borderRadius: "50%",
  width: "100%",
  height: "100%",
  "&.avatar": {
    borderWidth: 0,
  },
  "& svg": {
    display: "none",
  },
});

const nameOptions = [
  "Medical Records", 
  "Faculty Video",
  "Expert Presentation",
  "Poster Portal",
  "Patient Journey",
  "Additional Educational Resources",
  "References",
  "Custom Name"
];

const Menus = (props) => {
  const { values, index, enableClose, onRemove, onSetFieldValue, errors, touched, menus, handleBlur } = props;
  const { isPublished } = useContext(GlobalState);
  const { getSignedUrlApi, createAssetsApi } = useRequests();
  const getSignedUrl = useApi(getSignedUrlApi);
  const createAssets = useApi(createAssetsApi);
  const [showLoader, setShowLoader] = useState(false);
  const [url, setUrl] = useState("");
  const [images, setImages] = useState([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(index);
  const [openURL, setOpenURL] = useState(false);
  const isCustomName = menus?.name ? !nameOptions.includes(menus?.name) : false;
  const [showUploadURL, setShowUploadURL] = useState(isCustomName || false);

  const handleOpenURL = () => {
    setOpenURL(open => !open);
  }

  const addAssetsToDB = (payload) => {
    createAssets
      .request("axontrailers", payload)
      .then((res) => {
        if (res.status === 200) {
          console.log("res", res);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const fetchAndUploadFile = (file) => {
    // if (!file || !file.name.match(/^[a-zA-Z0-9_\-]+\.[a-zA-Z0-9]+$/)) {
    //   // Invalid file name
    //   setShowLoader(false);
    //   setInvalidUrlError(true);
    //   return null;
    // } 
    getSignedUrl
      .request("axontrailers")
      .then((res) => {
        if (res.status === 200) {
          let client = res?.data?.client;
          let blobSAS = res?.data?.blobSAS;
          let name = file?.name.split(".");
          let fileExtension = name?.pop();
          let fileName = `${name[0]}${Date.now()}.${fileExtension}`;
          const finalUrl = `${client}/${fileName}?${blobSAS}`;
          fetch(`${finalUrl}`, {
            method: "PUT",
            body: file,
            headers: {
              "x-ms-blob-type": "BlockBlob",
              "Content-Type": "multipart/form-data",
            },
          })
            .then((response) => {
              if (response.status === 201 || response.status === 200) {
                let fileUrl = response?.url?.substring(
                  0,
                  response?.url?.indexOf("?")
                );
                var path = fileUrl.split("/");
                fileUrl = process.env.REACT_APP_CDN_URL
                  ? `${process.env.REACT_APP_CDN_URL}/${path[3]}/${path[4]}`
                  : fileUrl;
                onSetFieldValue(
                  `menus.${index}.content`,
                  fileUrl
                );
                let payload = {
                  fileurl: fileUrl,
                  filename: fileName,
                  type: "axontrailers",
                };
                addAssetsToDB(payload);
                setShowLoader(false);
                setOpenURL(false);
                setUrl("");
              }
            })
            .catch((error) => {
              console.log("error", error);
              setShowLoader(false);
              // setInvalidUrlError(true);
            });
        }
      })
      .catch((error) => {
        console.log("error", error);
        setShowLoader(false);
        // setInvalidUrlError(true);
      });
  };

  const handleUploadFile = (file) => {
    setShowLoader(true);
    // setInvalidUrlError(false);
    if (file instanceof File) {
      fetchAndUploadFile(file);
    } else {
      fetch(file)
        .then((response) => response.blob())
        .then((blob) => {
          const urlFile = new File([blob], `urlFile.${blob.type.split('/')[1]}`, {
            type: blob.type,
            lastModified: Date.now(),
          });
          // if (!urlFile.name.match(/^[a-zA-Z0-9_\-]+\.[a-zA-Z0-9]+$/)) {
          //   setShowLoader(false);
          //   // setInvalidUrlError(true);
          //   return null;
          // } else {
            fetchAndUploadFile(urlFile);
          // }
        })
        .catch((error) => {
          console.error("Error fetching file from URL:", error);
          setShowLoader(false);
          // setInvalidUrlError(true);
        });
    }
  };

  const openImageViewer = useCallback((index, imageUrl, e) => {
    setImages([imageUrl]);
    setCurrentImage(index);
    setIsViewerOpen(true);
    e.stopPropagation();
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Grid item container component={CssCard} className='card-wrapper'>
      <Grid item xs={5} sx={{ marginTop: "20px" }}>
        <FormControl fullWidth size='small' sx={{ width: "90%" }}>
          <InputLabel id='type' className='select-label'>
            Type
          </InputLabel>
          <FastField
            component={CssSelectField}
            variant='outlined'
            labelId='type'
            size='small'
            name={`menus.${index}.type`}
            id={`menus.${index}.type`}
            label='Type'
            onBlur={handleBlur}
            defaultValue=''
            value={menus?.type}
            onChange={(e) => {
              onSetFieldValue(`menus.${index}.type`, e.target.value);
            }}
            inputProps={{ readOnly: isPublished }}

          >
            <MenuItem key={0} value={'vertical'}>
              {`Vertical`}
            </MenuItem>
            <MenuItem key={1} value={'horizontal'}>
              {`Horizontal`}
            </MenuItem>
          </FastField>
        </FormControl>
        {errors &&
          errors.menus &&
          errors.menus[index] &&
          errors.menus[index].type &&
          (touched &&
            touched.menus &&
            touched.menus[index] &&
            touched.menus[index].type) && (
            <div className='field-error'>
              {errors.menus[index].type}
            </div>
          )}
      </Grid>
      <Grid item xs={5} sx={{ marginTop: "20px" }}>
        <FormControl fullWidth size='small' sx={{ width: "90%" }}>
          <InputLabel id='name' className='select-label'>
            Name
          </InputLabel>
          <FastField
            component={CssSelectField}
            variant='outlined'
            labelId='name'
            size='small'
            name={`menus.${index}.name`}
            id={`menus.${index}.name`}
            label='Name'
            onBlur={handleBlur}
            defaultValue=''
            value={isCustomName ? "Custom Name" : menus?.name}
            onChange={(e) => {
              if (e.target?.value == "Custom Name") {
                setShowUploadURL(true);
              } else {
                setShowUploadURL(false);
              }
              onSetFieldValue(`menus.${index}.name`, e.target.value);
            }}
            inputProps={{ readOnly: isPublished }}
          >
            {nameOptions?.map((item, index) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </FastField>
        </FormControl>
        {errors &&
          errors.menus &&
          errors.menus[index] &&
          errors.menus[index].type &&
          (touched &&
            touched.menus &&
            touched.menus[index] &&
            touched.menus[index].type) && (
            <div className='field-error'>
              {errors.menus[index].type}
            </div>
          )}
      </Grid>
      {showUploadURL && <Grid item xs={5}>
        <FastField
          component={CssTextField}
          variant='outlined'
          size='small'
          name={`menus.${index}.customName`}
          id={`menus.${index}.customName`}
          value={isCustomName ? menus?.name : menus?.customName}
          autoComplete="Off"
          onBlur={handleBlur}
          InputProps={{
            className: 'text-field'
          }}
          onChange={(e) => {
            onSetFieldValue(`menus.${index}.customName`, e.target.value);
          }}
          inputProps={{ readOnly: isPublished }}
          label='Custom Name' />
      </Grid>}
      {showUploadURL && <Grid item xs={7} display="flex">
        <div style={{display:'flex', marginTop: '20px'}}>
          <CssAvatar
            alt={"Icon"}
            className={menus?.content ? "avatar" : ""}
          >
            {menus?.content && menus?.content !== "" && (
              <CssAvatarCard
                className={menus?.content ? "avatar" : ""}
                component="img"
                image={menus?.content}
                alt={"Icon"}
                onClick={(e) => openImageViewer(0, menus?.content, e)}
                style={{ cursor: "pointer" }}
              />
            )}
          </CssAvatar>
          <label htmlFor={`menus.${index}.content`}>
            <input
                id={`menus.${index}.content`}
                name={`menus.${index}.content`}
                disabled={isPublished}
                type="file"
                onChange={(event) =>
                  handleUploadFile(event.currentTarget.files[0])
                }
                className="form-control"
                style={{ display: "none" }}
                accept={"image/*"}
                inputProps={{ readOnly: isPublished }}
                InputProps={{
                  className: "text-field",
                }}
              />
            {showLoader ? (
              <CssCircularProgress size={30} />
            ) : <>
              <CssUploadButton
                aria-label="upload"
                variant="contained"
                component="span"
                >
                <CssUploadIcon />
              </CssUploadButton>
              <CssUploadButton
                variant="contained"
                >
                <CssLinkIcon onClick={handleOpenURL}/>
              </CssUploadButton>
            </>}
          </label>
        </div>
        {openURL && <div style={{ display: "flex",alignItems:"center", marginLeft: 'auto', marginTop: '12px' }}>
          <div style={{ fontSize: "14px", color: "white", marginRight: "10px", marginTop: '8px'}}>{`URL`} </div>
          <TextField
            aria-label="upload"
            placeholder="Enter URL"
            onChange={(event) => {
              setUrl(event.target.value)
            }}
            variant="outlined"
            margin="dense"
            InputProps={{ style: { height: "30px",width:'13.3rem' } }}
          />
          {/* {invalidUrlError && <div style={{ color: 'red', fontSize: '14px', marginLeft: '4rem', marginTop: '4px' }}>{`Invalid File Format`}</div>} */}
          <Button onClick={(e) => {
            handleUploadFile(url)
            e.preventDefault();
          }} style={{ color: 'white', marginTop: '8px' }} >
            Add
          </Button>
        </div>}
      </Grid>}
      {(enableClose && !isPublished) &&
        <CssCloseIconButton onClick={() => onRemove(index)}>
          <CssCloseIcon />
        </CssCloseIconButton>
      }
      <Grid item xs={12} className='menu-items-wrapper'>
        <FieldArray name={`menus.${index}.menu_items`}>
          {({ insert, remove, push }) => (
            <>
              <Grid item xs={12} className='field-array-title-wrapper'>
                <Typography variant='h6' className='field-array-title'>Menu Items</Typography>
                {isPublished ? null : (
                  <CssAddIconButton onClick={() => push({ label: '', content_type: '2D', content: '' })}>
                    <CssAddIcon />
                  </CssAddIconButton> 
                )} 
              </Grid>
              {values.menus[index].menu_items.map((item, i) => <MenuItems
                key={i}
                menuIndex={index}
                index={i}
                errors={errors}
                touched={touched}
                onRemove={remove}
                menuItems={item}
                enableClose={values.menus[index].menu_items.length > 1}
                onSetFieldValue={onSetFieldValue}
                isPublished={isPublished}
                bucket={"axontrailers"}
              />)}
            </>
          )}
        </FieldArray>
      </Grid>
      {isViewerOpen && (
        <ImageView
          currentImage={currentImage}
          openImageViewer={openImageViewer}
          closeImageViewer={closeImageViewer}
          isViewerOpen={isViewerOpen}
          imageNames=""
          viewFullScreen={false}
          images={images}
        />
      )}
    </Grid>
  );
};

export default Menus;