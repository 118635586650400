import React from 'react';
import { 
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import CssButton from 'Components/CssButton';

const ConfirmDialog = (props) => {
  const { open, text, handleYesNo } = props;

  return (
    <Dialog
      open={open}
      keepMounted
    >
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CssButton 
          variant='contained'
          size='medium'
          onClick={() => handleYesNo(false)}
          autoFocus
          className='button-alert'
        >
          No
        </CssButton>
        <CssButton 
          variant='contained'
          size='medium'
          onClick={() => handleYesNo(true)}
          autoFocus
          className='button-alert'
        >
          Yes
        </CssButton>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;