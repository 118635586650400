import React from 'react';
import { 
  Grid, Typography
} from '@mui/material';
import assets from 'data/assets.json';
import FolderImage from 'Assets/folder_img.png';
import './SystemFiles.css';

const MediaFolders = (props) => {
  const { onFolderClick } = props;

	return (
		<Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          {assets?.map((list, index) => (
            <Grid
              item
              xs={2}
              key={index}
              className='folder-wrapper'
            >
              <div>
                <img
                  src={FolderImage}
                  alt=''
                  className={'folder'}
                  onClick={() => onFolderClick(list?.value, list?.label)}
                />
                <Typography className={'folder-name'}>
                  {list?.label}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
	);
};

export default MediaFolders;
