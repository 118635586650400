import { styled } from '@mui/material/styles';
import { Dialog } from "@mui/material";

const CssDialogBox = styled(Dialog)({
      //       "& .MuiPaper-root": {
      //             background:"#212529",
      //              color:"#fff"           
      //   }, 
}); 

export default CssDialogBox;