import React, { useState, useContext } from "react";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { GlobalState } from "context/GlobalContext";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "./SystemFiles.css";
import MediaFolders from "./MediaFolders";
import Assets from "./Assets";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.3),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.5),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "25ch",
      "&:focus": {
        width: "25ch",
      },
    },
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: "none",
  },
  "& .modal-title": {
    color: theme.palette.primary.contrastText,
  },
  "& .icon-close": {
    color: theme.palette.primary.contrastText,
  },
  "& .folder-name": {
    color: theme.palette.primary.contrastText,
  },
}));

const SystemFiles = (props) => {
  const { open, setOpenSystemFiles, onCaptureFile, accept } = props;
  const { showSystemAssets, setShowSystemAssets } = useContext(GlobalState);
  const [assetType, setAssetType] = useState("");
  const [assetLabel, setAssetLabel] = useState("");
  const [text, setText] = useState("");

  const onAssetClick = (img) => {
    onCaptureFile(img);
    setShowSystemAssets(false);
    setOpenSystemFiles(false);
  };

  const handleClose = () => {
    setText("");
    setShowSystemAssets(false);
    setOpenSystemFiles(false);
  };

  const handleBack = () => {
    setText("");
    if (assetType && showSystemAssets) {
      setShowSystemAssets(false);
    } else {
      setOpenSystemFiles(false);
    }
  };

  const onFolderClick = (type, label) => {
    setAssetType(type);
    setShowSystemAssets(true);
    setAssetLabel(label);
  };

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      maxWidth={"md"}
      fullWidth
      keepMounted
      className="SystemModalContainer"
    >
      <DialogTitle className="modal-title">
        {showSystemAssets ? (
          <IconButton
            edge="start"
            onClick={handleBack}
            aria-label="back"
            className="icon-close"
            size="large"
          >
            <KeyboardBackspaceIcon />
          </IconButton>
        ) : null}
        {showSystemAssets ? (
          <div style={{ display: "flex" }}>
            {assetLabel}{" "}
            <Search style={{marginLeft: "50px"}}>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => setText(e.target.value)}
              />
            </Search>
          </div>
        ) : (
          <div>System Files</div>
        )}
        <IconButton
          edge="start"
          onClick={handleClose}
          aria-label="close"
          className="icon-close"
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {showSystemAssets ? (
          <Assets
            type={assetType}
            accept={accept}
            onAssetClick={onAssetClick}
            showImages={true}
            searchText={text}
          />
        ) : (
          <MediaFolders onFolderClick={onFolderClick} />
        )}
      </DialogContent>
    </StyledDialog>
  );
};

export default SystemFiles;
