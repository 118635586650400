const hex = (value) => {
  return Math.floor(value).toString(16)
};

export const ObjectId = () => {
  return hex(Date.now() / 1000) +
    ' '.repeat(16).replace(/./g, () => hex(Math.random() * 16))
};

export const MergeObjectIntoArray = (array, object) => {
  return array.map(o => {
    if (o.id === object.id) {
      return Object.assign({}, o, object);
    }
    return o;
  });
};

export const truncate = (str, n) => {
  return (str.length > n) ? str.substr(0, n-1) + '...' : str;
};
