import React, { useContext, useEffect } from 'react';
import { createTheme } from '@mui/material/styles';
import { GlobalStyles, ThemeProvider } from '@mui/material';
import { getTheme } from './Theme/theme';
import CustomRoutes from './Routes/routes';
import './App.css';
import { GlobalState } from 'context/GlobalContext';
import Auth from "services/auth";
import { useDispatch, useSelector } from 'react-redux';
import useRequests from 'services/request-hook';
import useApi from 'services/api-hook';
import { updateRoles } from 'state/actions/user';

const App = () => {
  const { getUserRolesApi } = useRequests();
  const dispatch = useDispatch();
  const getRolesList = useApi(getUserRolesApi);
  const { mode } = useContext(GlobalState);
  const theme = createTheme(getTheme(mode));
  const myToken = Auth.getToken();
  localStorage.setItem("config_access_token", myToken);
  const roles = useSelector(state => state?.userReducer?.roles);

  useEffect(() => {
    if (!roles) {
      getRolesList
      .request()
      .then((res) => {
        if (res.status === 200) {
          dispatch(updateRoles(res?.data));
        }
      })
      .catch((error) => {
        dispatch(updateRoles([]));
        console.log("error", error);
      });
    }
  }, []);

  const globalStyles = (
    <GlobalStyles
      styles={(theme) => ({
        "body::-webkit-scrollbar-track": {
          background: theme.palette.primary.main
        }
      })}
    />
  );

  return (
    <ThemeProvider theme={theme}>
      {globalStyles}
      <div style={{ height: "inherit" }}>
        <CustomRoutes />
      </div>
    </ThemeProvider>
  );
};
export default App;
