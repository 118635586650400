import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from '@mui/material/TablePagination';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";

const CssTablePagination = styled(TablePagination)(({ theme }) => ({
    '& .MuiTablePagination-selectLabel': {
        marginBottom: "0px"
    },
    '& .MuiTablePagination-displayedRows': {
        marginBottom: "0px"
    }
}));

const UsersList = (props) => {
    const { usersDataList, page, setPage, setRowsPerPage, rowsPerPage, count, fetchUsersList, openHandleEdit, openDeleteConfirmation } = props
    // const emptyRows =
    //     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersDataList.length) : 0;
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
        fetchUsersList(0, event.target.value);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        fetchUsersList(newPage, rowsPerPage);
    };

    return (
        <Paper elevation={0} sx={{ width: '100%', overflow: 'hidden', backgroundColor: "transparent" }}>
            <TableContainer sx={{ height: 400, }}>
                <Table stickyHeader aria-label="Meeting table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" className="tableHeader">Profile</TableCell>
                            <TableCell align="left" className="tableHeader">Name</TableCell>
                            <TableCell align="left" className="tableHeader">Email</TableCell>
                            <TableCell align="left" className="tableHeader">Moderator</TableCell>
                            {/* <TableCell align="left" className="tableHeader">Title</TableCell> */}
                            <TableCell align="left" className="tableHeader">Role</TableCell>
                            <TableCell sx={{ minWidth: "100px" }} align="left" className="tableHeader">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usersDataList?.map((row, i) => (
                            <TableRow key={row?._id} sx={{ cursor: "pointer" }}>
                                <TableCell align="left"><Avatar src={row?.avatar?.url} sx={{ width: 30, height: 30 }} /></TableCell>
                                <TableCell align="left">{_.truncate(row?.name) || `---`}</TableCell>
                                <TableCell align="left">{row?.email || `---`}</TableCell>
                                <TableCell align="left">{row?.conference_id ? `Yes` : "No"}</TableCell>
                                {/* <TableCell align="left">{_.truncate(row?.title) || `---`}</TableCell> */}
                                <TableCell align="left">{row?.roles?.length ? row?.roles?.map(item => item?.label)?.join(", ") : `---`}</TableCell>
                                <TableCell align="left">
                                    <Tooltip
                                        title="Edit"
                                        className="me-1"
                                        onClick={() => openHandleEdit(row)}
                                        placement="left"
                                    >
                                        <EditIcon />
                                    </Tooltip>
                                    <Tooltip
                                        title="Delete"
                                        onClick={() => openDeleteConfirmation(row)}
                                        placement="left"
                                    >
                                        <DeleteIcon />
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <CssTablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};
export default UsersList;
