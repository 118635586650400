/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { Formik, Form, FieldArray } from "formik";
import { Card, Container, Grid, IconButton, Stack, Typography } from "@mui/material";
import * as Yup from "yup";
import "./Simulation.css";
import CssButton from "Components/CssButton";
import useApi from "services/api-hook";
import useRequests from "services/request-hook";
import { GlobalState } from "context/GlobalContext";
import FileUpload from "Components/FileUpload";
import styled from "@emotion/styled";
import Menus from "./Menus";
import CssTitle from "Components/CssTitle";
import CssCircularProgress from "Components/CssCircularProgress";

const SUPPORTED_VIDEO_FORMATS = ["video/mp4"];

const SceneFormSchema = Yup.object().shape({
  video: Yup.mixed()
    .nullable()
    .notRequired()
    .test(
      "FILE_FORMAT",
      "Unsupported File Format.",
      (value) =>
        !value || (value && SUPPORTED_VIDEO_FORMATS.includes(value.type))
    ),
    menus: Yup.array().of(
      Yup.object().shape({
        type: Yup.string()
          .min(3, "minimum length of a text is 3")
          .max(30, "maximum length of a text is 30"),
        name: Yup.string()
          .min(3, "minimum length of a text is 3")
          .max(30, "maximum length of a text is 30"),
      })
    ),
});

const CssAddIconButton = styled(IconButton)({
  position: "absolute",
  top: "33px",
  right: "30px",
});

const CssCard = styled(Card)({
  marginTop: "20px !important",
  padding: "6px 20px 20px",
  display: "block !important",
  backgroundColor: "transparent",
  border: "1px solid #673068",
  textAlign: "center",
});

const SceneForm = ({ handleResponse, node }) => {
  const { isPublished } = useContext(GlobalState);
  const { sceneApi, getSceneApi, updateSceneApi } = useRequests();
  const scene = useApi(sceneApi);
  const getScene = useApi(getSceneApi);
  const updateScene = useApi(updateSceneApi);
  const childFunc = React.useRef(null);
  const [saveImg, setSaveImg] = useState(node?.elementId ? true : false);
  const [fileUrl, setFileUrl] = useState("");
  const [editFormData, setEditFormData] = useState({});
  const [callUpdate, setCallUpdate] = useState(false);
  const [onDisable, setOnDisable] = useState(node?.elementId ? true : false);
  const [btnDisable, setBtnDisable] = React.useState(false);
  const initialValues = {
    video: editFormData?.video || null,
    menus: editFormData?.menus || []
  };

  const saveImage = (value) => {
    setSaveImg(value);
  };

  const onFileUpload = ({ url }) => {
    if (url) {
      let finalUrl = url?.substring(0, url?.indexOf("?"));
      var path = finalUrl.split("/");
      finalUrl = process.env.REACT_APP_CDN_URL
        ? `${process.env.REACT_APP_CDN_URL}/${path[3]}/${path[4]}`
        : finalUrl;
      setFileUrl(finalUrl);
    } else {
      setFileUrl("");
    }
  };
  const saveOnDisable = (value) => {
    setOnDisable(value);
  };
  useEffect(() => {
    if (node && node.elementId) {
      getScene
        .request(node.elementId)
        .then((res) => {
          if (res.status === 200) {
            setEditFormData({ ...res?.data });
            setCallUpdate(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const handleSpinner = (value) => {
    setBtnDisable(value);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={SceneFormSchema}
      isInitialValid={false}
      validateOnMount={true}
      onSubmit={(values) => {
        values.video = fileUrl ? fileUrl : values.video;
        if (callUpdate) {
          updateScene.request(node.elementId, values).then((res) => {
            handleResponse(res);
          });
        } else {
          scene.request(values).then((res) => {
            handleResponse(res);
          });
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        isValid,
        setFieldValue,
        dirty,
        isSubmitting,
        handleBlur
      }) => (
        <Form noValidate={true} autoComplete="off" className="form-wrapper">
          {typeof values?.video === "string" ? delete errors.video : ""}
          <Container className="container">
            <Grid container className="form-wrapper" direction="row">
              <Grid item xs={8}>
                <div style={{ width: "870px", paddingTop: "5px" }}>
                  <FileUpload
                    name="video"
                    id="video"
                    multiple={false}
                    selectedFile={values?.video}
                    saveImage={saveImage}
                    onDisable={onDisable}
                    saveOnDisable={saveOnDisable}
                    onFileUpload={onFileUpload}
                    accept={{ "video/*": [".mp4"] }}
                    childFunc={childFunc}
                    bucket={"scene-videos"}
                    isValid={false}
                    title="Video*"
                    handleSpinner={handleSpinner}
                  />
                  {errors.video && errors.video ? (
                    <div
                      className="errorText"
                      style={{ top: 0, position: "relative" }}
                    >
                      {errors.video}
                    </div>
                  ) : null}
                </div>
              </Grid>
              <Grid
                container
                className="form-wrapper menus-form-wrapper"
                direction="row"
              >
                <FieldArray name="menus">
                  {({ insert, remove, push }) => (
                    <>
                      <Grid item xs={12} className="field-array-title-wrapper">
                        <Typography variant="h5" className="field-array-title">
                          Menus
                        </Typography>
                        {isPublished ? null : (
                          <CssAddIconButton
                            onClick={() =>
                              push({
                                type: "",
                                name: "",
                                menu_items: [
                                  {
                                    label: "",
                                    content_type: "2D",
                                    content: "",
                                  },
                                ],
                              })
                            }
                          >
                            <CssAddIconButton />
                          </CssAddIconButton>
                        )}
                      </Grid>
                      {values?.menus?.length === 0 ? (
                        <Grid
                          container
                          component={CssCard}
                          className="card-wrapper"
                        >
                          <CssTitle
                            variant="h6"
                            sx={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              !isPublished &&
                              push({
                                type: "",
                                name: "",
                                menu_items: [
                                  {
                                    label: "",
                                    content_type: "2D",
                                    content: "",
                                  },
                                ],
                              })
                            }
                          >
                            Add Menus
                          </CssTitle>
                        </Grid>
                      ) : (
                        values.menus.map((item, index) => (
                          <Menus
                            key={index}
                            values={values}
                            index={index}
                            errors={errors}
                            touched={touched}
                            onRemove={remove}
                            menus={item}
                            enableClose={values.menus.length > 0}
                            onSetFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                          />
                        ))
                      )}
                    </>
                  )}
                </FieldArray>
              </Grid>
            </Grid>
          </Container>
          <Grid className="button-stack form-btn-wrapper">
            <Stack spacing={2} direction="row">
              <CssButton
                variant="contained"
                size="medium"
                type="submit"
                disabled={
                  // !isValid ||
                  isSubmitting ||
                  isPublished ||
                  !saveImg ||
                  btnDisable
                }
              >
                {isSubmitting ? <CssCircularProgress size={20} /> : node?.elementId ? "Update" : "Save"}
              </CssButton>
              {node && node.elementId ? null : (
                <CssButton
                  type="reset"
                  variant="contained"
                  size="medium"
                  onClick={() => {
                    childFunc.current();
                  }}
                  sx={{ backgroundColor: "#673068" }}
                  disabled={!dirty}
                >
                  Reset
                </CssButton>
              )}
            </Stack>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default SceneForm;
