/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import CssTitle from "Components/CssTitle";
import CssButton from "Components/CssButton";
import ModalContainer from "Components/ModalContainer";
import { Formik, Form, FastField } from "formik";
import { Grid, Stack, Container, MenuItem } from "@mui/material";
import CssTextField from "Components/CssTextField";
import useRequests from "services/request-hook";
import useApi from "services/api-hook";
import InputFileUpload from "Components/InputFileUpload";
import * as Yup from "yup";
import CustomSnackbar from "Components/CustomSnackbar";
import SRList from "./SRList";
import FileUpload from "Components/FileUpload";
import { GlobalState } from 'context/GlobalContext';
import CssCircularProgress from "Components/CssCircularProgress";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "minimum of 3 characters")
    .max(30, "maximum of 30 characters")
    .matches(/^[A-Za-z]{2}[ A-Za-z0-9_-]*$/, "Name must be alphanumeric  only")
    .required("Name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is Required"),
});

const SRDesktop = () => {
  const { getSRListApi, createSRDesktopApi, getCategoriesApi, updateSrDestopApi, deleteSRDesktop, getSRThemApi } = useRequests();
  const getCategories = useApi(getCategoriesApi);
  const getSRList = useApi(getSRListApi);
  const createSRDesktop = useApi(createSRDesktopApi);
  const updateSRApi = useApi(updateSrDestopApi);
  const SRThemApi = useApi(getSRThemApi);
  const deleteApi = useApi(deleteSRDesktop);
  const [open, setOpen] = useState(false);
  const [usersDataList, setUsersDataList] = useState([]);
  const [page, setPage] = useState(0);
  const [callUpdate, setCallUpdate] = useState(false);
  const [themeName, setThemeName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [fileUrl, setFileUrl] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const childFunc1 = React.useRef(null);
  const [posterUrl, setPosterUrl] = useState("");
  const [macFile, setMacFile] = useState("");
  const [winFile, setWinFile] = useState("");
  const { setCategoryList, categoryList } = useContext(GlobalState);
  const [simulationCategories, setSimulationCategories] = useState([]);
  const [actionType, setActionType] = useState("");
  const [initialValues, setInitialValues] = useState({
    name: '',
    simulation_type: '',
    mac_setup_file: '',
    win_setup_file: '',
    poster_url: '',
  });

  const getAllSimulationCategories = () => {
    getCategories.request()
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data?.length > 0) {
            setSimulationCategories(res.data);
          } else {
            console.log('No Data Available');
          }
        }
      })
      .catch((error) => {
        let errorObj = error.response.data;
        console.log('error', errorObj);
      });
  };

  const setSnackBar = (type) => {
    setOpenSnackbar(true);
    setActionType(type);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleClearData = () => {
    setInitialValues({
      name: '',
      simulation_type: '',
      mac_setup_file: '',
      win_setup_file: '',
      poster_url: '',
    })
    setMacFile("");
    setWinFile("")
    setPosterUrl("")
  }

  const handleOpen = (type) => {
    setOpen(true);
    setCallUpdate(false);
    handleClearData();
  };

  const fetchSRList = (pageNO, rowsPerPages) => {
    getSRList
      .request(pageNO + 1, rowsPerPages)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            // setRowsPerPage(+res?.headers?.limit);
            setCount(+res?.headers?.total_count);
            setUsersDataList([...res.data]);
          } else {
            setUsersDataList([]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchSRList(page, rowsPerPage);
  }, []);

  useEffect(() => {
    getAllSimulationCategories();
  }, [])

  const onFileUpload = ({ url }, stateName) => {
    if (url) {
      let finalUrl = url?.substring(0, url?.indexOf("?"));
      var path = finalUrl.split("/");
      finalUrl = process.env.REACT_APP_CDN_URL
        ? `${process.env.REACT_APP_CDN_URL}/${path[3]}/${path[4]}`
        : finalUrl;
      stateName(finalUrl);
    }
  };

  const handelOpenEdit = (SrList) => {
    if (SrList) {
      SRThemApi
        .request(SrList)
        .then((res) => {
          if (res.status === 200) {
            setCallUpdate(true);
            const updatedInitialValues = {
              name: SrList?.name || '',
              simulation_type: SrList?.simulation_type || '',
              mac_setup_file: SrList?.mac_setup_file || '',
              win_setup_file: SrList?.win_setup_file || '',
              poster_url: SrList?.poster_url || '',
            };
            setInitialValues(updatedInitialValues);
            setThemeName(SrList?._id);
            setPosterUrl(SrList?.poster_url)
            setMacFile(SrList?.mac_setup_file)
            setWinFile(SrList?.win_setup_file)
            setOpen(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleDelete = (SrList) => {
    deleteApi
      .request(SrList._id)
      .then((res) => {
        if (res.status === 200) {
          fetchSRList(page, rowsPerPage);
          setSnackBar("delete");
        }
      })
      .catch((error) => {
        console.log("Error deleting SR Desktop item:", error);
      });
  };


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            spacing={2}
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
          >
            <CssTitle variant="h4">SR Desktop</CssTitle>
            <CssButton
              variant="contained"
              onClick={handleOpen}
              sx={{ width: "max-content" }}
            >
              ADD
            </CssButton>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <SRList
            usersDataList={usersDataList}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            setCount={setCount}
            handelOpenEdit={handelOpenEdit}
            handleDelete={handleDelete}
            count={count}
            fetchSRList={fetchSRList}
          />
        </Grid>
      </Grid>
      <ModalContainer
        open={open}
        handleClose={() => {
          setOpen(false)
          handleClearData();
        }}
        title={"Add File"}
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          // validationSchema={validationSchema}
          validateOnMount={true}
          isInitialValid={false}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            const payload = {
              name: values.name,
              simulation_type: values.simulation_type,
              mac_setup_file: macFile,
              win_setup_file: winFile,
              poster_url: posterUrl,
            };
            if (callUpdate) {
              updateSRApi
                .request(themeName, payload)
                .then((res) => {
                  handleClearData();
                  setSubmitting(false);
                  fetchSRList(page, rowsPerPage);
                  setOpen(false);
                  setSnackBar();
                })
                .catch((error) => {
                  console.log("error", error);
                  setSubmitting(false);
                });
            } else {
              createSRDesktop
                .request(payload)
                .then((res) => {
                  if (res.status === 200) {
                    setSubmitting(false);
                    fetchSRList(page, rowsPerPage);
                    setOpen(false);
                    setSnackBar();
                    handleClearData();
                  }
                })
                .catch((error) => {
                  console.error('API Error:', error);
                  setSubmitting(false);
                });
            }
          }
          }
        >
          {({
            dirty,
            isValid,
            values,
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            handleBlur,
          }) => (
            <Form
              onSubmit={handleSubmit}
              noValidate={true}
              autoComplete="off"
              className="form-wrapper formSpace"
            >
              <Container sx={{ marginTop: "10px" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <div className="form_control">
                      <FastField
                        component={CssTextField}
                        variant="outlined"
                        size="small"
                        name="name"
                        id="name"
                        label="Name*"
                        autoComplete="Off"
                        value={values.name}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("name", e.target.value);
                        }}
                        sx={{ marginTop: "0px" }}
                      />
                      {errors.name && touched.name ? (
                        <div className="errorText">{errors.name}</div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="form_control">
                      <FastField
                        component={CssTextField}
                        select
                        variant="outlined"
                        size="small"
                        name="simulation_type"
                        id="simulation_type"
                        label="Simulation Type*"
                        autoComplete="Off"
                        value={values.simulation_type}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("simulation_type", e.target.value);
                        }}
                        sx={{ marginTop: "0px" }}
                      >
                        {simulationCategories.map(category => (
                          <MenuItem key={category.id} value={category.category
                          }>
                            {category.category}
                          </MenuItem>
                        ))}
                      </FastField>
                      {errors.simulation_type && touched.simulation_type ? (
                        <div className="errorText">{errors.simulation_type}</div>
                      ) : null}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    {/* <div className="form_control"> */}
                    <Stack spacing={2} direction="row">
                      <InputFileUpload
                        onFileUpload={(...arg) =>
                          onFileUpload(...arg, setWinFile)
                        }
                        bucket={"sr-desktop-installer"}
                        // acceptType=".doc"
                        stackInput={{
                          name: "win_setup_file",
                          label: "Window Setup Files",
                          placeholder: "Setup Files",
                        }}
                        id="Win-file"
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    {/* <div className="form_control"> */}
                    <Stack spacing={2} direction="row">
                      <InputFileUpload
                        onFileUpload={(...arg) =>
                          onFileUpload(...arg, setMacFile)
                        }
                        bucket={"sr-desktop-installer"}
                        // acceptType=".doc"
                        stackInput={{
                          name: "mac_setup_file",
                          label: "Mac Setup Files",
                          placeholder: "Setup Files",
                        }}
                        id="Mac-File"
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={2} direction="row">
                      <InputFileUpload
                        onFileUpload={(...arg) =>
                          onFileUpload(...arg, setPosterUrl)
                        }
                        bucket={"other"}
                        acceptType="image/*"
                        stackInput={{
                          name: "poster_url",
                          label: "Poster URL",
                          placeholder: "Poster URL",
                        }}
                        id="poster"
                      />
                    </Stack>
                  </Grid>
                  <Grid className="button-stack form-btn-wrapper">
                    <Stack spacing={2} direction="row">
                      <CssButton
                        variant="contained"
                        size="medium"
                        type="submit"
                        disabled={isSubmitting || !values.name || !values.simulation_type}
                      >
                        {isSubmitting ? <CssCircularProgress size={20} /> : "Save"}
                      </CssButton>
                      <CssButton
                        variant="contained"
                        sx={{ backgroundColor: "#673068" }}
                        size="medium"
                        type="reset"
                        disabled={!dirty}
                      >
                        Reset
                      </CssButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Container>
            </Form>
          )}
        </Formik>
      </ModalContainer>
      <CustomSnackbar
        open={openSnackbar}
        message={actionType === "delete" ? "Data Deleted Successfully" : "Data Saved Successfully"}
        handleClose={handleCloseSnackbar}
      />
    </>
  );
};

export default SRDesktop;
