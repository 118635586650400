import React, { forwardRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import styled from '@emotion/styled';

const CssMuiAlert = styled(MuiAlert)({
  "& .MuiSvgIcon-root": {
    color: '#fff',
  },
  color: "#fff"
});

const Alert = forwardRef(function Alert(props, ref) {
  return <CssMuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  
});

function SlideTransition(props) {
  return <Slide {...props} direction='up' />;
}

const CustomSnackbar = (props) => {
  const { open, message, handleClose, severity } = props;

  return (
    <Snackbar 
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
    >
      <Alert onClose={handleClose} severity={severity || 'success'} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default CustomSnackbar;