import _ from 'lodash';

export const initialState = [{
  className: '',
  data: { label: '' },
  id: '',
  position: { x: 0, y: 0 },
  type: ''
}];

const reducer = (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case 'SET_NODES_DATA':
      return {
        ...state,
        data,
      };

    case 'SET_NODE_DATA':
      return {
        ...state,
        node: data,
      };

    case 'UPDATE_NODES_DATA':
      let node = data;
      const nodes = state.data;
      const index = _.findIndex(nodes, { id: node.id });

      if (index !== -1) {
        nodes.splice(index, 1, node);
      }

      return {
        ...state,
        data: nodes,
      };

    default:
      return state;
  }
};

export default reducer;
