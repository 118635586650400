import React, { useState } from "react";
import { FastField } from "formik";
import CssTextField from "../../Components/CssTextField";
import { styled } from "@mui/material/styles";
import useRequests from "services/request-hook";
import useApi from "services/api-hook";
import {
  Grid,
  IconButton,
  Card,
  Stack,
  Avatar,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const CssCloseIconButton = styled(IconButton)({
  position: "absolute",
  top: "7px",
  right: "7px",
  border: "1px solid #F00",
  padding: 0,
  backgroundColor: "#FFF",
});

const CssCloseIcon = styled(CloseIcon)({
  color: "#F00",
  fontSize: "1.2rem",
});

const CssCard = styled(Card)({
  marginTop: "30px !important",
  padding: "6px 20px 20px",
  position: "relative",
  backgroundColor: "transparent",
  border: "1px solid #673068",
});

const CssAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: "transparent",
  top: "18px",
  left: "12px",
  "&.avatar": {
    borderWidth: 0,
  },
  "& svg": {
    display: "none",
  },
  border: "1px dashed",
  borderColor: theme.palette.primary.contrastText,
}));

const CssCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  position: "relative",
  top: "20px",
  left: "20px",
}));

const CssUploadButton = styled(IconButton)({
  position: "relative",
  top: "20px",
});

const CssUploadIcon = styled(CloudUploadIcon)({
  "& uploadImg": {
    color: "#FFF",
  },
});

const Sponsors = (props) => {
  const {
    index,
    enableClose,
    errors,
    touched,
    onSetFieldValue,
    onRemove,
    sponsor,
    handleBlur,
    isPublished,
  } = props;
  const [showLoader, setShowLoader] = useState(false);
  const { getSignedUrlApi, createAssetsApi } = useRequests();
  const getSignedUrl = useApi(getSignedUrlApi);
  const createAssets = useApi(createAssetsApi);

  const addAssetsToDB = (payload) => {
    createAssets
      .request("other", payload)
      .then((res) => {
        if (res.status === 200) {
          console.log("res", res);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleUploadFile = (file) => {
    setShowLoader(true);
    let selectedFile = file;
    let formData = new FormData();
    formData.append("file", selectedFile);
    getSignedUrl
      .request("other")
      .then((res) => {
        if (res.status === 200) {
          let client = res?.data?.client;
          let blobSAS = res?.data?.blobSAS;
          let name = file?.name.split(".");
          let fileExtension = name.pop();
          let fileName = `${name[0]}${Date.now()}.${fileExtension}`;
          const finalUrl = `${client}/${fileName}?${blobSAS}`;
          fetch(`${finalUrl}`, {
            method: "PUT",
            body: file,
            headers: {
              "x-ms-blob-type": "BlockBlob",
              "Content-Type": "multipart/form-data",
            },
          })
            .then((response) => {
              if (response.status === 201 || response.status === 200) {
                let fileUrl = response?.url?.substring(
                  0,
                  response?.url?.indexOf("?")
                );
                var path = fileUrl.split("/");
                fileUrl = process.env.REACT_APP_CDN_URL
                  ? `${process.env.REACT_APP_CDN_URL}/${path[3]}/${path[4]}`
                  : fileUrl;
                onSetFieldValue(`sponsors.${index}.logo_url`, fileUrl);
                let payload = {
                  fileurl: fileUrl,
                  filename: fileName,
                  type: "other",
                };
                addAssetsToDB(payload);
                setShowLoader(false);
              }
            })
            .catch((error) => {
              console.log("error", error);
              setShowLoader(false);
            });
        }
      })
      .catch((error) => {
        console.log("error", error);
        setShowLoader(false);
      });
  };

  return (
    <Grid container component={CssCard} className="card-wrapper">
      <Grid item xs={6}>
        <FastField
          component={CssTextField}
          variant="outlined"
          size="small"
          name={`sponsors.${index}.name`}
          id={`sponsors.${index}.name`}
          InputProps={{
            className: "text-field",
          }}
          autoComplete="Off"
          value={sponsor.name}
          onBlur={handleBlur}
          onChange={(e) => {
            onSetFieldValue(`sponsors.${index}.name`, e.target.value);
          }}
          label="Name"
        />
        {errors &&
          errors.sponsors &&
          errors.sponsors[index] &&
          errors.sponsors[index].name &&
          touched &&
          touched.sponsors &&
          touched.sponsors[index] &&
          touched.sponsors[index].name && (
            <div className="field-error">{errors.sponsors[index].name}</div>
          )}
      </Grid>
      <Grid item xs={6}>
        <FastField
          component={CssTextField}
          variant="outlined"
          size="small"
          name={`sponsors.${index}.place_holder`}
          id={`sponsors.${index}.place_holder`}
          InputProps={{
            className: "text-field",
          }}
          autoComplete="Off"
          value={sponsor.place_holder}
          onBlur={handleBlur}
          onChange={(e) => {
            onSetFieldValue(`sponsors.${index}.place_holder`, e.target.value);
          }}
          label="Placeholder"
        />
        {errors &&
          errors.sponsors &&
          errors.sponsors[index] &&
          errors.sponsors[index].place_holder &&
          touched &&
          touched.sponsors &&
          touched.sponsors[index] &&
          touched.sponsors[index].place_holder && (
            <div className="field-error">
              {errors.sponsors[index].place_holder}
            </div>
          )}
      </Grid>
      <Grid item xs={6}>
        <Stack spacing={4} direction="row" justifyContent="center">
          <CssAvatar
            alt={"Icon"}
            src={sponsor?.logo_url}
            className={sponsor?.logo_url ? "avatar" : ""}
          />
          <label htmlFor={`sponsors.${index}.logo_url`}>
            <input
              id={`sponsors.${index}.logo_url`}
              name={`sponsors.${index}.logo_url`}
              type="file"
              onChange={(event) =>
                handleUploadFile(event.currentTarget.files[0])
              }
              className="form-control"
              style={{ display: "none" }}
              accept="image/*"
              inputProps={{ readOnly: isPublished }}
              InputProps={{
                className: "text-field",
              }}
            />
            {showLoader ? (
              <CssCircularProgress size={30} />
            ) : (
              <CssUploadButton
                aria-label="upload"
                variant="contained"
                component="span"
              >
                <CssUploadIcon />
              </CssUploadButton>
            )}
          </label>
        </Stack>
      </Grid>
      {enableClose && (
        <CssCloseIconButton onClick={() => onRemove(index)}>
          <CssCloseIcon />
        </CssCloseIconButton>
      )}
    </Grid>
  );
};
export default Sponsors;
