/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import {
  Grid
} from '@mui/material';
import MediaCard from 'Components/MediaCard';
import useRequests from 'services/request-hook';
import useApi from 'services/api-hook';
import CssTitle from 'Components/CssTitle';
import _ from 'lodash';

const Assets = (props) => {
    const { type, onAssetClick, showImages, accept, searchText } = props;
  const { getAssetsApi } = useRequests();
  const getAssets = useApi(getAssetsApi);
  const [assets, setAssets] = useState([]);
  const [assetsLength, setAssetsLength] = useState(true);
  const [currentPage, setCurrentPage] = useState(null);
  const containerRef = useRef(null);
  const [triggerObserver, setTriggerObserver] = useState(false);
  const [text, setText] = useState(searchText);

  useEffect(() => {
    setAssets([]);
    setText(searchText);
  }, [searchText]);

  useEffect(() => {
    setCurrentPage(1);
    handleFetchAssetsList(1);
    setAssetsLength(true);
}, [text]);
  
  const containerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.3
  };

  const handleFetchAssetsList = (page) => {
    getAssets.request(type, page, text)
        .then((res) => {
            if (res.status === 200) {
                if (res?.data?.length > 0) {
                    const { current_page, total_pages } = res?.headers
                    let acceptType
                    if (showImages) {
                        if (accept['image/*'] || accept['video/*']) {
                            acceptType = _.filter(res?.data, (file) => !_.includes(file?.file_url, ".mp3") && !_.includes(file?.file_url, ".MP3") && !_.includes(file?.file_url, ".ogg") && !_.includes(file?.file_url, ".OGG"))
                        // }  if (accept['video/*']) {
                        //     acceptType= _.filter(res?.data, (file) => !_.includes(file?.file_url, ".jpeg") && !_.includes(file?.file_url, ".png") && !_.includes(file?.file_url, ".jpg") && !_.includes(file?.file_url, ".mp3") && 
                        //     !_.includes(file?.file_url, ".JPEG") && !_.includes(file?.file_url, ".PNG") && !_.includes(file?.file_url, ".JPG") && !_.includes(file?.file_url, ".MP3") && !_.includes(file?.file_url, ".ogg") && !_.includes(file?.file_url, ".OGG"));
                        }   if (accept['audio/*']) {
                            acceptType = _.filter(res?.data, (file) => !_.includes(file?.file_url, ".mp4") && !_.includes(file?.file_url, ".jpeg") && !_.includes(file?.file_url, ".png") && !_.includes(file?.file_url, ".jpg") && 
                            !_.includes(file?.file_url, ".JPEG") && !_.includes(file?.file_url, ".PNG") && !_.includes(file?.file_url, ".JPG") )
                        }
                    if(currentPage < 1 ){
                        setAssets(acceptType);
                        (acceptType.length === 0) ? setAssetsLength(false):setAssetsLength(true);
                    } else{
                        if(assets.length <  res.headers.total_count){
                            setAssets((sls) => sls.concat(acceptType));
                        }               
                    } 
                    } else {
                        if(currentPage < 1 ){
                            setAssets(res.data);
                        }   else{
                            if(assets.length <  res.headers.total_count){
                                setAssets((sls) => sls.concat(res.data));
                            }               
                        }
                    }
                  
                    if (parseInt(total_pages) > parseInt(current_page)) {
                      setCurrentPage(current_page);
                      setTriggerObserver(false);
                    } else {
                      setTriggerObserver(true);
                    }
                } else {
                    setAssets([]);
                    setAssetsLength(false);
                    setTriggerObserver(true);
                }
            }
        })
      .catch((error) => {
        console.log(error);
      });
  };

  const onInteraction = (entities, observer) => {
    const [entity] = entities;

    if (entity.isIntersecting && !triggerObserver) {
      setTriggerObserver(true);
      if (!isNaN(parseInt(currentPage))) {
        handleFetchAssetsList(parseInt(currentPage) + 1);
      }
    }
  };

  useEffect(() => {
    if (type) {
      handleFetchAssetsList(1);
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(onInteraction, containerOptions);
    if (containerRef.current) observer.observe(containerRef.current);

    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, [containerRef, containerOptions]);

  return (
    <Grid container spacing={3}>
      <CssTitle hidden={assetsLength} style={{ fontSize: '25px', marginTop: '170px', marginLeft: '370px' }}>
        {'No data found'}
      </CssTitle>
      {assets?.map((item, i) => {
        return (
          <Grid item xs={3} key={i} ref={containerRef}>
            <MediaCard
              image={
                item?.file_url?.substring(0, item?.file_url?.indexOf('?')) ||
                item?.file_url
              }
              alt={item?.filename}
              title={item?.filename}
              subTitle={item?.type}
              handleClick={onAssetClick}
              actionShow={true}
              images={[item?.file_url]}
              imageNames={[item?.filename]}
              index={0}
              viewFullScreen={false}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
export default Assets;
