import * as React from "react";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import _ from "lodash";
import Chip from "@mui/material/Chip";

const CssTablePagination = styled(TablePagination)(({ theme }) => ({
  "& .MuiTablePagination-selectLabel": {
    marginBottom: "0px",
  },
  "& .MuiTablePagination-displayedRows": {
    marginBottom: "0px",
  },
}));

const ApaThemesList = (props) => {
  const {
    apaThemesDataList,
    page,
    setPage,
    setRowsPerPage,
    rowsPerPage,
    count,
    fetchApaThemesList,
    hasModificationAccess = false
  } = props;

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    fetchApaThemesList(0, event.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchApaThemesList(newPage, rowsPerPage);
  };

  return (
    <Paper
      elevation={0}
      sx={{ width: "100%", overflow: "hidden", backgroundColor: "transparent" }}
    >
      <TableContainer sx={{ height: 400 }}>
        <Table stickyHeader aria-label="Meeting table">
          <TableHead>
            <TableRow>
              <TableCell align="left" className="tableHeader">
                Theme Name
              </TableCell>
              <TableCell align="left" className="tableHeader">
                BG Image
              </TableCell>
              <TableCell align="left" className="tableHeader">
                Logo
              </TableCell>
              <TableCell align="left" className="tableHeader">
                Status
              </TableCell>
              <TableCell align="left" className="tableHeader">
                Action
              </TableCell>
              {hasModificationAccess && <TableCell align="left" className="tableHeader">
                Edit
              </TableCell>}
              <TableCell align="left" className="tableHeader">
                Last Updated
              </TableCell>
              <TableCell align="left" className="tableHeader">
                Updated By
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {apaThemesDataList?.map((row, i) => (
              <TableRow key={row?._id} sx={{ cursor: "pointer" }}>
                <TableCell align="left">
                  {_.truncate(row?.name) || `---`}
                </TableCell>
                <TableCell align="left">
                  <Avatar src={row?.bg_img} sx={{ width: 30, height: 30 }} />
                </TableCell>
                <TableCell align="left">
                  <Avatar
                    src={row?.bg_img_logo}
                    sx={{ width: 30, height: 30 }}
                  />
                </TableCell>
                <TableCell align="left">
                  {row?.status ? "Active" : "Inactive"}
                </TableCell>
                <TableCell align="left">
                  <Chip
                    label={row?.status ? "Deactivate" : "Activate"}
                    size="small"
                    onClick={() =>
                      props.handelUpdateStatus(row?._id, row?.status)
                    }
                  />
                </TableCell>
                {hasModificationAccess && <TableCell align="left">
                  <Tooltip
                    title="Edit"
                    onClick={() => props.handelOpenEdit(row?.name)}
                    placement="left"
                  >
                    <EditIcon />
                  </Tooltip>
                </TableCell>}
                <TableCell align="left">{new Date(row?.updatedAt).toLocaleString('en-US', { timeZone: 'America/New_York' })}</TableCell>
                <TableCell align="left">{_.truncate(row?.updated_by) || `---`}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CssTablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
export default ApaThemesList;
