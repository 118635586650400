import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Layout from "../Components/Layout";
import Home from "../modules/Home/Home";
import Media from "../modules/Media/Media";
import Category from "../modules/Category";
import Simulation from "../modules/Simulation";
import AssetsList from "../modules/Media/AssetsList";
import Meeting from "../modules/MeetingManagement";
import MeetingDetails from "../modules/MeetingManagement/MeetingDetails"
import Users from "../modules/Users"
import APA from "../modules/Apa"
import { createBrowserHistory } from "history";
import SRDesktop from "../modules/SR-Download";
import RunStatistics from "modules/RunStatistics";
import { useSelector } from "react-redux";
import userAllowedRoles from "data/roles.json";

const CustomRoutes = () => {
  const browserHistory = createBrowserHistory();
  const roles = useSelector(state => state?.userReducer?.roles);
  const isRunStatistics = roles?.find(item => userAllowedRoles?.runStatesReport?.includes(item));

  return (
  <BrowserRouter history={browserHistory}>
    <Layout>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/:category" exact element={<Category />} />
        <Route path="/media/:type" exact element={<AssetsList />} />
        <Route path="/media" exact element={<Media />} />
        <Route path="/:category/simulation/:sid" element={<Simulation />} />
        <Route path="/meetings" exact element={<Meeting />} />
        <Route path="/meetings/:mid" exact element={<MeetingDetails />} />
        <Route path="/create-user" exact element={<Users />} />
        {/* <Route path="/SR-Desktop" exact element={<SRDesktop />} /> */}
        <Route path="/create-apa-theme" exact element={<APA />} />
        {isRunStatistics && <Route path="/run-statistics" exact element={<RunStatistics />} />}

      </Routes>
    </Layout>
  </BrowserRouter>
)
};

export default CustomRoutes;