/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Formik, Form, FastField } from 'formik';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import * as Yup from 'yup';
import _ from 'lodash';
import './Simulation.css';
import CssButton from 'Components/CssButton';
import CssTextField from 'Components/CssTextField';
import CssSelectField from 'Components/CssSelectField';
import ConfirmDialog from 'Components/ConfirmDialog';
import useApi from 'services/api-hook';
import useRequests from 'services/request-hook';
import { MergeObjectIntoArray } from 'utils';
import { GlobalState } from 'context/GlobalContext';
import CustomSnackbar from 'Components/CustomSnackbar';
import CssCircularProgress from 'Components/CssCircularProgress';


const KpiDefinitionFormSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  type: Yup.string().required('Type is required'),
  initial_value: Yup.string().required('Initial value is required'),
  unit_value: Yup.string().required('Unit Value is required'),
});

const KpiDefinitionEditFormSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  type: Yup.string().required('Type is required'),
  initial_value: Yup.string().required('Initial value is required'),
  unit_value: Yup.string().required('Unit Value is required'),
});

const typeOptions = [
  { label: 'Direct Cost', value: 'direct_cost' },
  { label: 'Indirect Cost', value: 'indirect_cost' },
  { label: 'Time', value: 'time' },
  { label: 'Number', value: 'number' },
];

const CssEditIcon = styled(EditIcon)({
  color: '#673068'
});

const CssDeleteIcon = styled(DeleteIcon)({
  color: '#673068'
});

const CssCheckIcon = styled(CheckCircleIcon)({
  color: '#673068'
});

const CssRevertIcon = styled(RotateLeftIcon)({
  color: '#673068'
});

const KpiDefinitionForm = () => {
  const { sid } = useParams();
  const { kpiApi, getKpiApi, updateKpiApi, deleteKpiApi } = useRequests();
  const { isPublished, setIsUpdateFlowStructure } = useContext(GlobalState);
  const kpi = useApi(kpiApi);
  const getKpi = useApi(getKpiApi);
  const updateKpi = useApi(updateKpiApi);
  const deleteKpi = useApi(deleteKpiApi);
  const [kpiData, setKPIData] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectedKpi, setSelectedKpi] = useState(null);
  const [openKpiDeleteAlert, setOpenKpiDeleteAlert] = useState(false);
  const initialValues = {
    name: '',
    type: '',
    initial_value: '',
    unit_value: '',
  };

  const getTypeLabel = (type) => {
    return _.find(typeOptions, (o) => o.value === type).label;
  };

  const onKpiEdit = (kpi) => {
    const k = { ...kpi, isKpiEdit: true };
    setKPIData((kpis) => MergeObjectIntoArray(kpis, k));
  };

  const onKpiDelete = (isDelete) => {
    if (isDelete) {
      const kpi = selectedKpi;

      deleteKpi.request(sid, kpi.id).then((res) => {
        setKPIData((kpis) => _.filter(kpis, (o) => o.id !== kpi.id));
        setOpenKpiDeleteAlert(false);
        setIsUpdateFlowStructure(true);
      });
    } else {
      setOpenKpiDeleteAlert(false);
    }
  };

  const onKpiRevert = (kpi) => {
    const k = { ...kpi, isKpiEdit: false };
    setKPIData((kpis) => MergeObjectIntoArray(kpis, k));
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);  
};

  useEffect(() => {
    getKpi.request(sid).then((res) => {
      setKPIData(res.data);
    });
  }, []);

  return (
    <>
      <TableContainer component={Paper} className='table-container filled-inputs'>
        <Table>
          {
            isPublished && (
              <TableHead className='table-head'>
                <TableRow>
                  <TableCell width={135}>Name</TableCell>
                  <TableCell width={130}>Type</TableCell>
                  <TableCell width={88}>Initial Value</TableCell>
                  <TableCell width={100}>Unit Value</TableCell>
                  <TableCell width={82}>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
            )
          }
          <TableBody className='table-body'>
            {
              isPublished ? null : (<TableRow>
                <TableCell colSpan={5}>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={KpiDefinitionEditFormSchema}
                    validateOnMount
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      setSubmitting(true)
                      kpi.request(sid, values).then(res => {
                        if (res && res.data) {
                          setKPIData((kpis) => res.data.kpis);
                          resetForm();
                          setSubmitting(false)
                          setOpenSnackbar(true);
                        }
                      });
                      setSubmitting(false) 
                    }}
                  >
                    {({
                      values,
                      setFieldValue,
                      errors,
                      touched,
                      isValid,
                      isSubmitting,
                      dirty,
                      handleBlur
                    }) => (
                      <Form>
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <FastField
                              component={CssTextField}
                              variant='filled'
                              size='small'
                              name='name'
                              id='name'
                              label='Name*'
                              autoComplete={"off"}
                              value={values.name}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                let nameValue = e.target.value;
                                if( ! nameValue.match(/^\s/)){
                                   setFieldValue('name', e.target.value);
                                }
                              }}
                              InputProps={{
                                className: 'text-field'
                              }}
                            />
                            {errors.name && touched.name ? (
                              <div className='field-error'>{errors.name}</div>
                            ) : null}
                          </Grid>
                          <Grid item xs={3}>
                            <div className='form_control'>
                              <FormControl fullWidth size='small'>
                                <InputLabel id='type'>
                                  Type
                                </InputLabel>
                                <FastField
                                  component={CssSelectField}
                                  variant='filled'
                                  size='small'
                                  name='type'
                                  id='type'
                                  label='Type*'
                                  labelId='type'
                                  defaultValue=''
                                  value={values.type}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    setFieldValue('type', e.target.value);
                                  }}
                                >
                                  {typeOptions.map((item, i) => (
                                    <MenuItem key={item.label} value={item.value}>
                                      {item.label}
                                    </MenuItem>
                                  ))}
                                </FastField>
                              </FormControl>
                              {errors.type && touched.type ? (
                                <div className='field-error'>{errors.type}</div>
                              ) : null}
                            </div>
                          </Grid>
                          <Grid item xs={2}>
                            <FastField
                              component={CssTextField}
                              variant='filled'
                              size='small'
                              name='initial_value'
                              id='initial_value'
                              label='Initial Value*'
                              onKeyDown={(evt) => (evt.key === 'e' || evt.key === 'E' || evt.key === '.' || evt.key === '-' ) && evt.preventDefault()}
                              type='text'
                              autoComplete='off'
                              onBlur={handleBlur}
                              value={values.initial_value}
                              onChange={(e) => {
                                const re = /^[0-9]+$/
                                if (e.target.value === "" || re.test(e.target.value))
                                setFieldValue('initial_value', e.target.value);
                              }}
                              InputProps={{
                                className: 'text-field'
                              }}
                            />
                            {errors.initial_value && touched.initial_value ? (
                              <div className='field-error'>{errors.initial_value}</div>
                            ) : null}
                          </Grid>
                          <Grid item xs={2}>
                            <FastField
                              component={CssTextField}
                              variant='filled'
                              size='small'
                              name='unit_value'
                              id='unit_value'
                              label='Unit Value*'
                              type='text'                       
                              onBlur={handleBlur}
                              autoComplete='off'
                              value={values.unit_value}
                            //   onKeyDown={(evt) => (evt.key === 'e' || evt.key === 'E') && evt.preventDefault()}
                              onChange={(e) => {
                                const re = /^[0-9]+$/
                                if (e.target.value === "" || re.test(e.target.value))
                                setFieldValue('unit_value', e.target.value);
                              }}
                              InputProps={{
                                className: 'text-field'
                              }}
                            />
                            {errors.unit_value && touched.unit_value ? (
                              <div className='field-error'>{errors.unit_value}</div>
                            ) : null}
                          </Grid>
                          <Grid item xs={2} className='btn-wrapper'>
                            <CssButton
                              variant='contained'
                              sx={{ width: 'max-content', backgroundColor: "#673068" }}
                              type='Submit'
                              disabled={!isValid || isSubmitting || !dirty}
                            >
                              {isSubmitting ? <CssCircularProgress size={20} /> : "Add Kpi"}
                            </CssButton>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </TableCell>
              </TableRow>)
            }
            {kpiData && kpiData.length > 0 ? kpiData.map((o, i) => (
              <TableRow key={i}>
                {o.isKpiEdit ?
                  <>
                    <TableCell colSpan={5}>
                      <Formik
                        initialValues={{
                          uid: o.uid,
                          name: o.name,
                          type: o.type,
                          initial_value: o.initial_value,
                          unit_value: o.unit_value
                        }}
                        validationSchema={KpiDefinitionFormSchema}
                        onSubmit={(values) => {
                            updateKpi.request(sid, o.id, values).then(res => {
                                onKpiRevert({ ...values, isKpiEdit: false, id: o.id });
                                setOpenSnackbar(true);
                            }).catch((error) => {
                            let errorRes = error.response
                            if (errorRes.status === 422) {
                              getKpi.request(sid).then((res) => {
                                setKPIData(res.data);
                              });
                            }
                          });
                        }}
                      >
                        {({
                          values,
                          setFieldValue,
                          errors,
                          touched
                        }) => (
                          <Form>
                            <Grid container spacing={2}>
                              <Grid item xs={3}>
                                <FastField
                                  component={CssTextField}
                                  variant='outlined'
                                  size='small'
                                  name='name'
                                  label='Name'
                                  autoComplete='off'
                                  value={values.name}
                                  onChange={(e) => {
                                    setFieldValue('name', e.target.value);
                                  }}
                                  InputProps={{
                                    className: 'text-field'
                                  }}
                                />
                                {errors.name && touched.name ? (
                                  <div className='field-error'>{errors.name}</div>
                                ) : null}
                              </Grid>
                              <Grid item xs={3}>
                                <div className='form_control type-select'>
                                  <FormControl fullWidth variant='outlined' size='small'>
                                    <InputLabel id='type'>
                                      Type
                                    </InputLabel>
                                    <FastField
                                      component={CssSelectField}
                                      size='small'
                                      name='type'
                                      label='Type'
                                      labelId='type'
                                      defaultValue=''
                                      value={values.type}
                                      onChange={(e) => {
                                        setFieldValue('type', e.target.value);
                                      }}
                                    >
                                      {typeOptions.map((item, i) => (
                                        <MenuItem key={item.label} value={item.value}>
                                          {item.label}
                                        </MenuItem>
                                      ))}
                                    </FastField>
                                  </FormControl>
                                  {errors.type && touched.type ? (
                                    <div className='field-error'>{errors.type}</div>
                                  ) : null}
                                </div>
                              </Grid>
                              <Grid item xs={2}>
                                <FastField
                                  component={CssTextField}
                                  variant='outlined'
                                  size='small'
                                  name='initial_value'
                                  label='Initial Value'
                                  type='number'
                                  autoComplete='false'
                                  value={values.initial_value}
                                  onChange={(e) => {
                                    setFieldValue('initial_value', e.target.value);
                                  }}
                                  InputProps={{
                                    className: 'text-field'
                                  }}
                                />
                                {errors.initial_value && touched.initial_value ? (
                                  <div className='field-error'>{errors.initial_value}</div>
                                ) : null}
                              </Grid>
                              <Grid item xs={2}>
                                <FastField
                                  component={CssTextField}
                                  variant='outlined'
                                  size='small'
                                  name='unit_value'
                                  label='Unit Value'
                                  type='number'
                                  autoComplete='false'
                                  value={values.unit_value}
                                  onChange={(e) => {
                                    setFieldValue('unit_value', e.target.value);
                                  }}
                                  InputProps={{
                                    className: 'text-field'
                                  }}
                                />
                                {errors.unit_value && touched.unit_value ? (
                                  <div className='field-error'>{errors.unit_value}</div>
                                ) : null}
                              </Grid>
                              <Grid item xs={2} className='btn-wrapper'>
                                <IconButton type='Submit'>
                                  <Tooltip title='Save Kpi' placement='top'>
                                    <CssCheckIcon />
                                  </Tooltip>
                                </IconButton>
                                <IconButton onClick={() => onKpiRevert(o)}>
                                  <Tooltip title='Revert' placement='top'>
                                    <CssRevertIcon />
                                  </Tooltip>
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Form>
                        )}
                      </Formik>
                    </TableCell>
                  </> : <>
                    <TableCell width={220}>{o.name}</TableCell>
                    <TableCell >{getTypeLabel(o.type)}</TableCell>
                    <TableCell width={120}>{o.initial_value}</TableCell>
                    <TableCell>{o.unit_value}</TableCell>
                    {
                      isPublished ? null : (
                        <TableCell align='center' width={220}>
                          <IconButton onClick={() => onKpiEdit(o)}>
                            <Tooltip title='Edit' placement='top'>
                              <CssEditIcon />
                            </Tooltip>
                          </IconButton>

                          <IconButton onClick={() => {
                            setSelectedKpi(o);
                            setOpenKpiDeleteAlert(true);
                          }}>
                            <Tooltip title='Delete' placement='top'>
                              <CssDeleteIcon />
                            </Tooltip>
                          </IconButton>
                        </TableCell>
                      )
                    }
                  </>
                }
              </TableRow>
            )) :
              <TableRow>
                <TableCell align='center' colSpan={5}>No records found.</TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmDialog 
        open={openKpiDeleteAlert}
        text={`Are you sure, you would like to delete, ${selectedKpi?.name}?`}
        handleYesNo={onKpiDelete}
      />
      <CustomSnackbar sx={{ color: '#fff'}} open={openSnackbar} message={"Data Saved Successfully"}  handleClose={handleCloseSnackbar}/>
    </>
  );
}

export default KpiDefinitionForm;
