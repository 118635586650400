import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

const CssRemoveRedEyeIcon = styled(RemoveRedEyeIcon)(({ theme }) => ({
  color: "#e95890"
}));
const CssTablePagination = styled(TablePagination)(({ theme }) => ({
  "& .MuiTablePagination-selectLabel": {
    marginBottom: "0px",
  },
  "& .MuiTablePagination-displayedRows": {
    marginBottom: "0px",
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "SR Name",
    numeric: false,
    label: "SR Name",
  },
  { id: "Preview", numeric: false, label: "Preview" },
  { id: "Version", numeric: false, label: "Version" },
  { id: "Category", numeric: false, label: "Category" },
  {
    id: "SRCreationDate",
    numeric: false,
    label: "Created Date",
  },
  {
    id: "LastRunDate",
    numeric: false,
    label: "Last Run Date",
  },
  { id: "Count", numeric: true, label: "Run Count" },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className='tableHeader'
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const RunStatsTable = (props) => {
  const {
    tableData,
    page,
    setPage,
    setRowsPerPage,
    rowsPerPage,
    count,
    fetchApaThemesList,
  } = props;

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');

  const handleColor = (status) => {
    let color = "";
    if (status === "1") {
      color = "error";
    }
    return color;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(tableData, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, tableData],
  );
    
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          width: "100%",
          overflow: "hidden",
          backgroundColor: "transparent",
        }}
      >
        <TableContainer sx={{ height: 400 }}>
          <Table stickyHeader aria-label='Meeting table'>
             <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={tableData.length}
            />
            <TableBody>
              {visibleRows?.map((row, i) => (
                <TableRow key={row?._id} sx={{ cursor: "pointer" }}>
                  <TableCell align='left' sx={{ width: "275px !important" }}>
                    <Tooltip title={row["SR Name"]} placement='top'>
                      <p>{_.truncate(row["SR Name"]) || `---`}</p>
                    </Tooltip>
                  </TableCell>
                  <TableCell align='left' sx={{ width: "50px !important" }}>
                  <CssRemoveRedEyeIcon
                          sx={{ marginLeft: "15px"}}
                          color={handleColor(row["Axon Version"])}
                          onClick={(e) => {
                            e.stopPropagation();
                            window.open("/sr/"+row["SR_id"]);
                          }}
                        />
                  </TableCell>
                  <TableCell align='left' sx={{ width: "50px !important" }}>{row["Axon Version"]+".0"}</TableCell>
                  <TableCell align='left'>{row.Category.toUpperCase()}</TableCell>
                  <TableCell align='left'>
                    {moment(row.SRCreationDate).format("MM/DD/YYYY")}
                  </TableCell>
                  <TableCell align='left'>
                    {moment(row.LastRunDate).format("MM/DD/YYYY")}
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ fontWeight: "bold", fontSize: "1.5rem" }}
                  >
                    {row.Count}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow sx={{ cursor: "pointer" }}>
                <TableCell align='left' />
                <TableCell align='left' />
                <TableCell align='left' />
                <TableCell align='left' />
                <TableCell align='left' />
                <TableCell
                  align='left'
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    color: "#e95890",
                  }}
                >
                  Total :
                </TableCell>
                <TableCell
                  align='center'
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    color: "#e95890",
                  }}
                >
                  {count || 0}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {/* <CssTablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component='div'
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </>
  );
};

export default RunStatsTable;
